<!--

  File: PortfoloiInput.vue

  Defines a component that allows a task to added or removed from
  a manager's portfolio.

  Props:
    taskId: The task being modified.

  Events:
    None.

-->
<template>
  <div class="tile">
    <div class="panel my-0">
      <b-field
        class="mt-2 mx-4"
        label="Managers with the task in their portfolio"
      >
        <b-taginput
          v-model="task.portfolio"
          :data="filteredEmployees"
          autocomplete
          :open-on-focus="false"
          field="Name"
          icon="label"
          placeholder="Type to add, one required"
          type="is-primary"
          close-type="is-danger"
          @typing="getFilteredTags"
        >
        </b-taginput>
      </b-field>
      <div class="buttons mb-1">
        <button
          class="button is-primary m-2 ml-4"
          type="button"
          :disabled="saveDisabled"
          @click="updateTaskManagers()"
        >
          Save
        </button>
        <button class="button is-primary m-2" type="button" @click="refresh()">
          Reset
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: ["taskId"],
  apollo: {
    originalTask: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          originalTask: task(id: $id) {
            id
            PKEY
            AccountingId
            portfolio {
              edges {
                node {
                  PKEY
                  EmployeePkey
                  employee {
                    Name
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
      result() {
        this.task = { ...this.originalTask };
        delete this.task.__typename; // remove GraphQL __typename artifact
        // convert portfolio objects to have the same properties as the objects
        // in allEmployees
        this.task.portfolio = this.originalTask.portfolio.edges.map((e) => {
          return {
            PKEY: e.node.EmployeePkey.toString(),
            Name: e.node.employee.Name,
          };
        });
      },
    },
    allEmployees: {
      query: gql`
        {
          allEmployees(
            sort: NAME_ASC
            filters: { Role: "MANAGER", Active: true }
          ) {
            edges {
              node {
                Name
                PKEY
              }
            }
          }
        }
      `,
      result() {
        this.filteredEmployees = this.allEmployees.edges.map((e) => {
          return { PKEY: e.node.PKEY, Name: e.node.Name };
        });
        this.managers = [...this.filteredEmployees];
      },
    },
  },
  data() {
    return {
      originalTask: { portfolio: null },
      task: { portfolio: null },
      filteredEmployees: [],
      managers: undefined,
    };
  },
  computed: {
    saveDisabled() {
      if (!this.originalTask.portfolio) {
        return false;
      }
      if (!this.task.portfolio) {
        return false;
      }
      let origPortfolio = this.originalTask.portfolio.edges.map((e) => {
        return {
          PKEY: e.node.EmployeePkey.toString(),
          Name: e.node.employee.Name,
        };
      });

      let portfolio = this.task.portfolio;

      if (portfolio.length < 1) {
        return true;
      }

      if (origPortfolio.length !== portfolio.length) {
        return false;
      }

      return portfolio.every((e) =>
        origPortfolio.find((oe) => oe.PKEY === e.PKEY)
      );
    },
  },
  methods: {
    refresh() {
      Object.values(this.$apollo.queries).forEach((q) => q.refetch());
    },
    getFilteredTags(text) {
      this.filteredEmployees = this.managers.filter((option) => {
        return (
          option.Name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
    },
    updateTaskManagers() {
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($TaskPkey: Int!, $EmployeePKEYs: [Int!]!) {
              updateTaskManagers(
                input: { TaskPkey: $TaskPkey, EmployeePKEYs: $EmployeePKEYs }
              ) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            TaskPkey: this.task.PKEY,
            EmployeePKEYs: this.task.portfolio.map((x) => x.PKEY),
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Updated task managers for " + this.task.AccountingId + "!",
            type: "is-success",
          });
          this.refresh();
          this.$emit("request-refresh");
        })
        .catch((error) => {
          console.error("Caught error: ", error);
          this.$buefy.snackbar.open({
            message:
              "Failed to update task managers. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
  },
};
</script>

<style></style>
