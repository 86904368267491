<!--

  File: TaskForm.vue

  Defines a component that produces a form to edit task data. Example task
  data include: Name, Id, AccountingId, etc.

  Props:
    projectPkey: The primary key to set as the ProjectPKEY of new tasks.
    taskId: The id of the task being edited.

  Events:
    modified: Emitted when creating a task.
    updated: Emitted on task data updates.

-->
<template>
  <div class="card">
    <div class="card-content">
      <div class="columns">
        <div class="column">
          <b-field label="Charge Number">
            <b-input
              v-model="task.AccountingId"
              placeholder="Charge Number"
              required
            >
            </b-input>
          </b-field>
          <b-field label="Rollup to Task">
            <b-select placeholder="None" v-model="task.TaskPkeyRollup">
              <option
                v-for="rc in rollupChoices"
                :value="rc.PKEY"
                :key="rc.PKEY"
              >
                {{ rc.AccountingId }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Id">
            <b-input v-model="task.Id" placeholder="Id"> </b-input>
          </b-field>
          <b-field label="Description">
            <b-input
              v-model="task.Description"
              placeholder="Description"
              required
            >
            </b-input>
          </b-field>
          <customer-list
            :selectedCustomer="task.CustomerPkey"
            @input="task.CustomerPkey = parseInt($event)"
          />
          <b-field label="Funding Source Type">
            <b-select
              placeholder="Select a Funding Source Type"
              v-model="task.FundingSourceTypePkey"
            >
              <option
                v-for="fs in allFundingSourceTypes"
                :value="fs.PKEY"
                :key="fs.PKEY"
              >
                {{ fs.Id }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Status">
            <b-checkbox v-model="task.Closed"> Dormant </b-checkbox>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Ceiling">
            <b-input
              v-model="task.CeilingAmount"
              placeholder="Ceiling Amount"
              required
            >
            </b-input>
          </b-field>
          <b-field label="Period of Performance">
            <template>
              <b-field>
                <b-datepicker
                  placeholder="Click to select..."
                  v-model="POP"
                  range
                  :date-formatter="
                    (d) => {
                      return (
                        d[0].toISOString().substring(0, 10) +
                        ' to ' +
                        d[1].toISOString().substring(0, 10)
                      );
                    }
                  "
                  editable
                  :date-parser="
                    (s) => {
                      let d = s.replace(' to ', '-').split('-');
                      return [
                        new Date(d[0], d[1] - 1, d[2]),
                        new Date(d[3], d[4] - 1, d[5]),
                      ];
                    }
                  "
                  position="is-bottom-left"
                >
                </b-datepicker>
              </b-field>
            </template>
          </b-field>
          <b-field label="Funded">
            <b-input
              v-model="task.LimitAmount"
              placeholder="Enter amount funded so far"
              required
            >
            </b-input>
          </b-field>
          <b-field label="Funding End">
            <b-datepicker
              v-model="LimitEnd"
              placeholder="Enter last funding day"
              required
              trap-focus
              editable
              position="is-bottom-left"
              :date-formatter="
                (d) => {
                  return d.toISOString().substring(0, 10);
                }
              "
              :date-parser="
                (s) => {
                  return new Date(s);
                }
              "
            >
            </b-datepicker>
          </b-field>
          <b-field label="Fee">
            <b-input v-model="task.Fee" placeholder="Fee" required> </b-input>
          </b-field>
          <b-field label="Portfolio">
            <b-taginput
              v-model="task.portfolio"
              :data="filteredEmployees"
              autocomplete
              :open-on-focus="true"
              field="Name"
              icon="label"
              placeholder="Add a manager"
              type="is-primary"
              close-type="is-danger"
              @typing="getFilteredTags"
            >
            </b-taginput>
          </b-field>
        </div>
        <b-loading v-model="$apollo.loading" />
      </div>
    </div>
    <footer class="card-footer">
      <button
        class="button is-primary"
        type="button"
        :disabled="saveDisabled"
        @click="updateTask()"
        v-if="taskId"
      >
        Save
      </button>
      <button
        class="button is-primary"
        type="button"
        :disabled="saveDisabled"
        @click="createTask()"
        v-if="!taskId"
      >
        New
      </button>
      <button
        class="button is-danger"
        type="button"
        @click="confirmDeleteTask()"
        v-if="taskId"
      >
        Delete
      </button>
    </footer>
  </div>
</template>

<script>
import gql from "graphql-tag";
import CustomerList from "./CustomerList";

export default {
  components: {
    CustomerList,
  },
  props: {
    projectPkey: { type: String },
    taskId: { type: String },
  },
  apollo: {
    // Vue-Apollo options here
    originalTask: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          originalTask: task(id: $id) {
            id
            PKEY
            TaskPkeyRollup
            FundingSourceTypePkey
            CustomerPkey
            ProjectPkey
            Id
            Description
            AccountingId
            LimitAmount
            LimitEnd
            CeilingAmount
            Fee
            StartPOP
            EndPOP
            Closed
            customer {
              PKEY
              Name
              Description
              id
            }
            portfolio {
              edges {
                node {
                  PKEY
                  EmployeePkey
                  employee {
                    Name
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
      result() {
        this.resetTask();
      },
    },
    allEmployees: {
      query: gql`
        {
          allEmployees(
            sort: NAME_ASC
            filters: { RoleIn: ["ADMIN", "MANAGER"], Active: true }
          ) {
            edges {
              node {
                Name
                PKEY
              }
            }
          }
        }
      `,
      result() {
        this.filteredEmployees = this.allEmployees.edges.map((e) => {
          return { PKEY: e.node.PKEY, Name: e.node.Name };
        });
        this.managers = [...this.filteredEmployees];
      },
    },
    allFundingSourceTypes: {
      query: gql`
        {
          allFundingSourceTypes {
            PKEY
            Id
            Abbrev
          }
        }
      `,
    },
    rollupChoices: {
      query: gql`
        query ($project: Int!) {
          rollupChoices: allTasks(
            sort: ACCOUNTING_ID_ASC
            filters: { ProjectPkey: $project, Closed: false }
          ) {
            edges {
              node {
                PKEY
                AccountingId
              }
            }
          }
        }
      `,
      variables() {
        return {
          project: this.originalTask.ProjectPkey,
        };
      },
      skip() {
        return !this.originalTask.ProjectPkey;
      },
      update(data) {
        // remove edges and nodes
        return data.rollupChoices.edges.map((e) => e.node);
      },
      result() {
        this.rollupChoices.unshift({ PKEY: null, AccountingId: "None" });
      },
    },
  },
  data() {
    return {
      allFundingSourceTypes: [],
      task: this.initializeTask(),
      originalTask: {},
      filteredEmployees: [],
      managers: undefined,
    };
  },
  computed: {
    saveDisabled() {
      if (this.taskId) {
        return (
          this.isSamePortfolio() &&
          this.originalTask.AccountingId === this.task.AccountingId &&
          this.originalTask.TaskPkeyRollup === this.task.TaskPkeyRollup &&
          this.originalTask.Id === this.task.Id &&
          this.originalTask.Description === this.task.Description &&
          this.originalTask.CustomerPkey === this.task.CustomerPkey &&
          this.originalTask.FundingSourceTypePkey ===
            this.task.FundingSourceTypePkey &&
          this.originalTask.CeilingAmount === this.task.CeilingAmount &&
          this.originalTask.StartPOP === this.task.StartPOP &&
          this.originalTask.EndPOP === this.task.EndPOP &&
          this.originalTask.LimitAmount === this.task.LimitAmount &&
          this.originalTask.LimitEnd === this.task.LimitEnd &&
          this.originalTask.Fee === this.task.Fee &&
          this.originalTask.Closed === this.task.Closed
        );
      }
      return (
        !this.task.AccountingId ||
        !this.task.Id ||
        !this.task.Description ||
        !this.task.CustomerPkey ||
        !this.task.FundingSourceTypePkey ||
        !this.task.CeilingAmount ||
        !this.task.StartPOP ||
        !this.task.EndPOP ||
        !this.task.LimitAmount ||
        !this.task.LimitEnd ||
        !this.task.Fee ||
        this.task.portfolio.length === 0
      );
    },
    LimitEnd: {
      get() {
        if (!this.task.LimitEnd) {
          return undefined;
        }
        return new Date(this.task.LimitEnd + " 00:00:00");
      },
      set(newValue) {
        this.task.LimitEnd = newValue.toISOString().substring(0, 10);
      },
    },
    POP: {
      get() {
        if (this.task.StartPOP && this.task.EndPOP) {
          return [
            new Date(this.task.StartPOP + " 00:00:00"),
            new Date(this.task.EndPOP + " 00:00:00"),
          ];
        }
        return undefined;
      },
      set(newValue) {
        this.task.StartPOP = newValue[0].toISOString().substring(0, 10);
        this.task.EndPOP = newValue[1].toISOString().substring(0, 10);
      },
    },
  },
  watch: {
    taskId(newId) {
      if (!newId) {
        this.task = this.initializeTask();
      }
    },
  },
  methods: {
    resetTask() {
      if (!this.originalTask) return;

      this.task = { ...this.originalTask };
      delete this.task.__typename; // remove GraphQL __typename artifact
      delete this.task.customer;
      delete this.task.id;
      // convert portfolio objects to have the same properties as the objects
      // in allEmployees
      this.task.portfolio = this.originalTask.portfolio.edges.map((e) => {
        return {
          PKEY: e.node.EmployeePkey.toString(),
          Name: e.node.employee.Name,
        };
      });
    },
    initializeTask() {
      return {
        PKEY: undefined,
        FundingSourceTypePkey: undefined,
        CustomerPkey: undefined,
        ProjectPkey: this.projectPkey,
        Id: undefined,
        Description: undefined,
        AccountingId: undefined,
        LimitAmount: undefined,
        LimitEnd: undefined,
        CeilingAmount: undefined,
        Fee: undefined,
        StartPOP: undefined,
        EndPOP: undefined,
        Closed: false,
        portfolio: [],
        customer: undefined,
      };
    },
    createTask() {
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: TaskInput!) {
              createTask(input: $input) {
                task {
                  id
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: this.task,
          },
        })
        .then((data) => {
          // Result
          this.$buefy.toast.open({
            message: "Task " + data.data.createTask.task.Name + " created!",
            type: "is-success",
          });
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create task " +
              this.task.Description +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    updateTask() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: TaskInput!) {
              updateTask(input: $input) {
                task {
                  Description
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: this.task,
          },
        })
        .then((data) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Task " + data.data.updateTask.task.Description + " updated!",
            type: "is-success",
          });
          this.$emit("updated");
        })
        .catch((error) => {
          // Error
          console.error(error);
          this.$buefy.snackbar.open({
            message:
              "Failed to update task " +
              this.task.Description +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    confirmDeleteTask() {
      this.$buefy.dialog.confirm({
        title: "Deleting task",
        message:
          "Are you sure you want to <b>delete</b> the task " +
          this.task.AccountingId +
          "? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteTask(),
      });
    },
    deleteTask() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($PKEY: Int!) {
              deleteTask(input: { PKEY: $PKEY }) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            PKEY: this.task.PKEY,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Task " + this.task.AccountingId + " deleted!",
            type: "is-success",
          });
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to delete task " +
              this.task.AccountingId +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    getFilteredTags(text) {
      this.filteredEmployees = this.managers.filter((option) => {
        return (
          option.Name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
    },
    isSamePortfolio() {
      if (!this.originalTask.portfolio) {
        return false;
      }
      let origPortfolio = this.originalTask.portfolio.edges.map((e) => {
        return {
          PKEY: e.node.EmployeePkey.toString(),
          Name: e.node.employee.Name,
        };
      });

      let portfolio = this.task.portfolio;
      if (origPortfolio.length !== portfolio.length) {
        return false;
      }

      return portfolio.every((e) =>
        origPortfolio.find((oe) => oe.PKEY === e.PKEY),
      );
    },
  },
};
</script>
