<!--

  File: SalaryForm.vue

  Defines a component that produces a form to edit employee salary over time.

  Props:
    id: The id of the employee.

  Events:
    None

-->
<template>
  <div class="columns">
    <div class="block">
      <div class="buttons my-0">
        <b-button
          class="button py-0 mx-0 my-0 is-primary"
          type="is-text"
          size="is-small"
          icon-left="plus"
          @click="openNewForm()"
        />
        <b-button
          class="button py-0 mx-0 my-0 is-primary"
          type="is-text"
          size="is-small"
          icon-left="pencil"
          :disabled="!selected"
          @click="openEditForm()"
        />
      </div>
      <b-table
        :data="employee.salaries"
        default-sort="StartDate"
        default-sort-direction="desc"
        :loading="$apollo.loading"
        :selected.sync="selected"
        sticky-header
        @dblclick="openEditForm()"
      >
        <b-table-column
          label="Date Range"
          field="StartDate"
          sortable
          v-slot="props"
        >
          {{ props.row.StartDate }} to {{ props.row.EndDate }}
        </b-table-column>
        <b-table-column label="Yearly Rate" numeric v-slot="props">
          {{ toCurrency(props.row.YearlyRate) }}
        </b-table-column>
      </b-table>
    </div>
    <b-modal
      :active="active"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
      width="300"
    >
      <div class="modal-card" style="width: 280px">
        <form action="" v-if="id">
          <header class="modal-card-head">
            <p class="modal-card-title" v-if="selected">Update Salary</p>
            <p class="modal-card-title" v-else>New Salary</p>
            <button type="button" class="delete" @click="closeSubForm()" />
          </header>
          <div class="modal-card-body">
            <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
            <b-field label="Date Range">
              <b-datepicker
                v-model="dateRange"
                placeholder="Enter start date"
                icon="calendar"
                trap-focus
                range
                :date-formatter="
                  (d) => {
                    return (
                      d[0].toISOString().substring(0, 10) +
                      ' to ' +
                      d[1].toISOString().substring(0, 10)
                    );
                  }
                "
                editable
                :date-parser="
                  (s) => {
                    let d = s.replace(' to ', '-').split('-');
                    return [
                      new Date(d[0], d[1] - 1, d[2]),
                      new Date(d[3], d[4] - 1, d[5]),
                    ];
                  }
                "
                required
              />
            </b-field>
            <b-field label="Yearly Rate">
              <b-input
                type="number"
                step=".01"
                icon="currency-usd"
                v-model="salary.YearlyRate"
                placeholder="Enter yearly rate"
                required
              >
              </b-input>
            </b-field>
          </div>
          <footer class="modal-card-foot">
            <button
              class="button is-primary"
              type="button"
              :disabled="saveDisabled"
              @click="updateSalary()"
              v-if="selected"
            >
              Save
            </button>
            <button
              class="button is-primary"
              type="button"
              :disabled="saveDisabled"
              @click="createSalary()"
              v-else
            >
              New
            </button>
            <button
              class="button is-danger"
              type="button"
              @click="confirmDeleteSalary()"
              v-if="selected"
            >
              Delete
            </button>
          </footer>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: ["id"],
  apollo: {
    // Vue-Apollo options here
    employee: {
      query: gql`
        query GetEmployee($id: ID!) {
          employee(id: $id) {
            PKEY
            Name
            salaries {
              StartDate
              EndDate
              YearlyRate
              PKEY
              EmployeePkey
            }
          }
        }
      `,
      fetchPolicy: "no-cache",
      variables() {
        // Use vue reactive properties here
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
    },
  },
  data() {
    return {
      isLoading: false,
      employee: { salaries: undefined },
      selected: undefined,
      active: false,
      salary: {
        StartDate: "",
        EndDate: "",
        YearlyRate: "",
      },
    };
  },
  computed: {
    dateRange: {
      get() {
        return [
          new Date(this.salary.StartDate + " 00:00:00"),
          new Date(this.salary.EndDate + " 00:00:00"),
        ];
      },
      set(newValue) {
        this.salary.StartDate = newValue[0].toISOString().substring(0, 10);
        this.salary.EndDate = newValue[1].toISOString().substring(0, 10);
      },
    },
    saveDisabled() {
      if (this.selected) {
        return (
          this.selected.StartDate === this.salary.StartDate &&
          this.selected.EndDate === this.salary.EndDate &&
          this.selected.YearlyRate === this.salary.YearlyRate
        );
      }
      return (
        !this.salary.StartDate ||
        !this.salary.EndDate ||
        !this.salary.YearlyRate
      );
    },
  },
  methods: {
    closeSubForm() {
      this.selected = undefined;
      this.active = false;
    },
    openEditForm() {
      this.salary = { ...this.selected };
      delete this.salary.__typename; // remove GraphQL __typename artifact
      this.active = true;
    },
    openNewForm() {
      let june30 = new Date();
      june30.setMonth(5);
      june30.setDate(30);
      this.selected = undefined;
      this.salary = {
        EmployeePkey: this.employee.PKEY,
        StartDate: new Date().toISOString().substring(0, 10),
        EndDate: june30.toISOString().substring(0, 10),
        YearlyRate: undefined,
      };
      this.active = true;
    },
    toCurrency(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(value);
    },
    updateSalary() {
      this.isLoading = true;
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: EmployeeSalaryInput!) {
              updateEmployeeSalary(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.salary,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Salary data for " + this.employee.Name + " updated!",
            type: "is-success",
          });
          this.$apollo.queries.employee.refetch();
          this.isLoading = false;
          this.closeSubForm();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message: error.message,
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
    createSalary() {
      this.isLoading = true;
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: EmployeeSalaryInput!) {
              createEmployeeSalary(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.salary,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Salary data for " + this.employee.Name + " added!",
            type: "is-success",
          });
          this.closeSubForm();
          this.$apollo.queries.employee.refetch();
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message: error.message,
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
    confirmDeleteSalary() {
      this.$buefy.dialog.confirm({
        title: "Deleting salary data",
        message:
          "Are you sure you want to <b>delete</b> this data? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteSalary(),
      });
    },
    deleteSalary() {
      this.isLoading = true;
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($PKEY: Int!) {
              deleteEmployeeSalary(input: { PKEY: $PKEY }) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            PKEY: this.salary.PKEY,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Salary data for " + this.employee.Name + " deleted!",
            type: "is-success",
          });
          this.closeSubForm();
          this.$apollo.queries.employee.refetch();
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message: error.message,
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .animation-content .modal-card {
  overflow: visible !important;
}
::v-deep .modal-card-body {
  overflow: visible !important;
}
::v-deep .table-wrapper {
  overflow-x: hidden !important;
}
</style>
