<!--

  File: Contracts.vue

  Defines a component that displays all contracts in a table. Contracts
  can be created and modified.

  Props:
    None

  Events:
    ContractTab:close: Sets activeTab to 0.
    ContractTab:change: Sets activeTab to 0 and re-fetches data.

-->
<template>
  <div>
    <b-tabs type="my-0" v-model="activeTab">
      <b-tab-item>
        <div class="container">
          <div class="level">
            <!--suppress JSUnresolvedVariable -->
            <div class="buttons level-left my-0">
              <b-tooltip
                type="is-info"
                label="Refresh"
                position="is-right"
                :delay="500"
              >
                <b-button
                  class="button is-primary"
                  size="is-medium"
                  icon-left="refresh"
                  @click="
                    Object.values($apollo.queries).forEach((q) => q.refetch())
                  " /></b-tooltip
              ><b-tooltip
                type="is-info"
                label="Create new Contract"
                position="is-right"
                :delay="500"
              >
                <b-button
                  class="button is-primary"
                  type="is-text"
                  size="is-medium"
                  icon-left="plus"
                  @click="openNewForm()"
                />
              </b-tooltip>
              <b-tooltip
                type="is-info"
                label="Edit Contract"
                position="is-right"
                :delay="500"
              >
                <b-button
                  class="button is-primary"
                  type="is-text"
                  size="is-medium"
                  icon-left="pencil"
                  :disabled="!selected"
                  @click="openEditForm()"
                />
              </b-tooltip>
            </div>
            <div class="level-item">
              <h1 class="title has-text-primary has-text-centered">
                Contracts
              </h1>
            </div>
            <div class="level-right">
              <HelpDoc id="contracts" />
            </div>
          </div>
          <div class="columns is-centered">
            <b-table
              :data="allProjects.edges"
              :default-sort="['node.Name', 'asc']"
              :selected.sync="selected"
              :loading="$apollo.loading"
              sticky-header
              height="750"
              @dblclick="openEditForm()"
              ref="BTable"
            >
              <b-table-column
                field="node.Name"
                label="Code"
                sortable
                searchable
              >
                <template #searchable="props">
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Search..."
                    icon="magnify"
                    size="is-small"
                  />
                </template>
                <template v-slot="props">
                  <span>{{ props.row.node.Name }}</span>
                </template>
              </b-table-column>
              <b-table-column
                field="node.Description"
                label="Description"
                sortable
                searchable
              >
                <template #searchable="props">
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Search..."
                    icon="magnify"
                    size="is-small"
                  />
                </template>
                <template v-slot="props">
                  <span>{{ props.row.node.Description }}</span>
                </template>
              </b-table-column>
              <b-table-column
                field="node.Open"
                label="Open/Closed"
                sortable
                searchable
              >
                <template #searchable="props">
                  <b-input
                    v-model="props.filters[props.column.field]"
                    placeholder="Search..."
                    icon="magnify"
                    size="is-small"
                    value="false"
                  />
                </template>
                <template v-slot="props">
                  <span>{{ props.row.node.Open }}</span>
                </template>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item>
        <ContractTab
          :contractId="contractId"
          :contractName="
            selected
              ? selected.node.Name + ' - ' + selected.node.Description
              : undefined
          "
          @close="onFormClose()"
          @modified="onFormChanged()"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import gql from "graphql-tag";
import HelpDoc from "./HelpDoc";
import ContractTab from "@/components/ContractTab";

export default {
  components: {
    ContractTab,
    HelpDoc,
  },
  apollo: {
    // Vue-Apollo options here
    allProjects: {
      query: gql`
        {
          allProjects {
            edges {
              node {
                PKEY
                Name
                Description
                Closed
                id
              }
            }
          }
        }
      `,
      update(data) {
        data.allProjects.edges.forEach((e) => {
          var closed = e.node.Closed;
          e.node.Open = closed ? "closed" : "open";
        });
        return data.allProjects;
      },
    },
  },
  data() {
    return {
      activeTab: 0,
      allProjects: { edges: [] },
      selected: undefined,
      contractId: undefined,
      contractName: undefined,
    };
  },
  mounted() {
    this.$set(this.$refs.BTable.filters, "node.Open", "open");
  },
  watch: {},
  methods: {
    openEditForm() {
      this.contractId = this.selected ? this.selected.node.id : undefined;
      this.activeTab = 1;
    },
    openNewForm() {
      this.selected = undefined;
      this.activeTab = 1;
    },
    onFormClose() {
      this.contractId = undefined;
      this.activeTab = 0;
    },
    onFormChanged() {
      this.contractId = undefined;
      this.activeTab = 0;
      this.$apollo.queries.allProjects.refetch();
      this.selected = undefined;
    },
  },
};
</script>
