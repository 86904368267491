<!--

  File: CompanyRates.vue

  Defines a component that allows administrators to manage company rate
  information.

  Props:
    None

  Events:
    close: The company rates modal emits this event to signal that the
           isActive property should be set to false. The event data
           indicates if company rates have been modified.

-->
<template>
  <div class="container">
    <div class="level">
      <div class="level-left">
        <div class="level-item buttons my-0">
          <b-tooltip
            type="is-info"
            label="Refresh"
            position="is-right"
            :delay="500"
          >
            <b-button
              class="button is-primary"
              size="is-medium"
              icon-left="refresh"
              @click="
                Object.values($apollo.queries).forEach((q) => q.refetch())
              " /></b-tooltip
          ><b-tooltip
            type="is-info"
            label="Add indirect rates to selected cost center"
            position="is-right"
            :delay="500"
          >
            <b-button
              class="button is-primary"
              type="is-text"
              size="is-medium"
              icon-left="plus"
              @click="openNewForm()"
            />
          </b-tooltip>
          <b-tooltip
            type="is-info"
            label="Edit selected indirect rates"
            position="is-right"
            :delay="500"
          >
            <b-button
              class="button is-primary"
              type="is-text"
              size="is-medium"
              icon-left="pencil"
              :disabled="!selectedIndirectRates"
              @click="openEditForm()"
            />
          </b-tooltip>
        </div>
      </div>
      <div class="level-item">
        <h1 class="title has-text-primary has-text-centered">Company Rates</h1>
      </div>
      <div class="level-right">
        <div class="level-item">
          <HelpDoc id="company-rates" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns is-centered">
        <b-table
          :data="allCostCenters.edges"
          :selected.sync="selectedCostCenter"
        >
          <b-table-column field="node.Name" label="Cost Center">
            <template v-slot="props">
              <span>{{ props.row.node.Name }}</span>
            </template>
          </b-table-column>
        </b-table>
        <b-table
          v-if="selectedCostCenter"
          :data="selectedCostCenter.node.indirectRates.edges"
          :default-sort="['node.StartDate', 'desc']"
          :selected.sync="selectedIndirectRates"
          :loading="$apollo.loading"
          sticky-header
          height="750"
          @dblclick="openEditForm()"
        >
          <b-table-column
            field="node.StartDate"
            label="Date Range"
            sortable
            v-slot="props"
          >
            {{ props.row.node.StartDate }} to {{ props.row.node.EndDate }}
          </b-table-column>
          <b-table-column field="node.MH" label="M&H" numeric>
            <template v-slot="props">
              <span>{{ props.row.node.MH }}</span>
            </template>
          </b-table-column>
          <b-table-column field="node.Fringe" label="Fringe" numeric>
            <template v-slot="props">
              <span>{{ props.row.node.Fringe }}</span>
            </template>
          </b-table-column>
          <b-table-column field="node.GA" label="G&A" numeric>
            <template v-slot="props">
              <span>{{ props.row.node.GA }}</span>
            </template>
          </b-table-column>
          <b-table-column
            field="node.EndDate"
            label="OH"
            centered
            cell-class="no-padding"
          >
            <template v-slot="props">
              <b-table
                :data="props.row.node.ohRates.edges"
                :show-header="false"
                default-sort="node.Id"
                :class="
                  selectedIndirectRates === props.row ? 'selectedTable' : ''
                "
              >
                <b-table-column field="node.Id" sortable numeric>
                  <template v-slot="props">
                    <span>{{ props.row.node.Id }}:</span>
                  </template>
                </b-table-column>
                <b-table-column field="node.Rate" numeric>
                  <template v-slot="props">
                    <span>{{ props.row.node.Rate }}</span>
                  </template>
                </b-table-column>
              </b-table>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </div>
    <CompanyRatesModal
      :isActive="modalActive"
      :input="selectedIndirectRates"
      :costCenterPkey="selectedCostCenter.node.PKEY"
      @close="onModalClose($event)"
    />
  </div>
</template>
<script>
import gql from "graphql-tag";
import CompanyRatesModal from "@/components/CompanyRatesModal";
import HelpDoc from "@/components/HelpDoc";

export default {
  components: { CompanyRatesModal, HelpDoc },
  apollo: {
    // Vue-Apollo options here
    allCostCenters: {
      query: gql`
        {
          allCostCenters {
            edges {
              node {
                PKEY
                Name
                indirectRates {
                  edges {
                    node {
                      PKEY
                      CostCenterPkey
                      Fringe
                      GA
                      MH
                      StartDate
                      EndDate
                      ohRates {
                        edges {
                          node {
                            PKEY
                            IndirectRatesPkey
                            Id
                            Rate
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      update(data) {
        this.selectedCostCenter = data.allCostCenters.edges[0];
        this.selectedIndirectRates = null;
        return data.allCostCenters;
      },
    },
  },
  data() {
    return {
      allCostCenters: [],
      modalActive: false,
      selectedCostCenter: { node: { indirectRates: { edges: [] } } },
      selectedIndirectRates: null,
    };
  },
  methods: {
    openNewForm() {
      this.selectedIndirectRates = null;
      this.modalActive = true;
    },
    openEditForm() {
      this.modalActive = true;
    },
    onModalClose(eventData) {
      if (eventData.modified) {
        this.$apollo.queries.allCostCenters.refetch();
      }
      this.modalActive = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep td.no-padding {
  padding: 0;
}
::v-deep div.selectedTable tr {
  background-color: #4b63ae;
  color: #fff;
}
</style>
