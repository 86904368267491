<!--

  File: HourAssignmentPlanUndo.vue

  A component that implements an undo/redo stack for hour assignment plan modifications.

  Props:
    None

  Events:
    request-refresh: Emitted when the user performs an undo or redo action
    interface: Pass a calling interface back to the parent

-->
<template>
  <section>
    <b-tooltip
      type="is-info"
      label="Undo the last hour assignment plan change"
      position="is-right"
      :delay="500"
    >
      <b-button
        class="button is-primary mr-1"
        size="is-normal"
        icon-left="undo"
        :disabled="undoStack.length === 0"
        @click="undo()"
      />
    </b-tooltip>
    <b-tooltip
      type="is-info"
      label="Redo the last undone hour assignment plan change"
      position="is-right"
      :delay="500"
    >
      <b-button
        class="button is-primary mr-1"
        size="is-normal"
        icon-left="redo"
        :disabled="redoStack.length === 0"
        @click="redo()"
      />
    </b-tooltip>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>
<script>
import gql from "graphql-tag";

export default {
  mounted() {
    this.$emit("interface", {
      push: (ur) => this.push(ur),
    });
  },
  data() {
    return {
      isLoading: false,
      undoStack: [],
      redoStack: [],
    };
  },
  methods: {
    undo() {
      var ur = this.undoStack.pop();
      this.savePlan(ur.inverseAction);
      this.redoStack.push(ur);
    },
    redo() {
      var ur = this.redoStack.pop();
      this.savePlan(ur.action);
      this.undoStack.push(ur);
    },
    push(undoRecord) {
      this.undoStack.push(undoRecord);
      this.redoStack = [];
    },
    savePlan(hapInput) {
      this.isLoading = true;
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($HAPlan: [HourAssignmentPlanInput]) {
              addOrReplaceHourAssignmentPlan(input: $HAPlan) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            HAPlan: hapInput,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Updated hour assignment plan!",
            type: "is-success",
          });
          this.isLoading = false;
          this.$emit("request-refresh", { modified: true });
        })
        .catch((error) => {
          // Error
          console.error(error);
          this.$buefy.snackbar.open({
            message:
              "Failed to update hour assignment plans. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
  },
};
</script>
<style></style>
