<!--

  File: Tasks.vue

  Defines a component that displays a list of tasks assigned to a contract.

  Props:
    contractId: The id of the contract being edited.

  Events:
    modified: Emitted when the task data changes.

-->
<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="level my-0">
          <!--suppress JSUnresolvedVariable -->
          <div class="buttons level-left my-0">
            <b-tooltip
              type="is-info"
              label="Refresh"
              position="is-right"
              :delay="500"
            >
              <b-button
                class="button is-primary"
                size="is-medium"
                icon-left="refresh"
                @click="
                  Object.values($apollo.queries).forEach((q) => q.refetch())
                " /></b-tooltip
            ><b-tooltip
              type="is-info"
              label="Create new Contract"
              position="is-right"
              :delay="500"
            >
              <b-button
                class="button is-primary"
                type="is-text"
                size="is-medium"
                icon-left="plus"
                @click="openNewForm()"
              />
            </b-tooltip>
            <b-tooltip
              type="is-info"
              label="Edit Contract"
              position="is-right"
              :delay="500"
            >
              <b-button
                class="button is-primary"
                type="is-text"
                size="is-medium"
                icon-left="pencil"
                :disabled="!selected"
                @click="openEditForm()"
              />
            </b-tooltip>
          </div>
          <div class="level-right">
            <HelpDoc id="task" />
          </div>
        </div>
        <b-table
          :data="project.tasks.edges"
          :default-sort="['node.AccountingId', 'asc']"
          :selected.sync="selected"
          :loading="$apollo.loading"
          sticky-header
          height="650"
          @dblclick="openEditForm()"
          ref="BTable"
        >
          <b-table-column
            field="node.AccountingId"
            label="Charge Number"
            sortable
            searchable
          >
            <template #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
              />
            </template>
            <template v-slot="props">
              <span>{{ props.row.node.AccountingId }}</span>
            </template>
          </b-table-column>
          <b-table-column field="node.Id" label="Id" sortable searchable>
            <template #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
              />
            </template>
            <template v-slot="props">
              <span>{{ props.row.node.Id }}</span>
            </template>
          </b-table-column>
          <b-table-column
            field="node.Description"
            label="Description"
            sortable
            searchable
          >
            <template #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
              />
            </template>
            <template v-slot="props">
              <span>{{ props.row.node.Description }}</span>
            </template>
          </b-table-column>
          <b-table-column
            field="node.Dormant"
            label="Active/Dormant"
            sortable
            searchable
          >
            <template #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
              />
            </template>
            <template v-slot="props">
              <span>{{ props.row.node.Dormant }}</span>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </div>
    <TaskModal
      :isActive="formActive"
      :projectPkey="project ? project.PKEY : undefined"
      :taskId="selected ? selected.node.id : undefined"
      @close="onFormClose"
      @modified="onFormChanged"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";
import HelpDoc from "./HelpDoc";
import TaskModal from "./TaskModal";

export default {
  props: {
    contractId: { type: String },
  },
  components: {
    TaskModal,
    HelpDoc,
  },
  apollo: {
    // Vue-Apollo options here
    project: {
      query: gql`
        query ($id: ID!) {
          project(id: $id) {
            id
            PKEY
            tasks {
              edges {
                node {
                  Id
                  Description
                  AccountingId
                  Closed
                  id
                }
              }
            }
          }
        }
      `,
      variables() {
        // Use vue reactive properties here
        return {
          id: this.contractId,
        };
      },
      skip() {
        return !this.contractId;
      },
      update(data) {
        data.project.tasks.edges.forEach((e) => {
          var closed = e.node.Closed;
          e.node.Dormant = closed ? "dormant" : "active";
        });
        return data.project;
      },
    },
  },
  data() {
    return {
      formActive: false,
      project: { tasks: { edges: [] } },
      selected: undefined,
    };
  },
  mounted() {
    this.$set(this.$refs.BTable.filters, "node.Dormant", "active");
  },
  methods: {
    openEditForm() {
      this.formActive = true;
    },
    openNewForm() {
      this.selected = undefined;
      this.formActive = true;
    },
    onFormClose() {
      this.formActive = false;
    },
    onFormChanged() {
      this.$apollo.queries.project.refetch();
      this.selected = undefined;
      this.formActive = false;
    },
  },
};
</script>
