<!--

  File: Employees.vue

  Defines a component that displays all employees in a table. There
  are controls to modified the employee data.

  Props:
    None

  Events:
    EmployeeModal:close: Sets modalActive to false.
    EmployeeModal:change: Sets modalActive to false and re-fetches data.

-->
<template>
  <div class="mt-4">
    <div class="container">
      <div class="level">
        <!--suppress JSUnresolvedVariable -->
        <div class="buttons level-left my-0">
          <b-tooltip
            type="is-info"
            label="Refresh"
            position="is-right"
            :delay="500"
          >
            <b-button
              class="button is-primary"
              size="is-medium"
              icon-left="refresh"
              @click="
                Object.values($apollo.queries).forEach((q) => q.refetch())
              "
          /></b-tooltip>
          <b-tooltip
            type="is-info"
            label="Create new Employee Account"
            position="is-right"
            :delay="500"
          >
            <b-button
              class="button is-primary"
              type="is-text"
              size="is-medium"
              icon-left="plus"
              @click="openNewForm()"
            />
          </b-tooltip>
          <b-tooltip
            type="is-info"
            label="Edit Employee Info"
            position="is-right"
            :delay="500"
          >
            <b-button
              class="button is-primary"
              type="is-text"
              size="is-medium"
              icon-left="pencil"
              :disabled="!selected"
              @click="openEditForm()"
            />
          </b-tooltip>
        </div>
        <div class="level-item">
          <h1 class="title has-text-primary has-text-centered">
            Employee Accounts
          </h1>
        </div>
        <div class="level-right">
          <HelpDoc id="employee" />
        </div>
      </div>
      <div class="columns is-centered">
        <b-table
          :data="allEmployees"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :loading="$apollo.loading"
          default-sort="Name"
          :selected.sync="selected"
          sticky-header
          height="750"
          @dblclick="openEditForm()"
        >
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
              <template
                v-if="column.searchable && !column.numeric"
                #searchable="props"
              >
                <b-input
                  v-model="props.filters[props.column.field]"
                  placeholder="Search..."
                  icon="magnify"
                  :ref="column.field"
                  size="is-small"
                />
              </template>
              <template v-slot="props">
                {{ props.row[column.field] }}
              </template>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </div>
    <EmployeeModal
      :isActive="modalActive"
      :employee="selected"
      @close="onFormClose()"
      @change="onFormChanged()"
    />
  </div>
</template>

<script>
import gql from "graphql-tag";
import EmployeeModal from "./EmployeeModal";
import HelpDoc from "./HelpDoc";

export default {
  components: {
    EmployeeModal,
    HelpDoc,
  },
  apollo: {
    // Vue-Apollo options here
    allEmployees: {
      query: gql`
        {
          allEmployees {
            edges {
              node {
                id
                PKEY
                EmpNo
                Name
                Role
                Active
              }
            }
          }
        }
      `,
      update(data) {
        data.allEmployees.edges.forEach((e) => {
          var active = e.node.Active;
          e.node.Status = active ? "active" : "terminated";
        });
        // remove edges and nodes
        return data.allEmployees.edges.map((e) => e.node);
      },
    },
  },

  data() {
    return {
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,

      modalActive: false,
      selected: undefined,

      allEmployees: [],

      columns: [
        {
          field: "EmpNo",
          label: "Id",
          searchable: true,
          sortable: true,
        },
        {
          field: "Name",
          label: "Name",
          searchable: true,
          sortable: true,
        },
        {
          field: "Role",
          label: "Role",
          searchable: true,
          sortable: true,
        },
        {
          field: "Status",
          label: "Active",
          searchable: true,
          sortable: true,
        },
      ],
    };
  },

  methods: {
    openEditForm() {
      this.modalActive = true;
    },
    openNewForm() {
      this.selected = undefined;
      this.modalActive = true;
    },
    onFormClose() {
      this.modalActive = false;
    },
    onFormChanged() {
      this.$apollo.queries.allEmployees.refetch();
      this.selected = undefined;
      this.modalActive = false;
    },
  },
};
</script>
