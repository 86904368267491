<!--

  File: Home.vue

  Defines a component that displays hour assignments for the signed-in
  employee.

  Props:
    id: The id of the signed-in user.
    role: The role of the signed-in user.

  Events:
    None

-->
<template>
  <div class="mt-4">
    <div class="level my-0">
      <div class="level-left">
        <b-tooltip
          type="is-info"
          label="Refresh"
          position="is-right"
          :delay="500"
          ><b-button
            class="button is-primary mr-2"
            size="is-normal"
            icon-left="refresh"
            @click="Object.values($apollo.queries).forEach((q) => q.refetch())"
        /></b-tooltip>
        <b-select
          v-if="allEmployees && ['MANAGER', 'ADMIN'].includes(role)"
          v-model="selectedEmployee"
          placeholder="Select an employee"
          rounded
        >
          <option
            v-for="e in allEmployees.edges"
            :value="e.node.PKEY"
            :key="e.node.PKEY"
          >
            {{ e.node.Name }}
          </option>
        </b-select>
        <b-switch v-model="showDetails" class="ml-2">Show details</b-switch>
      </div>
      <div class="level-right">
        <HelpDoc id="home" />
      </div>
    </div>
    <div class="tile mt-4" v-if="hourSummary">
      <b-table
        :data="hourSummary.edges[0].node.laborHourSummary.edges"
        :loading="$apollo.loading"
      >
        <template #empty>
          <div class="hero is-danger">
            <div class="hero-body">
              <h1 class="subtitle">You do not have any hours assigned.</h1>
            </div>
          </div>
        </template>
        <b-table-column field="node.task.Id" label="Name">
          <template v-slot="props">
            <span>{{ props.row.node.task.Id }}</span>
          </template>
        </b-table-column>
        <b-table-column field="node.task.AccountingId" label="Charge Number">
          <template v-slot="props">
            <span>{{ props.row.node.task.AccountingId }}</span>
          </template>
        </b-table-column>
        <b-table-column field="node.EndDate" label="Expires">
          <template v-slot="props">
            <span>{{ props.row.node.EndDate }}</span>
          </template>
        </b-table-column>
        <b-table-column
          field="node.RemainingHours"
          label="Remaining Hours"
          numeric
        >
          <template v-slot="props">
            <span>{{
              Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
              }).format(props.row.node.RemainingHours)
            }}</span>
          </template>
        </b-table-column>
        <b-table-column
          field="node.RemainingPayPeriods"
          label="Remaining Periods"
          numeric
        >
          <template v-slot="props">
            <span>{{ props.row.node.RemainingPayPeriods }}</span>
          </template>
        </b-table-column>
      </b-table>
    </div>
    <b-collapse :open="showDetails" class="mt-6">
      <EmployeeUtilization :employees="[selectedEmployee]" :title="false" />
    </b-collapse>
  </div>
</template>

<script>
import gql from "graphql-tag";
import HelpDoc from "./HelpDoc";
import EmployeeUtilization from "./EmployeeUtilization.vue";

export default {
  props: ["id", "role"],
  components: { HelpDoc, EmployeeUtilization },
  apollo: {
    allEmployees: {
      query: gql`
        {
          allEmployees(filters: { Active: true }, sort: NAME_ASC) {
            edges {
              node {
                id
                PKEY
                Name
              }
            }
          }
        }
      `,
    },
    hourSummary: {
      query: gql`
        query ($userPkey: ID!) {
          hourSummary: allEmployees(filters: { PKEY: $userPkey }) {
            edges {
              node {
                PKEY
                Name
                laborHourSummary(filters: { RemainingPayPeriodsGt: 0 }) {
                  edges {
                    node {
                      task {
                        Id
                        AccountingId
                      }
                      TaskPkey
                      StartDate
                      EndDate
                      RemainingHours
                      RemainingPayPeriods
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          userPkey: this.selectedEmployee,
        };
      },
    },
  },
  data() {
    return {
      showDetails: false,
      hourSummary: undefined,
      allEmployees: null,
      selectedEmployee: this.id,
    };
  },
  watch: {},
  methods: {},
};
</script>

<style></style>
