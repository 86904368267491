<!--

  File: SubcontractForm.vue

  Defines a component that produces a form to list subcontracts belonging
  to a task.

  Props:
    task: The task being edited.

  Events:
    None

-->
<template>
  <section>
    <div class="buttons my-0">
      <b-button
        class="button py-0 mx-0 my-0 is-primary"
        type="is-text"
        size="is-small"
        icon-left="plus"
        @click="openNewForm()"
      />
      <b-button
        class="button py-0 mx-0 my-0 is-primary"
        type="is-text"
        size="is-small"
        icon-left="pencil"
        :disabled="!selected"
        @click="openEditForm()"
      />
    </div>
    <b-table
      :data="task && task.subcontracts ? task.subcontracts.edges : undefined"
      default-sort="node.contractor.Name"
      default-sort-direction="desc"
      :selected.sync="selected"
      @dblclick="openEditForm()"
    >
      <template #empty>
        <div class="hero is-danger">
          <div class="hero-body">
            <h1 class="subtitle">This task does not have any subcontracts.</h1>
          </div>
        </div>
      </template>
      <b-table-column label="Contractor" v-slot="props">
        {{ props.row.node.contractor.Name }}
      </b-table-column>
      <b-table-column label="Period of Performance" v-slot="props">
        {{ props.row.node.POP }}
      </b-table-column>
      <b-table-column label="Funded" numeric v-slot="props">
        {{ toCurrency(props.row.node.LimitAmount) }}
      </b-table-column>
      <b-table-column label="Fee" numeric v-slot="props">
        {{ toCurrency(props.row.node.ProfitAmount) }}
      </b-table-column>
      <b-table-column label="Hours" numeric v-slot="props">
        {{ props.row.node.FundedHours }}
      </b-table-column>
    </b-table>
    <b-modal
      :active="active"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto">
        <form action="">
          <header class="modal-card-head">
            <p class="modal-card-title mr-3">
              {{ this.selected ? "Update Subcontract" : "New Subcontract" }}
            </p>
            <button type="button" class="delete" @click="closeSubForm()" />
          </header>
          <div class="modal-card-body">
            <b-field label="Subcontractor" v-if="autocompleteChoices">
              <b-autocomplete
                ref="subcontractorAutocomplete"
                v-model="input"
                placeholder="Enter a Subcontractor"
                keep-first
                open-on-focus
                :data="filteredChoices"
                field="node.Name"
                @select="
                  (option) =>
                    (subcontract.ContractorPkey = option
                      ? option.node.PKEY
                      : undefined)
                "
                clearable
              >
                <template #header>
                  <div class="buttons my-0">
                    <b-button
                      class="button py-0 mx-0 my-0 is-primary"
                      type="is-text"
                      size="is-small"
                      icon-left="plus"
                      @click="openNewSubcontractor()"
                    />
                    <b-button
                      class="button py-0 mx-0 my-0 is-primary"
                      type="is-text"
                      size="is-small"
                      icon-left="pencil"
                      :disabled="!subcontract.ContractorPkey"
                      @click="openEditSubcontractor()"
                    />
                  </div>
                </template>
              </b-autocomplete>
            </b-field>
            <b-field label="Period of Performance">
              <b-datepicker
                placeholder="Click to select..."
                v-model="POP"
                range
                :date-formatter="
                  (d) => {
                    return (
                      d[0].toISOString().substring(0, 10) +
                      ' to ' +
                      d[1].toISOString().substring(0, 10)
                    );
                  }
                "
                editable
                :date-parser="
                  (s) => {
                    let d = s.replace(' to ', '-').split('-');
                    return [
                      new Date(d[0], d[1] - 1, d[2]),
                      new Date(d[3], d[4] - 1, d[5]),
                    ];
                  }
                "
              >
              </b-datepicker>
            </b-field>
            <b-field label="Funded">
              <b-input
                type="number"
                step=".01"
                icon="currency-usd"
                v-model="subcontract.LimitAmount"
                placeholder="Enter amount"
                required
              >
              </b-input>
            </b-field>
            <b-field label="Fee">
              <b-input
                type="number"
                step=".01"
                icon="currency-usd"
                v-model="subcontract.ProfitAmount"
                placeholder="Enter amount"
                required
              >
              </b-input>
            </b-field>
            <b-field label="Hours">
              <b-input
                type="number"
                step=".25"
                icon="clock"
                v-model="subcontract.FundedHours"
                placeholder="Enter hours"
                required
              >
              </b-input>
            </b-field>
          </div>
          <footer class="modal-card-foot">
            <button
              class="button is-primary"
              type="button"
              :disabled="saveDisabled"
              @click="saveSubcontract()"
            >
              {{ this.selected ? "Save" : "New" }}
            </button>
            <button
              class="button is-danger"
              type="button"
              @click="confirmDeleteSubcontract()"
              v-if="selected"
            >
              Delete
            </button>
          </footer>
        </form>
      </div>
    </b-modal>
    <SubcontractorModal
      :isActive="activeSubcontractorModal"
      :subcontractorPkey="subcontract.ContractorPkey"
      @close="closeSubcontractor($event)"
    />
  </section>
</template>

<script>
import gql from "graphql-tag";
import SubcontractorModal from "./SubcontractorModal";

export default {
  name: "SubcontractForm",
  props: ["taskId"],
  components: { SubcontractorModal },
  apollo: {
    autocompleteChoices: {
      query: gql`
        {
          autocompleteChoices: allContractors(sort: NAME_ASC) {
            edges {
              node {
                PKEY
                Name
              }
            }
          }
        }
      `,
      result() {
        if (!this.$refs.subcontractorAutocomplete) {
          return;
        }
        if (this.subcontract.ContractorPkey) {
          let pkey = this.subcontract.ContractorPkey;
          let choice = this.autocompleteChoices.edges.find(
            (e) => e.node.PKEY == pkey
          );
          this.$refs.subcontractorAutocomplete.setSelected(choice);
        } else {
          this.input = "";
          this.$refs.subcontractorAutocomplete.setSelected(null);
        }
      },
    },
    task: {
      query: gql`
        query ($id: ID!) {
          task(id: $id) {
            id
            PKEY
            StartPOP
            EndPOP
            subcontracts {
              edges {
                node {
                  PKEY
                  TaskPkey
                  ContractorPkey
                  StartDate
                  EndDate
                  LimitAmount
                  ProfitAmount
                  FundedHours
                  contractor {
                    Name
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        // Use vue reactive properties here
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
      update(data) {
        data.task.subcontracts.edges.forEach((e) => {
          e.node.POP = e.node.StartDate + " to " + e.node.EndDate;
        });
        return data.task;
      },
    },
  },
  data() {
    return {
      dates: [],
      selected: undefined,
      active: false,
      activeSubcontractorModal: false,
      input: "",
      subcontract: {
        TaskPkey: undefined,
        StartDate: undefined,
        EndDate: undefined,
        ContractorPkey: undefined,
        LimitAmount: undefined,
        ProfitAmount: undefined,
        FundedHours: undefined,
      },
    };
  },
  computed: {
    saveDisabled() {
      if (this.selected) {
        return (
          this.selected.node.ContractorPkey ===
            this.subcontract.ContractorPkey &&
          this.selected.node.StartDate === this.subcontract.StartDate &&
          this.selected.node.EndDate === this.subcontract.EndDate &&
          this.selected.node.LimitAmount === this.subcontract.LimitAmount &&
          this.selected.node.ProfitAmount === this.subcontract.ProfitAmount &&
          this.selected.node.FundedHours === this.subcontract.FundedHours
        );
      }
      return (
        !this.subcontract.ContractorPkey ||
        !this.subcontract.StartDate ||
        !this.subcontract.EndDate ||
        !this.subcontract.LimitAmount ||
        !this.subcontract.ProfitAmount ||
        !this.subcontract.FundedHours
      );
    },
    filteredChoices() {
      return this.autocompleteChoices.edges.filter((option) => {
        return (
          option.node.Name.toString()
            .toLowerCase()
            .indexOf(this.input.toLowerCase()) >= 0
        );
      });
    },
    POP: {
      get() {
        return [
          new Date(this.subcontract.StartDate + " 00:00:00"),
          new Date(this.subcontract.EndDate + " 00:00:00"),
        ];
      },
      set(newValue) {
        this.subcontract.StartDate = newValue[0].toISOString().substring(0, 10);
        this.subcontract.EndDate = newValue[1].toISOString().substring(0, 10);
      },
    },
  },
  watch: {},
  methods: {
    closeSubForm() {
      this.selected = undefined;
      this.input = "";
      this.dates = [];
      this.active = false;
    },
    openEditForm() {
      this.subcontract = { ...this.selected.node };
      this.input = this.subcontract.contractor.Name;
      delete this.subcontract.__typename; // remove GraphQL __typename artifact
      delete this.subcontract.contractor;
      delete this.subcontract.POP;
      this.active = true;
    },
    openNewForm() {
      this.selected = undefined;
      this.dates[0] = this.task.StartPOP;
      this.dates[1] = this.task.EndPOP;
      this.subcontract = {
        TaskPkey: this.task.PKEY,
        StartDate: this.dates[0],
        EndDate: this.dates[1],
        ContractorPkey: undefined,
        LimitAmount: undefined,
        ProfitAmount: undefined,
        FundedHours: undefined,
      };
      this.active = true;
    },
    toCurrency(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(value);
    },
    saveSubcontract() {
      if (this.selected) {
        this.updateSubcontract();
      } else {
        this.createSubcontract();
      }
    },
    createSubcontract() {
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: SubcontractInput!) {
              createSubcontract(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.subcontract,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Created subcontract!",
            type: "is-success",
          });
          this.$apollo.queries.task.refetch();
          this.closeSubForm();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create subcontract. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
    updateSubcontract() {
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: SubcontractInput!) {
              updateSubcontract(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.subcontract,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Updated subcontract!",
            type: "is-success",
          });
          this.$apollo.queries.task.refetch();
          this.closeSubForm();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to update subcontract. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
    confirmDeleteSubcontract() {
      this.$buefy.dialog.confirm({
        title: "Deleting Subcontract",
        message:
          "Are you sure you want to <b>delete</b> this data? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteSubcontract(),
      });
    },
    deleteSubcontract() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: SubcontractInput!) {
              deleteSubcontract(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.subcontract,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Deleted subcontract!",
            type: "is-success",
          });
          this.$apollo.queries.task.refetch();
          this.closeSubForm();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to delete subcontract. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
    openNewSubcontractor() {
      this.input = "";
      this.subcontract.ContractorPkey = null;
      this.activeSubcontractorModal = true;
    },
    openEditSubcontractor() {
      this.activeSubcontractorModal = true;
    },
    closeSubcontractor(eventData) {
      if (eventData.modified) {
        this.subcontract.ContractorPkey = eventData.PKEY;
        this.$apollo.queries.autocompleteChoices.refetch();
      }
      this.activeSubcontractorModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .animation-content .modal-card {
  overflow: visible !important;
}

::v-deep .modal-card-body {
  overflow: visible !important;
}
</style>
