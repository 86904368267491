<!--

  File: AssignedHours.vue

  Defines a component that displays in a table the number of hours
  assigned by pay period for employees authorized to charge to the
  given task.

  Props:
    taskId: The assigned hours are displayed for this task.

  Events:
    None.

-->
<template>
  <div class="my-0 p-0" :style="windowWidth()">
    <b-table
      :data="assignedHours"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      :loading="$apollo.loading"
      :default-sort="['name', 'asc']"
      focusable
      sticky-header
      :height="tableHeight(assignedHours)"
    >
      <template #empty>
        <div class="hero is-danger">
          <div class="hero-body">
            <h1 class="subtitle">No hours assigned on this task.</h1>
          </div>
        </div>
      </template>
      <b-table-column
        v-for="(c, i) in columns"
        :key="i"
        :label="c.label"
        :field="c.field"
        :sortable="c.sortable"
        :numeric="c.numeric"
        :sticky="c.sticky"
      >
        <template v-slot:header="{ column }">
          <span style="white-space: nowrap">
            {{ column.label }}
          </span>
        </template>
        <template v-slot="props">
          <span style="white-space: nowrap">
            {{ props.row[c.field] }}
          </span>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: ["taskId"],
  apollo: {
    assignedHours: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($task: ID!) {
          assignedHours: task(id: $task) {
            id
            assignedHours {
              edges {
                node {
                  HoursAdjusted
                  payPeriod {
                    PKEY
                    EndDate
                  }
                  employee {
                    Name
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          task: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
      update(data) {
        // pivot data so that there is a column for each pay period
        var pivot = {};
        data.assignedHours.assignedHours.edges.forEach((e) => {
          var name = e.node.employee.Name;
          var pp = e.node.payPeriod.EndDate;
          var hours = e.node.HoursAdjusted;
          var ppkey = e.node.payPeriod.PKEY;
          pivot[name] = pivot[name] || { name: name };
          pivot[name]["maxPP"] = pivot[name]["maxPP"] || -Infinity;
          pivot[name]["minPP"] = pivot[name]["minPP"] || Infinity;
          pivot[name][pp] = hours;
          pivot[name]["maxPP"] = Math.max(pivot[name]["maxPP"], ppkey);
          pivot[name]["minPP"] = Math.min(pivot[name]["minPP"], ppkey);
        });
        return Object.values(pivot).sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      },
      result() {
        this.fillInMissingHours();
      },
    },
    columns: {
      query: gql`
        query ($ppStart: ID!, $ppEnd: ID!) {
          columns: allPayPeriods(
            filters: { PKEYGte: $ppStart, PKEYLte: $ppEnd }
          ) {
            edges {
              node {
                EndDate
              }
            }
          }
        }
      `,
      variables() {
        var ppStart = Math.min(...this.assignedHours.map((x) => x.minPP));
        var ppEnd = Math.max(...this.assignedHours.map((x) => x.maxPP));
        return {
          ppStart: ppStart,
          ppEnd: ppEnd,
        };
      },
      skip() {
        return !this.assignedHours.length;
      },
      update(data) {
        // convert to columns
        var cols = data.columns.edges.map((e) => {
          return {
            field: e.node.EndDate,
            label: e.node.EndDate,
            sortable: false,
            sticky: false,
            numeric: true,
          };
        });
        cols.unshift({
          field: "name",
          label: "Name",
          sortable: true,
          sticky: true,
          numeric: false,
        });
        return cols;
      },
      result() {
        this.fillInMissingHours();
      },
    },
  },
  data() {
    return {
      assignedHours: [],
      columns: [],
    };
  },
  mounted() {
    this.$emit("interface", {
      refresh: () => this.refresh(),
    });
  },
  methods: {
    tableHeight(list) {
      var head = this.columns.length < 12 ? 65 : 90;
      var h2 = list.length > 5 ? 250 : head + 40 * list.length;
      var h = list.length === 0 ? 140 : h2;
      return h;
    },
    windowWidth() {
      var w = window.innerWidth - 150;
      return `width: max-content; max-width: ${w}px;`;
    },
    refresh() {
      Object.values(this.$apollo.queries).forEach((q) => q.refetch());
    },
    fillInMissingHours() {
      if (this.assignedHours.length === 0) return;
      if (this.columns.length === 0) return;

      // overwrite undefined hours with zero
      this.assignedHours.forEach((ah) => {
        this.columns.forEach((c) => {
          ah[c.field] = ah[c.field] || 0;
          if (typeof ah[c.field] !== "number") return;
          ah[c.field] =
            Math.abs(ah[c.field]) > 1e-4 ? ah[c.field].toFixed(2) : 0;
        });
      });
    },
  },
};
</script>

<style></style>
