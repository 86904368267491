<template>
  <div>
    <b-field label="Customer">
      <b-dropdown position="is-bottom-right" aria-role="menu" trap-focus>
        <template #trigger>
          <a class="navbar-item" role="button">
            <h3>{{ selected.Name }}</h3>
            <b-icon icon="menu-down"></b-icon>
          </a>
        </template>
        <b-dropdown-item aria-role="menu-item" :focusable="false" custom>
          <form action="">
            <div class="modal-card" style="width: 300px">
              <div class="columns">
                <div class="column">
                  <div class="level my-0">
                    <div class="buttons level-left my-0">
                      <b-tooltip
                        type="is-info"
                        label="Add new customer"
                        position="is-right"
                        :delay="500"
                      >
                        <b-button
                          class="button is-primary"
                          type="is-text"
                          size="is-small"
                          icon-left="plus"
                          @click="openNewForm()"
                        />
                      </b-tooltip>
                      <b-tooltip
                        type="is-info"
                        label="Edit selected customer"
                        position="is-right"
                        :delay="500"
                      >
                        <b-button
                          class="button is-primary"
                          type="is-text"
                          size="is-small"
                          icon-left="pencil"
                          :disabled="!selected"
                          @click="openEditForm()"
                        />
                      </b-tooltip>
                    </div>
                    <div class="level-right">
                      <HelpDoc id="customer" />
                    </div>
                  </div>
                  <b-table
                    :data="allCustomers"
                    default-sort-direction="asc"
                    sort-icon="arrow-up"
                    sort-icon-size="is-small"
                    :loading="$apollo.loading"
                    default-sort="Name"
                    :selected.sync="selected"
                    focusable
                    sticky-header
                    height="300"
                    @select="$emit('input', $event.PKEY)"
                  >
                    <b-table-column field="Name" searchable>
                      <template #searchable="props">
                        <b-input
                          v-model="props.filters[props.column.field]"
                          placeholder="Search..."
                          icon="magnify"
                          size="is-small"
                        />
                      </template>
                      <template v-slot="props">
                        <span>{{ props.row.Name }}</span>
                      </template>
                    </b-table-column>
                  </b-table>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown-item>
      </b-dropdown>
    </b-field>
  </div>
</template>

<script>
import gql from "graphql-tag";
import HelpDoc from "./HelpDoc";

export default {
  components: {
    HelpDoc,
  },
  props: {
    selectedCustomer: {},
  },
  apollo: {
    // Vue-Apollo options here
    allCustomers: {
      fetchPolicy: "no-cache",
      query: gql`
        {
          allCustomers(sort: NAME_ASC) {
            edges {
              node {
                PKEY
                Name
              }
            }
          }
        }
      `,
      update(data) {
        // remove edges and nodes
        return data.allCustomers.edges.map((e) => e.node);
      },
      result() {
        if (this.selectedCustomer) {
          this.selected = this.allCustomers.find(
            (element) => element.PKEY == this.selectedCustomer
          );
        }
      },
    },
  },

  data() {
    return {
      selected: { Name: "Select a Customer" },
      allCustomers: [],
    };
  },
  watch: {
    selectedCustomer(newId) {
      if (newId && this.allCustomers.length >= 1) {
        this.selected = this.allCustomers.find(
          (element) => element.PKEY == newId
        );
      } else {
        this.selected = { Name: "Select a Customer" };
      }
    },
  },
  methods: {
    openNewForm() {
      this.$buefy.dialog.prompt({
        message: `What is the name of the new customer?`,
        inputAttrs: {
          placeholder: "Enter customer name...",
          maxlength: 60,
        },
        trapFocus: true,
        onConfirm: (value) => this.createCustomer(value),
      });
    },
    openEditForm() {
      this.$buefy.dialog.prompt({
        message: `What is the new name of the customer?`,
        inputAttrs: {
          placeholder: "Enter customer name...",
          maxlength: 60,
          value: this.selected.Name,
        },
        trapFocus: true,
        onConfirm: (value) => this.updateCustomer(value),
      });
    },
    createCustomer(newName) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: CustomerInput!) {
              createCustomer(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: { Name: newName },
          },
        })
        .then(() => {
          // Result
          this.$buefy.toast.open({
            message: "Customer " + newName + " created!",
            type: "is-success",
          });
          this.$apollo.queries.allCustomers.refetch();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create customer " +
              newName +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    updateCustomer(newName) {
      let pkey = this.selected.PKEY;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: CustomerInput!) {
              updateCustomer(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: { PKEY: pkey, Name: newName },
          },
        })
        .then(() => {
          // Result
          this.$buefy.toast.open({
            message: "Customer " + newName + " updated!",
            type: "is-success",
          });
          this.$apollo.queries.allCustomers.refetch();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to update customer " +
              newName +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
  },
};
</script>
