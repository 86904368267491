<!--

  File: HelpDoc.vue

  Defines a component to display instructions to the user.
  The markdown text is store in .md files under @/assets/markdown.
  It uses the VueShowdown component to render markdown text.

  Props:
    id: the key used to find the markdown to display.

  Events:
    None

-->
<template>
  <section>
    <b-icon
      class="is-clickable mx-2"
      size="is-small"
      icon="help"
      @click.native="runHelp()"
    />
    <b-modal v-model="isHelpActive">
      <section class="content has-text-left">
        <b-message>
          <VueShowdown
            flavor="original"
            :markdown="require('@/assets/markdown/' + id + '.md').default"
          />
        </b-message>
      </section>
    </b-modal>
  </section>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      isHelpActive: false,
      helpMessage: undefined,
    };
  },
  methods: {
    runHelp() {
      this.isHelpActive = true;
    },
    closeForm() {
      this.isHelpActive = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
