<!--

  File: DownloadHourAssignments.vue

  Defines a component that displays hour assignments for all employees and
  allows the administrator to download the data in csv format.

  Props:
    None

  Events:
    None

-->
<template>
  <div>
    <div class="container">
      <div class="level">
        <div class="level-left">
          <b-button
            type="is-primary mt-1"
            rounded
            outlined
            icon-left="download"
            :disabled="false"
            @click="downloadCsv()"
          >
            Download CSV
          </b-button>
        </div>
        <div class="level-item">
          <h1 class="title has-text-primary has-text-centered">
            Download Hour Assignments
          </h1>
        </div>
        <div class="level-right">
          <HelpDoc id="download-hour-assignments" />
        </div>
      </div>
      <div class="columns is-centered">
        <b-table
          :data="allHourAssignments.edges"
          sticky-header
          height="750"
          :loading="$apollo.loading"
          striped
        >
          <template #empty>
            <div class="hero is-danger">
              <div class="hero-body">
                <h1 class="subtitle">No hour assignments found.</h1>
              </div>
            </div>
          </template>
          <b-table-column
            field="node.task.AccountingId"
            label="Charge Number"
            searchable
            sortable
          >
            <template #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
              />
            </template>
            <template v-slot="props">
              <span>{{ props.row.node.task.AccountingId }}</span>
            </template>
          </b-table-column>
          <b-table-column
            field="node.employee.Name"
            label="Name"
            searchable
            sortable
          >
            <template #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
              /> </template
            ><template v-slot="props">
              <span>{{ props.row.node.employee.Name }}</span>
            </template>
          </b-table-column>
          <b-table-column
            field="node.LaborCatAccountingId"
            label="Labor Category"
            searchable
            sortable
          >
            <template #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
              /> </template
            ><template v-slot="props">
              <span>{{ props.row.node.LaborCatAccountingId }}</span>
            </template>
          </b-table-column>
          <b-table-column
            field="node.RemainingHours"
            label="PROCAS Hours"
            numeric
          >
            <template v-slot="props">
              <span>{{
                Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(
                  props.row.node.ExpendedHours + props.row.node.RemainingHours,
                )
              }}</span>
            </template>
          </b-table-column>
          <b-table-column field="node.task.EndPOP" label="End POP" sortable>
            <template v-slot="props">
              <span>{{ props.row.node.task.EndPOP }}</span>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import gql from "graphql-tag";
import HelpDoc from "@/components/HelpDoc";

export default {
  components: { HelpDoc },
  apollo: {
    allHourAssignments: {
      query: gql`
        {
          allHourAssignments {
            edges {
              node {
                employee {
                  Name
                }
                task {
                  Id
                  AccountingId
                  EndPOP
                }
                LaborCatAccountingId
                ExpendedHours
                RemainingHours
              }
            }
          }
        }
      `,
    },
  },
  data() {
    return {
      allHourAssignments: { edges: undefined },
    };
  },
  methods: {
    downloadCsv() {
      let csv = "Charge Number,Name,Labor Category,Hours,End POP\n";
      this.allHourAssignments.edges.forEach((row) => {
        csv += row.node.task.AccountingId + ",";
        csv += '"' + row.node.employee.Name + '",';
        csv += '"' + row.node.LaborCatAccountingId + '",';
        csv +=
          (row.node.ExpendedHours + row.node.RemainingHours).toFixed(2) + ",";
        csv += row.node.task.EndPOP;
        csv += "\n";
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "hour-assignments.csv";
      anchor.click();
    },
  },
};
</script>
