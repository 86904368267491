<!--

  File: LaborCategoryAssignments.vue

  Defines a component that produces a form to assign employees to labor categories.

  Props:
    taskId: The id of the task being edited.

  Events:

-->
<template>
  <section>
    <div class="buttons my-0">
      <b-button
        class="button py-0 mx-0 my-0 is-primary"
        type="is-text"
        size="is-small"
        icon-left="plus"
        @click="openNewForm()"
      />
      <b-button
        class="button py-0 mx-0 my-0 is-primary"
        type="is-text"
        size="is-small"
        icon-left="pencil"
        :disabled="!selected"
        @click="openEditForm()"
      />
    </div>
    <b-table
      v-if="task"
      :data="
        task && task.laborCategoryAssignments
          ? task.laborCategoryAssignments
          : undefined
      "
      default-sort="employee.Name"
      default-sort-direction="asc"
      :selected.sync="selected"
      @dblclick="openEditForm()"
      sticky-header
    >
      <template #empty>
        <div class="hero is-danger">
          <div class="hero-body">
            <h1 class="subtitle">
              This task does not have any labor category assignments.
            </h1>
          </div>
        </div>
      </template>
      <b-table-column
        label="Employee"
        field="employee.Name"
        sortable
        v-slot="props"
      >
        {{ props.row.employee.Name }}
      </b-table-column>
      <b-table-column
        :label="'Accounting Id'"
        field="laborCategory.AccountingId"
        sortable
        v-slot="props"
        v-if="task.fundingSourceType.Enum === 2"
      >
        {{ props.row.laborCategory.AccountingId }}
      </b-table-column>
      <b-table-column
        :label="
          task.fundingSourceType.Enum === 2 ? 'Labor Category' : 'OH Rate'
        "
        field="laborCategory.Id"
        sortable
        v-slot="props"
      >
        {{ props.row.laborCategory.Id }}
      </b-table-column>
      <b-table-column label="Date Range" v-slot="props">
        {{ props.row.startingPayPeriod.StartDate }} to
        {{ props.row.endingPayPeriod.EndDate }}
      </b-table-column>
    </b-table>
    <LaborCategoryAssignmentForm
      v-if="task"
      :laborCategoryAssignmentId="selected ? selected.id : undefined"
      :taskId="task.id"
      :active="formActive"
      @close="formActive = false"
      @modified="onFormChanged"
    />
  </section>
</template>

<script>
import gql from "graphql-tag";
import LaborCategoryAssignmentForm from "@/components/LaborCategoryAssignmentForm";

export default {
  components: { LaborCategoryAssignmentForm },
  props: {
    taskId: { type: String },
  },
  apollo: {
    task: {
      query: gql`
        query ($id: ID!) {
          task: task(id: $id) {
            id
            PKEY
            fundingSourceType {
              Enum
              id
            }
            laborCategoryAssignments {
              id
              PKEY
              LaborCategoryPkey
              EmployeePkey
              StartingPayPeriod
              EndingPayPeriod
              laborCategory {
                id
                Id
                AccountingId
              }
              employee {
                id
                Name
              }
              startingPayPeriod {
                StartDate
              }
              endingPayPeriod {
                EndDate
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
  data() {
    return {
      formActive: false,
      selected: undefined,
    };
  },
  methods: {
    openNewForm() {
      this.selected = undefined;
      this.formActive = true;
    },
    openEditForm() {
      this.formActive = true;
    },
    onFormChanged() {
      this.$apollo.queries.task.refetch();
      this.selected = undefined;
      this.formActive = false;
    },
  },
};
</script>

<style scoped></style>
