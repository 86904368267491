<!--

  File: ExpendedHours.vue

  Defines a component that displays the ExpendedHoursTable component with a 
  pop-out button. When the button is pressed the table expands to full screen.

  Props:
    taskId: The actual hours expended is displayed for this task.

  Events:
    None.

-->
<template>
  <div class="my-0 p-0" :style="windowWidth()">
    <div class="level m-0 p-0">
      <div class="level-left"></div>
      <div class="level-right">
        <b-tooltip
          type="is-info"
          label="Pop out"
          position="is-right"
          :delay="500"
        >
          <b-icon
            class="is-clickable"
            size="is-small"
            icon="arrow-expand"
            @click.native="popOut()"
          />
        </b-tooltip>
      </div>
    </div>
    <ExpendedHoursTable :taskId="taskId" :fullScreen="false" />
  </div>
</template>

<script>
import ExpendedHoursTable from "./ExpendedHoursTable.vue";

export default {
  props: ["taskId", "fullWidth"],
  components: { ExpendedHoursTable },
  methods: {
    popOut() {
      this.$buefy.modal.open({
        parent: this,
        component: ExpendedHoursTable,
        props: { taskId: this.taskId, fullScreen: true },
        hasModalCard: false,
        trapFocus: true,
        fullScreen: true,
      });
    },
    windowWidth() {
      if (this.fullWidth) {
        var w = window.innerWidth - 150;
        return `width: max-content; max-width: ${w}px;`;
      }
      return "width: max-content; max-width: 1025px;";
    },
  },
};
</script>

<style></style>
