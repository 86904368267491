<!--

  File: Invoices.vue

  Defines a component that produces a form to edit subcontract invoices.

  Props:
    subcontract: The subcontract record to edit.

  Events:
    refetch: Emitted when invoices have changed to instruct parent to refetch
             the data from GraphQL.

-->
<template>
  <section>
    <div class="buttons my-0">
      <b-button
        class="button py-0 mx-0 my-0 is-primary"
        type="is-text"
        size="is-small"
        icon-left="plus"
        :disabled="!subcontract"
        @click="openNewForm()"
      />
      <b-button
        class="button py-0 mx-0 my-0 is-primary"
        type="is-text"
        size="is-small"
        icon-left="pencil"
        :disabled="!selected"
        @click="openEditForm()"
      />
    </div>
    <b-table
      :data="subcontract ? subcontract.invoices.edges : undefined"
      default-sort="node.DateIncurred"
      default-sort-direction="desc"
      :selected.sync="selected"
      @dblclick="openEditForm()"
    >
      <template #empty>
        <div class="hero is-danger">
          <div class="hero-body">
            <h1 class="subtitle" v-if="!subcontract">
              Enter a task and contractor to see a list of invoices.
            </h1>
            <h1 class="subtitle" v-else>
              This subcontract does not have any invoices.
            </h1>
          </div>
        </div>
      </template>
      <b-table-column
        label="Date"
        field="node.DateIncurred"
        sortable
        v-slot="props"
      >
        {{ props.row.node.DateIncurred }}
      </b-table-column>
      <b-table-column label="Hours" numeric v-slot="props">
        {{ props.row.node.Hours }}
      </b-table-column>
      <b-table-column label="Amount" numeric v-slot="props">
        {{ toCurrency(props.row.node.Amount) }}
      </b-table-column>
    </b-table>
    <b-modal
      :active="active"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto">
        <form action="">
          <header class="modal-card-head">
            <p class="modal-card-title">
              {{ this.selected ? "Update Invoice" : "New Invoice" }}
            </p>
            <button type="button" class="delete" @click="closeSubForm()" />
          </header>
          <div class="modal-card-body">
            <b-field label="Date">
              <b-datepicker
                v-model="invoice.DateIncurred"
                placeholder="Enter date of invoice"
                icon="calendar"
                @input="onChange()"
                trap-focus
                required
                editable
                :date-formatter="
                  (d) => {
                    return d.toISOString().substring(0, 10);
                  }
                "
                :date-parser="
                  (s) => {
                    return new Date(s);
                  }
                "
              />
            </b-field>
            <b-field label="Hours">
              <b-input
                type="number"
                step=".01"
                icon="clock"
                v-model="invoice.Hours"
                placeholder="Enter hours"
                @change.native="onChange()"
                required
              >
              </b-input>
            </b-field>
            <b-field label="Amount">
              <b-input
                type="number"
                step=".01"
                icon="currency-usd"
                v-model="invoice.Amount"
                placeholder="Enter amount"
                @change.native="onChange()"
                required
              >
              </b-input>
            </b-field>
          </div>
          <footer class="modal-card-foot">
            <button
              class="button is-primary"
              type="button"
              :disabled="!modified"
              @click="saveInvoice()"
            >
              {{ this.selected ? "Save" : "New" }}
            </button>
            <button
              class="button is-danger"
              type="button"
              @click="confirmDeleteInvoice()"
              v-if="selected"
            >
              Delete
            </button>
          </footer>
        </form>
      </div>
    </b-modal>
  </section>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: ["subcontract"],
  components: {},
  data() {
    return {
      modified: false,
      selected: undefined,
      active: false,
      invoice: {},
    };
  },
  computed: {},
  methods: {
    onChange() {
      this.modified = true;
    },
    closeSubForm() {
      this.selected = undefined;
      this.modified = false;
      this.active = false;
    },
    openEditForm() {
      this.invoice = { ...this.selected.node };
      let d = this.invoice.DateIncurred + " 00:00:00";
      this.invoice.DateIncurred = new Date(d);
      this.active = true;
    },
    openNewForm() {
      this.selected = undefined;
      this.invoice = {
        SubcontractPkey: this.subcontract.PKEY,
        DateIncurred: new Date(),
        Hours: undefined,
        Amount: undefined,
      };
      this.active = true;
    },
    toCurrency(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(value);
    },
    saveInvoice() {
      if (this.selected) {
        this.updateInvoice();
      } else {
        this.createInvoice(this.invoice);
      }
    },
    updateInvoice() {
      // remove GraphQL __typename artifact
      delete this.invoice.__typename;
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: ContractorInvoiceInput!) {
              updateContractorInvoice(input: $input) {
                ok
                ContractorInvoice {
                  PKEY
                  SubcontractPkey
                  Hours
                  DateIncurred
                  Amount
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: this.invoice,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Updated subcontract invoice!",
            type: "is-success",
          });
          this.$emit("refetch");
          this.closeSubForm();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to update subcontract invoice. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
    createInvoice(invoice) {
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: ContractorInvoiceInput!) {
              createContractorInvoice(input: $input) {
                ok
                ContractorInvoice {
                  PKEY
                  SubcontractPkey
                  Hours
                  DateIncurred
                  Amount
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: invoice,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Created subcontract invoice!",
            type: "is-success",
          });
          this.$emit("refetch");
          this.closeSubForm();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create subcontract invoice. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
    confirmDeleteInvoice() {
      this.$buefy.dialog.confirm({
        title: "Deleting subcontract invoice",
        message:
          "Are you sure you want to <b>delete</b> this data? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteInvoice(),
      });
    },
    deleteInvoice() {
      // remove GraphQL __typename artifact
      delete this.invoice.__typename;
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: ContractorInvoiceInput!) {
              deleteContractorInvoice(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.invoice,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Deleted subcontract invoice!",
            type: "is-success",
          });
          this.$emit("refetch");
          this.closeSubForm();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to delete subcontract invoice. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .animation-content .modal-card {
  overflow: visible !important;
}

::v-deep .modal-card-body {
  overflow: visible !important;
}
</style>
