<!--

  File: Admin.vue

  Defines a component that produces the admin pages.

  Props:
    selection: The admin page to display.

  Events:
    None.

-->
<template>
  <div class="columns">
    <Employees class="column" v-if="subApp === 'EMPLOYEES'" />
    <CompanyRates class="column" v-if="subApp === 'COMPANY-RATES'" />
    <Contracts class="column p-0" v-if="subApp === 'CONTRACTS'" />
    <SubcontractInvoices
      class="column p-00"
      v-if="subApp === 'SUBCONTRACT-INVOICES'"
    />
    <UploadOdcSteps v-if="subApp === 'UPLOAD-ODC-EXPENDITURES'" />
    <UploadSalariesSteps v-if="subApp === 'UPLOAD-NEW-SALARIES'" />
    <UploadTimesheetSteps v-if="subApp === 'UPLOAD-TIMESHEET-INFORMATION'" />
    <DownloadHourAssignments
      class="column"
      v-if="subApp === 'DOWNLOAD-HOUR-ASSIGNMENTS'"
    />
  </div>
</template>

<script>
import CompanyRates from "@/components/CompanyRates";
import DownloadHourAssignments from "@/components/DownloadHourAssignments";
import Employees from "@/components/Employees.vue";
import Contracts from "./Contracts";
import SubcontractInvoices from "@/components/SubcontractInvoices";
import UploadOdcSteps from "@/components/UploadOdcSteps";
import UploadSalariesSteps from "@/components/UploadSalariesSteps";
import UploadTimesheetSteps from "@/components/UploadTimesheetSteps";

export default {
  components: {
    CompanyRates,
    DownloadHourAssignments,
    Employees,
    Contracts,
    SubcontractInvoices,
    UploadOdcSteps,
    UploadSalariesSteps,
    UploadTimesheetSteps,
  },
  props: ["selection"],
  data() {
    return {
      subApp: this.selection,
      open: false,
      startDate: new Date(),
    };
  },
  watch: {
    selection() {
      this.subApp = this.selection;
    },
  },
  methods: {
    setSubApp(subApp) {
      this.subApp = subApp;
      this.open = false;
    },
  },
};
</script>
