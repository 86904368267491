<!--

  File: LaborCategories.vue

  Defines a component that produces a form to edit labor category data.

  Props:
    taskId: The id of the task being edited.

  Events:

-->
<template>
  <section>
    <div v-if="task && task.fundingSourceType.Enum === 2">
      <div class="buttons my-0">
        <b-button
          class="button py-0 mx-0 my-0 is-primary"
          type="is-text"
          size="is-small"
          icon-left="plus"
          @click="openNewForm()"
        />
        <b-button
          class="button py-0 mx-0 my-0 is-primary"
          type="is-text"
          size="is-small"
          icon-left="pencil"
          :disabled="!selected"
          @click="openEditForm()"
        />
      </div>
      <b-table
        :data="task && task.laborCategories ? task.laborCategories : undefined"
        default-sort="node.contractor.Name"
        default-sort-direction="desc"
        :selected.sync="selected"
        @dblclick="openEditForm()"
      >
        <template #empty>
          <div class="hero is-danger">
            <div class="hero-body">
              <h1 class="subtitle">
                This task does not have any labor categories.
              </h1>
            </div>
          </div>
        </template>
        <b-table-column label="Accounting Id" v-slot="props">
          {{ props.row.AccountingId }}
        </b-table-column>
        <b-table-column label="Id" v-slot="props">
          {{ props.row.Id }}
        </b-table-column>
        <b-table-column label="Description" v-slot="props">
          {{ props.row.Description }}
        </b-table-column>
        <b-table-column label="Ceiling" numeric v-slot="props">
          {{ toCurrency(props.row.LimitAmount) }}
        </b-table-column>
      </b-table>
    </div>
    <div class="hero is-info" v-else>
      <div class="hero-body">
        <h1 class="subtitle">
          Only T&M tasks have modifiable labor categories.
        </h1>
      </div>
    </div>
    <LaborCategoryForm
      v-if="task"
      :laborCategoryId="selected ? selected.id : undefined"
      :task="{ PKEY: task.PKEY, StartPOP: task.StartPOP, EndPOP: task.EndPOP }"
      :active="laborCategoryFormActive"
      @close="laborCategoryFormActive = false"
      @modified="onFormChanged"
    />
  </section>
</template>

<script>
import gql from "graphql-tag";
import LaborCategoryForm from "@/components/LaborCategoryForm";

export default {
  components: { LaborCategoryForm },
  props: {
    taskId: { type: String },
  },
  apollo: {
    task: {
      query: gql`
        query ($id: ID!) {
          task: task(id: $id) {
            id
            PKEY
            StartPOP
            EndPOP
            fundingSourceType {
              id
              Enum
            }
            laborCategories {
              id
              PKEY
              TaskPkey
              AccountingId
              Id
              Description
              LimitAmount
            }
          }
        }
      `,
      variables() {
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
  data() {
    return {
      laborCategoryFormActive: false,
      selected: undefined,
    };
  },
  methods: {
    toCurrency(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(value);
    },
    openNewForm() {
      this.selected = undefined;
      this.laborCategoryFormActive = true;
    },
    openEditForm() {
      this.laborCategoryFormActive = true;
    },
    onFormChanged() {
      this.$apollo.queries.task.refetch();
      this.selected = undefined;
      this.laborCategoryFormActive = false;
    },
  },
};
</script>

<style scoped></style>
