<!--

  File: UploadTimesheetSteps.vue

  Defines a component that walks the user through a series of steps to
  upload a file downloaded from PROCAS containing timesheet data.

  Props:
    None

  Events:
    None

-->
<template>
  <div class="container mt-4">
    <div class="level">
      <div class="level-item">
        <h1 class="title has-text-primary has-text-centered">
          Upload Timesheet Information
        </h1>
      </div>
      <div class="level-right">
        <HelpDoc id="upload-timesheet" />
      </div>
    </div>
    <b-steps type="is-primary" v-model="activeStep">
      <b-step-item type="is-primary" step="1" label="Upload">
        <h2 class="subtitle has-text-primary has-text-centered">
          {{ lastPayPeriodUploaded.EndDate }} is the end date of last pay period
          uploaded
        </h2>
        <div class="container">
          <b-field>
            <b-upload
              v-model="procasFile"
              expanded
              @input="readProcasFile()"
              drag-drop
              :loading="loading"
              :disabled="loading"
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"></b-icon>
                  </p>
                  <p>Drop your files here or click to upload</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div class="tags">
            <span class="tag is-primary" v-if="procasFile">
              {{ procasFile.name }}
              <button
                class="delete is-small"
                type="button"
                @click="removeProcasFile()"
              ></button>
            </span>
          </div>
        </div>
      </b-step-item>

      <b-step-item type="is-primary" step="2" label="Review">
        <h1 class="subtitle has-text-primary has-text-centered">
          Review the uploaded information
        </h1>
        <div class="columns is-vcentered" v-if="procasData.length > 0">
          <div class="column">
            <div
              class="hero is-small is-danger"
              v-if="uploadStatus && numErrors > 0"
            >
              <div class="hero-body">
                <b-dropdown
                  position="is-bottom-left"
                  append-to-body
                  aria-role="menu"
                  trap-focus
                  expanded
                  scrollable
                  max-height="600"
                >
                  <template #trigger>
                    <a class="navbar-item" role="button">
                      <span>Errors in upload [{{ numErrors }}]</span>
                      <b-icon icon="menu-down" />
                    </a>
                  </template>
                  <b-dropdown-item
                    aria-role="menuitem"
                    :focusable="false"
                    custom
                    paddingless
                  >
                    <div class="card">
                      <div class="card-content">
                        <span :class="bannerClass(uploadStatus.missingTasks)">
                          Missing tasks [{{ uploadStatus.missingTasks.length }}]
                        </span>
                        <b-table
                          :data="uploadStatus.missingTasks"
                          :show-header="false"
                          :columns="[{ field: 'ChargeNumber' }]"
                        />
                        <span
                          :class="bannerClass(uploadStatus.missingEmployees)"
                        >
                          Missing employees [{{
                            uploadStatus.missingEmployees.length
                          }}]
                        </span>
                        <b-table
                          :data="uploadStatus.missingEmployees"
                          :show-header="false"
                          :columns="[{ field: 'EmployeeName' }]"
                        />
                        <span
                          :class="
                            bannerClass(uploadStatus.employeesNotAssignedToTask)
                          "
                        >
                          Employees not assigned to a task [{{
                            uploadStatus.employeesNotAssignedToTask.length
                          }}]
                        </span>
                        <b-table
                          :data="uploadStatus.employeesNotAssignedToTask"
                          :show-header="false"
                          :columns="[
                            { field: 'EmployeeName' },
                            { field: 'ChargeNumber' },
                          ]"
                        />
                        <span
                          :class="
                            bannerClass(
                              uploadStatus.outsideTaskPOP.filter(
                                (ups) => ups.Error
                              )
                            )
                          "
                        >
                          Incurred hours are outside task POP [{{
                            uploadStatus.outsideTaskPOP.filter(
                              (ups) => ups.Error
                            ).length
                          }}]
                        </span>
                        <b-table
                          :data="
                            uploadStatus.outsideTaskPOP.filter(
                              (ups) => ups.Error
                            )
                          "
                          :show-header="false"
                          :columns="[
                            { field: 'EmployeeName' },
                            { field: 'ChargeNumber' },
                          ]"
                        />
                        <span
                          :class="
                            warningBannerClass(
                              uploadStatus.outsideTaskPOP.filter(
                                (ups) => !ups.Error
                              )
                            )
                          "
                        >
                          Incurred hours are in last pay period of the task [{{
                            uploadStatus.outsideTaskPOP.filter(
                              (ups) => !ups.Error
                            ).length
                          }}]
                        </span>
                        <b-table
                          :data="
                            uploadStatus.outsideTaskPOP.filter(
                              (ups) => !ups.Error
                            )
                          "
                          :show-header="false"
                          :columns="[
                            { field: 'EmployeeName' },
                            { field: 'ChargeNumber' },
                          ]"
                        />
                        <span
                          :class="
                            bannerClass(
                              uploadStatus.missingLaborCategoryHourlyRate
                            )
                          "
                        >
                          Missing labor category hourly rate [{{
                            uploadStatus.missingLaborCategoryHourlyRate.length
                          }}]
                        </span>
                        <b-table
                          :data="uploadStatus.missingLaborCategoryHourlyRate"
                          :show-header="false"
                          :columns="[
                            { field: 'ChargeNumber' },
                            { field: 'LaborCategory' },
                          ]"
                        />
                        <span :class="bannerClass(uploadStatus.missingSalary)">
                          Missing salary data [{{
                            uploadStatus.missingSalary.length
                          }}]
                        </span>
                        <b-table
                          :data="uploadStatus.missingSalary"
                          :show-header="false"
                          :columns="[{ field: 'EmployeeName' }]"
                        />
                        <span
                          :class="bannerClass(uploadStatus.laborCatMismatch)"
                        >
                          Mismatched labor categories [{{
                            uploadStatus.laborCatMismatch.length
                          }}]
                        </span>
                        <b-table
                          :data="uploadStatus.laborCatMismatch"
                          :show-header="true"
                          :columns="[
                            { field: 'EmployeeName', label: 'Employee' },
                            { field: 'ChargeNumber', label: 'ChargeNumber' },
                            { field: 'UploadLaborCat', label: 'Uploaded' },
                            { field: 'AssignedLaborCat', label: 'Assigned' },
                          ]"
                        />
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="hero is-small is-success" v-else>
              <div class="hero-body">Upload does not contain errors</div>
            </div>
          </div>
          <div class="column">
            <div
              class="hero is-small is-warning"
              v-if="uploadStatus && uploadStatus.numExistingRecords > 0"
            >
              <div class="hero-body">
                {{ uploadStatus.numExistingRecords }} existing timesheet records
                will be deleted
              </div>
            </div>
            <div class="hero is-small is-success" v-else>
              <div class="hero-body">No existing records will be deleted</div>
            </div>
          </div>
        </div>
        <b-table
          :data="procasData"
          :columns="procasDataColumns"
          sticky-header
          height="500"
        >
          <template #empty>
            <div class="hero is-danger">
              <div class="hero-body">
                <h1 class="subtitle">
                  First, upload a PROCAS timesheet file on the previous step
                </h1>
              </div>
            </div>
          </template>
        </b-table>
      </b-step-item>

      <b-step-item type="is-primary" step="3" label="Confirm">
        <h1 class="subtitle has-text-primary has-text-centered">
          Confirm upload
        </h1>
        <div v-if="procasData.length == 0" class="hero is-danger">
          <div class="hero-body">
            <h1 class="subtitle">
              Timesheet information was not uploaded, go back to step 1
            </h1>
          </div>
        </div>
        <div v-else>
          <b-message
            v-if="uploadStatus && !uploadStatus.ok"
            type="is-danger"
            title="Errors in upload"
            :closable="false"
          >
            There are errors in the uploaded timesheet information. Go to the
            previous step to view problems. Only valid records in the uploaded
            file will be saved if the confirm button is pressed.
          </b-message>
          <b-message
            v-if="uploadStatus && uploadStatus.numExistingRecords > 0"
            type="is-warning"
            title="Overwrite warning"
            :closable="false"
          >
            Existing data matching the pay period(s) being uploaded will be
            deleted.
          </b-message>
          <h1 class="subtitle has-text-primary has-text-centered">
            Press the confirm button to store timesheet information
          </h1>
          <div class="buttons is-centered">
            <b-button
              :type="
                uploadStatus && uploadStatus.ok
                  ? uploadStatus && uploadStatus.numExistingRecords > 0
                    ? 'is-warning'
                    : 'is-success'
                  : 'is-danger'
              "
              @click="commitData()"
              icon-left="upload"
              :loading="loading"
            >
              Confirm upload
            </b-button>
          </div>
        </div>
      </b-step-item>
      <template #navigation="{ previous, next }">
        <div class="buttons is-centered">
          <b-button
            outlined
            type="is-primary"
            icon-left="arrow-left"
            :disabled="previous.disabled"
            @click.prevent="previous.action"
          >
            Previous
          </b-button>
          <b-button
            outlined
            type="is-primary"
            icon-right="arrow-right"
            :disabled="next.disabled"
            @click.prevent="next.action"
          >
            Next
          </b-button>
        </div>
      </template>
    </b-steps>
  </div>
</template>

<script>
import gql from "graphql-tag";
import HelpDoc from "@/components/HelpDoc";

const papa = require("papaparse");
export default {
  components: { HelpDoc },
  apollo: {
    // Vue-Apollo options here
    lastPayPeriodUploaded: {
      fetchPolicy: "no-cache",
      query: gql`
        query {
          lastPayPeriodUploaded {
            EndDate
          }
        }
      `,
    },
  },
  data() {
    return {
      activeStep: 0,
      lastPayPeriodUploaded: { EndDate: null },
      loading: false,
      procasFile: null,
      procasData: [],
      uploadStatus: null,
      commitStatus: null,
      procasDataColumns: [
        { label: "Charge Number", column: 1 },
        // columns 2-5 are not used
        { label: "Labor Category", column: 6 },
        // columns 7-8 are not used
        { label: "Period End Date", column: 9 },
        // columns 10-11 are not used
        { label: "Employee Number", numeric: true, column: 12 },
        { label: "Employee Name", column: 13 },
        // column 14 is not used
        { label: "Hours", numeric: true, column: 15 },
        // columns 16-19 are not used
        { label: "Base Labor Cost", numeric: true, column: 20 },
        // columns 21-43 are not used
      ],
    };
  },
  mounted() {
    for (let c of this.procasDataColumns) {
      c.field = c.label.replace(/\s+/g, "");
    }
  },
  computed: {
    numErrors() {
      return (
        this.uploadStatus.missingTasks.length +
        this.uploadStatus.missingEmployees.length +
        this.uploadStatus.missingSalary.length +
        this.uploadStatus.laborCatMismatch.length +
        this.uploadStatus.employeesNotAssignedToTask.length +
        this.uploadStatus.outsideTaskPOP.length +
        this.uploadStatus.missingLaborCategoryHourlyRate.length
      );
    },
  },
  methods: {
    bannerClass(list) {
      return list.length ? "hero is-danger" : "hero is-success";
    },
    warningBannerClass(list) {
      return list.length ? "hero is-warning" : "hero is-success";
    },
    readProcasFile() {
      this.loading = true;
      let vm = this;
      vm.procasData = [];
      papa.parse(this.procasFile, {
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
          let rows = results.data;
          for (const r of rows) {
            let data = {};
            for (const c of vm.procasDataColumns) {
              data[c.field] = r[c.column - 1];
            }
            vm.procasData.push(data);
          }
          vm.uploadData();
        },
      });
    },
    uploadData() {
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($rows: [TimesheetUploadInput]) {
              createTimesheetUpload(input: $rows) {
                ok
                uploadSize
                numExistingRecords
                missingTasks {
                  ChargeNumber
                }
                missingEmployees {
                  EmployeeName
                }
                missingSalary {
                  EmployeeName
                }
                laborCatMismatch {
                  EmployeeName
                  ChargeNumber
                  UploadLaborCat
                  AssignedLaborCat
                }
                employeesNotAssignedToTask {
                  EmployeeName
                  ChargeNumber
                }
                outsideTaskPOP {
                  EmployeeName
                  ChargeNumber
                  Error
                }
                missingLaborCategoryHourlyRate {
                  ChargeNumber
                  LaborCategory
                }
              }
            }
          `,
          // Parameters
          variables: {
            rows: this.procasData,
          },
        })
        .then((data) => {
          // Result
          this.uploadStatus = data.data.createTimesheetUpload;
          let nrows = data.data.createTimesheetUpload.uploadSize;
          this.$buefy.toast.open({
            message: "Uploaded " + nrows + " timesheet records!",
            type: "is-success",
            duration: 5000,
          });
          this.activeStep = 1;
          this.loading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Problem uploading data. Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
          this.loading = false;
        });
    },
    commitData() {
      this.loading = true;
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation {
              commitTimesheetUpload {
                ok
                numRecordsSaved
              }
            }
          `,
        })
        .then((data) => {
          // Result
          this.commitStatus = data.data.commitTimesheetUpload;
          let nrows = data.data.commitTimesheetUpload.numRecordsSaved;
          this.$buefy.toast.open({
            message: "Committed " + nrows + " timesheet records!",
            type: "is-success",
            duration: 5000,
          });
          this.activeStep = 0;
          this.removeProcasFile();
          this.loading = false;
          this.$apollo.getClient().clearStore(); // invalidate all cached data
          this.$apollo.queries.lastPayPeriodUploaded.refetch();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Problem committing data. Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
          this.loading = false;
          this.$apollo.queries.lastPayPeriodUploaded.refetch();
        });
    },
    removeProcasFile() {
      this.procasData = [];
      this.procasFields = [];
      this.procasFile = null;
      this.uploadStatus = null;
      this.commitStatus = null;
    },
  },
};
</script>
<style lang="scss" scoped></style>
