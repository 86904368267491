<!--

  File: SubcontractSummary.vue

  Defines a component that displays the subcontracts associated with a task.

  Props:
    None

  Events:
    None

-->
<template>
  <div class="tile">
    <div class="panel my-0">
      <b-table
        v-if="task"
        :data="task.subcontracts.edges"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        :loading="$apollo.loading"
        default-sort="node.contractor.Name"
        sticky-header
        :height="tableHeight(task.subcontracts.edges)"
      >
        <template #empty>
          <div class="hero is-danger">
            <div class="hero-body">
              <h1 class="subtitle">
                There are no subcontracts associated with this task.
              </h1>
            </div>
          </div>
        </template>
        <b-table-column
          field="node.contractor.Name"
          label="Contractor"
          sortable
        >
          <template v-slot="props">
            {{ props.row.node.contractor.Name }}
          </template>
        </b-table-column>
        <b-table-column field="node.EndDate" label="End" sortable>
          <template v-slot="props">
            {{ props.row.node.EndDate }}
          </template>
        </b-table-column>
        <b-table-column
          field="node.LimitAmount"
          label="Funded"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.row.node.LimitAmount)
          }}
        </b-table-column>
        <b-table-column
          field="node.ProfitAmount"
          label="Fee"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.row.node.ProfitAmount)
          }}
        </b-table-column>
        <b-table-column
          field="node.FundedHours"
          label="Funded Hours"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
            }).format(props.row.node.FundedHours)
          }}
        </b-table-column>
        <b-table-column
          field="node.invoiceSummary.TotalBilled"
          label="Billed"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.row.node.invoiceSummary.TotalBilled)
          }}
        </b-table-column>
        <b-table-column
          field="node.invoiceSummary.TotalHours"
          label="Billed Hours"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
            }).format(props.row.node.invoiceSummary.TotalHours)
          }}
        </b-table-column>
        <b-table-column
          field="node.invoiceSummary.Remaining"
          label="Remaining"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.row.node.invoiceSummary.Remaining)
          }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>
<script>
import gql from "graphql-tag";

export default {
  props: ["taskId"],
  components: {},
  apollo: {
    task: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          task(id: $id) {
            PKEY
            subcontracts {
              edges {
                node {
                  LimitAmount
                  FundedHours
                  ProfitAmount
                  EndDate
                  invoiceSummary {
                    TotalHours
                    TotalBilled
                    Remaining
                  }
                  contractor {
                    Name
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        // Use vue reactive properties here
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
  data() {
    return { task: undefined };
  },
  computed: {},
  methods: {
    tableHeight(list) {
      var h =
        list.length === 0 ? 180 : list.length > 5 ? 250 : 50 + 40 * list.length;
      return h;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .table-wrapper {
  overflow-x: hidden;
}
</style>
