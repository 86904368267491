var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"active":_vm.active,"can-cancel":false,"has-modal-card":"","trap-focus":"","destroy-on-hide":true,"aria-role":"dialog","aria-modal":""}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title mr-3"},[_vm._v(" "+_vm._s(_vm.laborCategoryAssignmentId ? "Update Labor Category Assignment" : "New Labor Category Assignment")+" ")]),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeForm()}}})]),(_vm.laborCategoryAssignment)?_c('div',{staticClass:"modal-card-body"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Employee"}},[_c('b-autocomplete',{ref:"autocomplete",attrs:{"rounded":"","placeholder":"Enter employee name","data":_vm.filteredEmployeeChoices,"keep-first":true,"open-on-focus":true,"field":"node.Name","clearable":true},on:{"select":(option) =>
                  (_vm.laborCategoryAssignment.EmployeePkey = option
                    ? option.node.PKEY
                    : undefined)},model:{value:(_vm.inputEmployee),callback:function ($$v) {_vm.inputEmployee=$$v},expression:"inputEmployee"}})],1),_c('b-field',{attrs:{"label":_vm.task.fundingSourceType.Enum === 2 ? 'Labor Category' : 'OH Rate'}},[_c('b-select',{attrs:{"placeholder":"Select a labor category"},model:{value:(_vm.laborCategoryAssignment.LaborCategoryPkey),callback:function ($$v) {_vm.$set(_vm.laborCategoryAssignment, "LaborCategoryPkey", $$v)},expression:"laborCategoryAssignment.LaborCategoryPkey"}},_vm._l((_vm.task.laborCategories),function(lc){return _c('option',{key:lc.PKEY,domProps:{"value":lc.PKEY}},[_vm._v(" "+_vm._s(_vm.task.fundingSourceType.Enum === 2 ? lc.AccountingId : lc.Id)+" ")])}),0)],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Date Range"}},[_c('b-datepicker',{attrs:{"placeholder":"Click to select...","min-date":_vm.task.StartDate,"max-date":_vm.task.EndDate,"range":"","date-formatter":(d) => {
                  return (
                    d[0].toISOString().substring(0, 10) +
                    ' to ' +
                    d[1].toISOString().substring(0, 10)
                  );
                },"editable":"","date-parser":(s) => {
                  let d = s.replace(' to ', '-').split('-');
                  return [
                    new Date(d[0], d[1] - 1, d[2]),
                    new Date(d[3], d[4] - 1, d[5]),
                  ];
                },"position":"is-bottom-left"},model:{value:(_vm.laborCategoryAssignment.dateRange),callback:function ($$v) {_vm.$set(_vm.laborCategoryAssignment, "dateRange", $$v)},expression:"laborCategoryAssignment.dateRange"}})],1)],1)])]):_vm._e(),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-primary",attrs:{"type":"button","disabled":_vm.saveDisabled},on:{"click":function($event){return _vm.saveLaborCategoryAssignment()}}},[_vm._v(" "+_vm._s(_vm.laborCategoryAssignmentId ? "Save" : "New")+" ")]),(_vm.laborCategoryAssignmentId)?_c('button',{staticClass:"button is-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.confirmDeleteLaborCategoryAssignment()}}},[_vm._v(" Delete ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }