<!--

  File: ContractForm.vue

  Defines a component that produces a form to edit contract data.

  Props:
    contractId: Used to query GraphQL for data.

  Events:
    modified: Emitted if contract data has been modified.

-->
<template>
  <div class="card">
    <header class="card-header">
      <h1 class="card-header-title">Contract Form</h1>
      <HelpDoc class="card-header-icon" id="contract-form" />
    </header>
    <div class="card-content">
      <div class="columns">
        <div class="column">
          <b-field label="Code">
            <b-input v-model="project.Name" placeholder="Name" required>
            </b-input>
          </b-field>
          <b-field label="Description">
            <b-input
              v-model="project.Description"
              placeholder="Description"
              required
            >
            </b-input>
          </b-field>
          <b-field label="Business Unit" v-if="autocompleteChoices">
            <b-autocomplete
              v-model="input"
              placeholder="Enter a business unit"
              keep-first
              open-on-focus
              :data="filteredChoices"
              field="node.Name"
              @select="
                (option) =>
                  (project.BusinessUnitPkey = option
                    ? parseInt(option.node.PKEY)
                    : undefined)
              "
              clearable
            />
          </b-field>
          <b-field label="Period of Performance">
            <template>
              <b-field>
                <b-datepicker
                  placeholder="Click to select..."
                  v-model="POP"
                  range
                  :date-formatter="
                    (d) => {
                      return (
                        d[0].toISOString().substring(0, 10) +
                        ' to ' +
                        d[1].toISOString().substring(0, 10)
                      );
                    }
                  "
                  editable
                  :date-parser="
                    (s) => {
                      let d = s.replace(' to ', '-').split('-');
                      return [
                        new Date(d[0], d[1] - 1, d[2]),
                        new Date(d[3], d[4] - 1, d[5]),
                      ];
                    }
                  "
                  position="is-bottom-left"
                >
                </b-datepicker>
              </b-field>
            </template>
          </b-field>
          <b-field label="Status">
            <b-checkbox v-model="project.Closed"> Closed </b-checkbox>
          </b-field>
        </div>
        <div class="column">
          <customer-list
            :selectedCustomer="project.CustomerPkey"
            @input="project.CustomerPkey = $event"
          />
          <b-field label="Government Contract Id">
            <b-input
              v-model="project.GovtContractId"
              placeholder="GovtContractId"
              required
            >
            </b-input>
          </b-field>
          <b-field label="Prime Contract Id">
            <b-input
              v-model="project.PrimeContractId"
              placeholder="PrimeContractId"
              required
            >
            </b-input>
          </b-field>
          <b-field label="Customer POC">
            <b-input
              v-model="project.CustomerPOC"
              placeholder="CustomerPOC"
              required
            >
            </b-input>
          </b-field>
          <b-field label="KO Contract Specialist">
            <b-input
              v-model="project.KOContractSpec"
              placeholder="KOContractSpec"
              required
            >
            </b-input>
          </b-field>
        </div>
        <b-loading v-model="$apollo.loading" />
      </div>
    </div>
    <footer class="card-footer">
      <button
        class="button is-primary"
        type="button"
        :disabled="saveDisabled"
        @click="updateContract()"
        v-if="contractId"
      >
        Save
      </button>
      <button
        class="button is-primary"
        type="button"
        :disabled="saveDisabled"
        @click="createContract()"
        v-if="!contractId"
      >
        New
      </button>
      <button
        class="button is-danger"
        type="button"
        @click="confirmDeleteContract()"
        v-if="contractId"
      >
        Delete
      </button>
    </footer>
  </div>
</template>

<script>
import gql from "graphql-tag";
import CustomerList from "./CustomerList";
import HelpDoc from "@/components/HelpDoc";

export default {
  components: {
    CustomerList,
    HelpDoc,
  },
  props: {
    contractId: { type: String },
  },
  apollo: {
    autocompleteChoices: {
      query: gql`
        {
          autocompleteChoices: allBusinessUnits(sort: NAME_ASC) {
            edges {
              node {
                PKEY
                Name
              }
            }
          }
        }
      `,
    },
    originalProject: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          originalProject: project(id: $id) {
            id
            PKEY
            BusinessUnitPkey
            CustomerPkey
            Name
            Description
            GovtContractId
            PrimeContractId
            CustomerPOC
            KOContractSpec
            ContractStartPOP
            ContractEndPOP
            Closed
            businessUnit {
              id
              PKEY
              Name
            }
          }
        }
      `,
      variables() {
        return {
          id: this.contractId,
        };
      },
      skip() {
        return !this.contractId;
      },
      result() {
        this.project = { ...this.originalProject };
        this.input = this.originalProject.businessUnit.Name;
        delete this.project.__typename; // remove GraphQL __typename artifact
        delete this.project.businessUnit;
        delete this.project.id;
      },
    },
  },
  data() {
    return {
      selectedCustomer: "Null",
      project: {},
      originalProject: {},
      input: "",
    };
  },
  computed: {
    filteredChoices() {
      return this.autocompleteChoices.edges.filter((option) => {
        return (
          option.node.Name.toString()
            .toLowerCase()
            .indexOf(this.input.toLowerCase()) >= 0
        );
      });
    },
    saveDisabled() {
      if (this.contractId) {
        return (
          this.originalProject.BusinessUnitPkey ===
            this.project.BusinessUnitPkey &&
          this.originalProject.CustomerPkey == this.project.CustomerPkey &&
          this.originalProject.Name === this.project.Name &&
          this.originalProject.Description === this.project.Description &&
          this.originalProject.GovtContractId === this.project.GovtContractId &&
          this.originalProject.PrimeContractId ===
            this.project.PrimeContractId &&
          this.originalProject.CustomerPOC === this.project.CustomerPOC &&
          this.originalProject.KOContractSpec === this.project.KOContractSpec &&
          this.originalProject.ContractStartPOP ===
            this.project.ContractStartPOP &&
          this.originalProject.ContractEndPOP === this.project.ContractEndPOP &&
          this.originalProject.Closed === this.project.Closed
        );
      }
      return (
        !this.project.BusinessUnitPkey ||
        !this.project.CustomerPkey ||
        !this.project.Name ||
        !this.project.Description ||
        !this.project.GovtContractId ||
        !this.project.PrimeContractId ||
        !this.project.CustomerPOC ||
        !this.project.KOContractSpec ||
        !this.project.ContractStartPOP ||
        !this.project.ContractEndPOP
      );
    },
    POP: {
      get() {
        if (this.project.ContractStartPOP && this.project.ContractEndPOP) {
          return [
            new Date(this.project.ContractStartPOP + " 00:00:00"),
            new Date(this.project.ContractEndPOP + " 00:00:00"),
          ];
        } else {
          return [new Date(), new Date()];
        }
      },
      set(newValue) {
        this.project.ContractStartPOP = newValue[0]
          .toISOString()
          .substring(0, 10);
        this.project.ContractEndPOP = newValue[1]
          .toISOString()
          .substring(0, 10);
      },
    },
  },
  watch: {
    contractId(newId) {
      if (!newId) {
        this.input = "";
        this.project.PKEY = undefined;
        this.project.BusinessUnitPkey = undefined;
        this.project.CustomerPkey = undefined;
        this.project.Name = undefined;
        this.project.Description = undefined;
        this.project.GovtContractId = undefined;
        this.project.PrimeContractId = undefined;
        this.project.CustomerPOC = undefined;
        this.project.KOContractSpec = undefined;
        this.project.ContractStartPOP = undefined;
        this.project.ContractEndPOP = undefined;
        this.project.Closed = false;
      }
    },
  },
  methods: {
    createContract() {
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: ProjectInput!) {
              createProject(input: $input) {
                project {
                  id
                  Name
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: this.project,
          },
        })
        .then((data) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Contract " + data.data.createProject.project.Name + " created!",
            type: "is-success",
          });
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create contract " +
              this.project.Name +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    updateContract() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: ProjectInput!) {
              updateProject(input: $input) {
                project {
                  Name
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: this.project,
          },
        })
        .then((data) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Contract " + data.data.updateProject.project.name + " updated!",
            type: "is-success",
          });
          this.$apollo.queries.originalProject.refetch();
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          console.error(error);
          this.$buefy.snackbar.open({
            message:
              "Failed to update contract " +
              this.project.Name +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    confirmDeleteContract() {
      this.$buefy.dialog.confirm({
        title: "Deleting contract",
        message:
          "Are you sure you want to <b>delete</b> the contract " +
          this.project.Name +
          "? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteContract(),
      });
    },
    deleteContract() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($PKEY: Int!) {
              deleteProject(input: { PKEY: $PKEY }) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            PKEY: this.project.PKEY,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Contract " + this.project.Name + " deleted!",
            type: "is-success",
          });
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to delete contract " +
              this.project.Name +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
  },
};
</script>
