<!--

  File: ODCModal.vue

  Defines a component that allows administrators to add and modify 
  ODCs.

  Props:
    isActive: Activates the modal when true, false hides the modal.
    odcPkey: The PKEY of the ODC to modify or null PKEY to 
             create a new ODC.
  
  Events:
    close: Instructs the parent to close the modal. An object with the
           following properties is passed to the parent.
             modified - to indicate if data was modified
             PKEY - the ODC primary key or null if the ODC was deleted

-->
<template>
  <section>
    <b-modal
      :active="isActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">ODC</p>
          <button
            type="button"
            class="delete"
            @click="$emit('close', { modified: false })"
          />
        </header>
        <section class="modal-card-body">
          <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
          <b-field label="AccountingId">
            <b-input v-model="ODC.AccountingId" placeholder="Accounting Id">
            </b-input>
          </b-field>
          <b-field label="Name">
            <b-input v-model="ODC.Id" placeholder="Name"> </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-primary"
            type="button"
            :disabled="saveDisabled"
            @click="saveODC()"
          >
            {{ buttonLabel }}
          </button>
          <button
            class="button is-danger"
            type="button"
            @click="confirmDelete()"
            v-if="this.odcPkey"
          >
            Delete
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import gql from "graphql-tag";

export default {
  name: "ODCModal",
  props: ["isActive", "odcPkey"],
  data() {
    return {
      isLoading: false,
      modified: false,
      buttonLabel: "",
      ODC: { PKEY: undefined, Id: "", AccountingId: "" },
    };
  },
  apollo: {
    origODC: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($ODCPkey: ID!) {
          origODC: allODCs(filters: { PKEY: $ODCPkey }) {
            edges {
              node {
                PKEY
                Id
                AccountingId
              }
            }
          }
        }
      `,
      variables() {
        return { ODCPkey: parseInt(this.odcPkey) };
      },
      skip() {
        return !this.odcPkey;
      },
      result() {
        this.ODC = { ...this.origODC.edges[0].node };
      },
    },
  },
  watch: {
    isActive(newActive) {
      if (!newActive) {
        return;
      }
      this.$apollo.queries.origODC.refetch();
      if (this.odcPkey) {
        this.buttonLabel = "Save";
        this.modified = false;
      } else {
        this.buttonLabel = "New";
        this.ODC = { PKEY: undefined, Id: "", AccountingId: "" };
        this.modified = false;
      }
    },
  },
  computed: {
    saveDisabled() {
      if (!this.odcPkey) {
        return this.ODC.Id.length == 0 || this.ODC.AccountingId.length == 0;
      }
      if (this.origODC) {
        return (
          this.ODC.Id.length == 0 ||
          this.ODC.AccountingId.length == 0 ||
          (this.ODC.Id == this.origODC.edges[0].node.Id &&
            this.ODC.AccountingId == this.origODC.edges[0].node.AccountingId)
        );
      }
      return true;
    },
  },
  methods: {
    saveODC() {
      if (this.odcPkey) {
        this.updateODC();
      } else {
        this.createODC();
      }
    },
    updateODC() {
      this.isLoading = true;
      let ci = {
        PKEY: this.odcPkey,
        Id: this.ODC.Id,
        AccountingId: this.ODC.AccountingId,
      };
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: ODCUpdateInput!) {
              updateODC(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: ci,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "ODC " + ci.Id + " updated!",
            type: "is-success",
          });
          this.$emit("close", { modified: true, PKEY: ci.PKEY });
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to update ODC " +
              this.origODC.edges[0].node.Id +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
    createODC() {
      this.isLoading = true;
      let ci = { Id: this.ODC.Id, AccountingId: this.ODC.AccountingId };
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: ODCCreateInput!) {
              createODC(input: $input) {
                ODC {
                  PKEY
                  Id
                  AccountingId
                }
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: ci,
          },
        })
        .then((result) => {
          // Result
          this.$buefy.toast.open({
            message: "ODC " + ci.Id + " created!",
            type: "is-success",
          });
          this.$emit("close", {
            modified: true,
            PKEY: result.data.createODC.ODC.PKEY,
          });
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create ODC " +
              ci.Id +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: "Deleting ODC",
        message:
          "Are you sure you want to <b>delete</b> the ODC: " +
          this.ODC.Id +
          "? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteODC(),
      });
    },
    deleteODC() {
      this.isLoading = true;
      let ci = { PKEY: this.odcPkey };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: ODCDeleteInput!) {
              deleteODC(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: ci,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "ODC: " + this.ODC.Id + " deleted!",
            type: "is-success",
          });
          this.$emit("close", { modified: true, PKEY: null });
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to delete ODC: " +
              this.ODC.Id +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
