<!--

  File: TaskInfo.vue

  Defines a component that shows task information to managers.

  Props:
    taskId: The id of the task being edited.

  Events:
    None

-->
<template>
  <div class="columns" style="max-width: 800px">
    <div class="column">
      <b-field label="Period of Performance">
        <b-input
          v-model="POP"
          placeholder="Enter period of performance"
          readonly
        />
      </b-field>
      <b-field label="Description">
        <b-input
          v-model="task.Description"
          placeholder="Description"
          readonly
        />
      </b-field>
      <b-field label="Customer">
        <b-input v-model="task.customer.Name" placeholder="Customer" readonly />
      </b-field>
    </div>
    <div class="column">
      <b-field label="Funding End">
        <b-input
          v-model="task.LimitEnd"
          placeholder="Enter last funding day"
          readonly
        />
      </b-field>
      <b-field label="Fee">
        <b-input v-model="task.Fee" placeholder="Fee" readonly />
      </b-field>
      <b-field label="Funding Source Type">
        <b-input
          v-model="task.fundingSourceType.Id"
          placeholder="Funding Source Type"
          readonly
        />
      </b-field>
    </div>
    <b-loading v-model="$apollo.loading" />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  components: {},
  props: {
    taskId: { type: String },
  },
  apollo: {
    // Vue-Apollo options here
    task: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          task: task(id: $id) {
            id
            PKEY
            FundingSourceTypePkey
            Id
            Description
            AccountingId
            LimitEnd
            Fee
            StartPOP
            EndPOP
            Closed
            fundingSourceType {
              PKEY
              Id
              Abbrev
              Enum
              id
            }
            customer {
              PKEY
              Name
              Description
              id
            }
          }
        }
      `,
      variables() {
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
      result() {},
    },
  },
  data() {
    return {
      task: {
        customer: {},
        fundingSourceType: {},
      },
    };
  },
  computed: {
    POP() {
      if (this.task.StartPOP && this.task.EndPOP) {
        return this.task.StartPOP + " to " + this.task.EndPOP;
      }
      return undefined;
    },
  },
  watch: {},
  methods: {},
};
</script>
