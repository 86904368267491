<!--

  File: EmployeeModal.vue

  Defines a component to display a tabbed modal overlay for modifying
  employee information. Each tab contains specific data, for example:
  account, salary and portfolio.

  Props:
    isActive: If true, causes the modal to display, false hides the modal.
    employee: The object to modify.

  Events:
    close: Emitted when one of the buttons to close the modal is pressed.
           It is up to the parent component to set isActive to false to
           hide the modal.
    change: Emitted if the employee information has been modified. The parent
            should set isActive to false to hide the modal.
    
-->
<template>
  <section>
    <b-modal
      :active="isActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 1060px">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="employee">Update Employee Form</p>
          <p class="modal-card-title" v-else>New Employee Form</p>
          <HelpDoc id="employee-modal" />
          <button type="button" class="delete" @click="closeForm()" />
        </header>
        <section class="modal-card-body">
          <b-tabs v-model="activeTab">
            <b-tab-item label="Account" icon="account">
              <EmployeeForm
                :id="employee_id"
                ref="employeeForm"
                @role_change="role = $event"
                @modified="modifiedEmployee = true"
              />
            </b-tab-item>
            <b-tab-item
              label="Salary"
              icon="currency-usd"
              :disabled="!employee_id"
            >
              <SalaryForm :id="employee_id" />
            </b-tab-item>
            <b-tab-item
              label="Portfolio"
              icon="briefcase"
              :disabled="!employee_id || role === 'USER'"
            >
              <PortfolioForm
                :id="employee_id"
                ref="portfolioForm"
                @modified="modifiedPortfolio = true"
              />
            </b-tab-item>
          </b-tabs>
        </section>
        <footer class="modal-card-foot">
          <div class="buttons" v-if="activeTab != 1">
            <button
              class="button is-primary"
              type="button"
              :disabled="!modifiedEmployee && !modifiedPortfolio"
              @click="saveEmployee()"
              v-if="employee"
            >
              Save
            </button>
            <button
              class="button is-primary"
              type="button"
              :disabled="!modifiedEmployee"
              @click="newEmployee()"
              v-if="!employee"
            >
              New
            </button>
            <button
              class="button is-danger"
              type="button"
              @click="confirmDeleteEmployee()"
              v-if="employee && activeTab == 0"
            >
              Delete
            </button>
          </div>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import HelpDoc from "./HelpDoc";
import EmployeeForm from "./EmployeeForm";
import PortfolioForm from "./PortfolioForm";
import SalaryForm from "./SalaryForm";

export default {
  components: {
    HelpDoc,
    EmployeeForm,
    PortfolioForm,
    SalaryForm,
  },
  props: {
    isActive: {
      type: Boolean,
    },
    employee: {
      type: Object,
    },
  },
  watch: {
    employee(newEmployee) {
      if (newEmployee) {
        this.role = newEmployee.Role;
        this.employee_id = newEmployee.id;
      } else {
        this.role = "USER";
        this.employee_id = null;
      }
    },
    isActive(newActive) {
      if (newActive) {
        this.modifiedEmployee = false;
        this.modifiedPortfolio = false;
        this.activeTab = 0;
      }
    },
  },
  data() {
    return {
      modifiedEmployee: false,
      modifiedPortfolio: false,
      role: "USER",
      employee_id: null,
      activeTab: 0,
    };
  },
  methods: {
    closeForm() {
      this.modifiedEmployee = undefined;
      this.activeTab = 0;
      this.$emit("close");
    },
    newEmployee() {
      this.$refs.employeeForm.createEmployee();
      this.$emit("change");
    },
    saveEmployee() {
      if (this.modifiedEmployee) {
        this.$refs.employeeForm.updateEmployee();
      }
      if (this.modifiedPortfolio) {
        this.$refs.portfolioForm.updatePortfolio();
      }
      this.$emit("change");
    },
    confirmDeleteEmployee() {
      this.$buefy.dialog.confirm({
        title: "Deleting employee",
        message:
          "Are you sure you want to <b>delete</b> the employee " +
          this.employee.Name +
          "? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.$refs.employeeForm.deleteEmployee(this.employee);
          this.$emit("change");
        },
      });
    },
  },
};
</script>
