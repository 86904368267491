<!--

  File: EmployeeUtilization.vue

  Defines a component that displays in tables the number of hours
  assigned for all assigned tasks by pay period for employees. A
  table is displayed for each employee PKEY.

  Props:
    employees: An array of employee PKEYs to display.

  Events:
    None.

-->
<template>
  <section class="py-0">
    <div class="hero is-small mb-2" v-if="title">
      <div class="hero-body">
        <p class="title">Employee Utilization</p>
      </div>
    </div>
    <div class="mb-2" v-for="(u, i) in utilization" :key="i">
      <div class="panel is-primary">
        <p class="panel-heading">{{ u.name }}</p>
        <b-table
          :data="u.assignedHours"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          :loading="$apollo.loading"
          focusable
          sticky-header
          height="100%"
        >
          <b-table-column
            v-for="(c, i) in columns"
            v-slot="props"
            :key="i"
            :label="c.label"
            :field="c.field"
            :sortable="c.sortable"
            :numeric="c.numeric"
            :sticky="c.sticky"
            :width="c.width ? c.width : null"
          >
            <span style="white-space: nowrap">{{ props.row[c.field] }}</span>
          </b-table-column>
          <template #footer>
            <th v-for="(c, i) in columns" :key="i">
              <div :class="getCellClass(u.totals[c.field], i)">
                {{ u.totals[c.field] }}
              </div>
            </th>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: ["employees", "title"],
  apollo: {
    utilization: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($employees: [ID!]) {
          utilization: allEmployees(filters: { PKEYIn: $employees }) {
            edges {
              node {
                id
                Name
                assignedHours {
                  edges {
                    node {
                      HoursAdjusted
                      payPeriod {
                        PKEY
                        EndDate
                      }
                      laborCategory {
                        AccountingId
                        OHRateId
                        task {
                          Id
                          AccountingId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          employees: this.employees,
        };
      },
      skip() {
        return !this.employees;
      },
      update(data) {
        // pivot data so that there is a column for each pay period
        var employees = {};
        data.utilization.edges.forEach((emp) => {
          var pivot = {};
          var totals = null;
          emp.node.assignedHours.edges.forEach((e) => {
            var tname = e.node.laborCategory.task.AccountingId;
            var id = e.node.laborCategory.task.Id;
            var lc =
              e.node.laborCategory.AccountingId ||
              e.node.laborCategory.OHRateId;
            var pp = e.node.payPeriod.EndDate;
            var hours = e.node.HoursAdjusted;
            var ppkey = e.node.payPeriod.PKEY;
            var k = tname + lc;
            pivot[k] = pivot[k] || { name: tname, id: id, lc: lc };
            pivot[k]["maxPP"] = pivot[k]["maxPP"] || -Infinity;
            pivot[k]["minPP"] = pivot[k]["minPP"] || Infinity;
            pivot[k][pp] = hours;
            pivot[k]["maxPP"] = Math.max(pivot[k]["maxPP"], ppkey);
            pivot[k]["minPP"] = Math.min(pivot[k]["minPP"], ppkey);
            totals = totals || { name: " ", id: " ", lc: "Total" };
            totals["maxPP"] = totals["maxPP"] || -Infinity;
            totals["minPP"] = totals["minPP"] || Infinity;
            totals[pp] = totals[pp] || 0;
            totals[pp] += hours;
            totals["maxPP"] = Math.max(totals["maxPP"], ppkey);
            totals["minPP"] = Math.min(totals["minPP"], ppkey);
          });
          employees[emp.node.Name] = {
            name: emp.node.Name,
            assignedHours: Object.values(pivot).sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }),
            totals: totals,
          };
        });
        return Object.values(employees).sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      },
      result() {
        this.fillInMissingHours();
      },
    },
    columns: {
      query: gql`
        query ($ppStart: ID!, $ppEnd: ID!) {
          columns: allPayPeriods(
            filters: { PKEYGte: $ppStart, PKEYLte: $ppEnd }
          ) {
            edges {
              node {
                EndDate
                NumWorkdays
              }
            }
          }
        }
      `,
      variables() {
        var ppStart = Math.min(
          ...this.utilization.map((x) =>
            Math.min(...x.assignedHours.map((y) => y.minPP)),
          ),
        );
        var ppEnd = Math.max(
          ...this.utilization.map((x) =>
            Math.max(...x.assignedHours.map((y) => y.maxPP)),
          ),
        );
        return {
          ppStart: ppStart,
          ppEnd: ppEnd,
        };
      },
      skip() {
        var skipit = true;
        this.columns = [];
        this.utilization.forEach((x) => {
          if (x.assignedHours.length > 0) skipit = false;
        });
        return skipit;
      },
      update(data) {
        // convert to columns
        var cols = data.columns.edges.map((e) => {
          return {
            field: e.node.EndDate,
            label: e.node.EndDate,
            sortable: false,
            numWorkdays: e.node.NumWorkdays,
            numeric: true,
            sticky: false,
          };
        });
        cols.unshift({
          field: "lc",
          label: "Labor Cat",
          sortable: true,
          sticky: true,
          numeric: false,
        });
        cols.unshift({
          field: "name",
          label: "Charge Code",
          sortable: true,
          sticky: false,
          numeric: false,
        });
        cols.unshift({
          field: "id",
          label: "Task",
          sortable: true,
          sticky: false,
          numeric: false,
          width: "200px",
        });
        return cols;
      },
      result() {
        this.fillInMissingHours();
      },
    },
  },
  data() {
    return {
      utilization: [],
      columns: [],
    };
  },
  methods: {
    getCellClass(value, colindex) {
      var i = colindex;
      if (i < 3) return "th-wrap";
      var nwh = this.columns[i].numWorkdays * 8;
      if (Math.abs(nwh - value) < 1)
        return "th-wrap is-numeric hero is-success";
      if (Math.abs(nwh - value) < 8)
        return "th-wrap is-numeric hero is-warning";
      return "th-wrap is-numeric hero is-danger";
    },
    fillInMissingHours() {
      var textFields = ["id", "name", "lc"];
      if (this.utilization.length === 0) return;
      if (this.columns.length === 0) return;

      // overwrite undefined hours with zero
      this.utilization.forEach((u) => {
        if (u.assignedHours.length === 0) return;
        u.assignedHours.forEach((ah) => {
          this.columns.forEach((c) => {
            ah[c.field] = ah[c.field] || 0;
            if (textFields.includes(c.field)) return;
            ah[c.field] = Number(ah[c.field]).toFixed(2);
          });
        });
        var t = u.totals;
        this.columns.forEach((c) => {
          t[c.field] = t[c.field] || 0;
          if (textFields.includes(c.field)) return;
          t[c.field] = Number(t[c.field]).toFixed(2);
        });
      });
    },
  },
};
</script>

<style></style>
