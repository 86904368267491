<!--

  File: NavBar.vue

  Defines the navigation control for the application.

  Props:
    None

  Events:
    click: Reacts when child components, mainly buttons, emit this event.

-->
<template>
  <section>
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="a" :to="{ path: '/' }">
          <img src="@/assets/cts-logo.png" alt="Contract Tracking System" />
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="div">
          <b-button type="is-text" @click="setSubApp('HOME')" icon-left="home">
            Home
          </b-button>
        </b-navbar-item>
        <b-navbar-item
          v-if="who.role === 'ADMIN' || who.role === 'MANAGER'"
          tag="div"
        >
          <b-button
            type="is-text"
            @click="setSubApp('CONTRACT_TRACKING')"
            icon-left="trending-up"
          >
            Contract Tracking
          </b-button>
        </b-navbar-item>
        <b-navbar-dropdown v-if="who.role === 'ADMIN'">
          <template #label>
            <b-icon class="mr-2" size="is-small" icon="wrench"></b-icon>
            <span>Admin</span>
          </template>
          <b-navbar-item @click="setSubApp('ADMIN', 'EMPLOYEES')">
            <b-icon class="mr-2" icon="account-multiple"></b-icon>
            Employee
          </b-navbar-item>
          <b-navbar-item @click="setSubApp('ADMIN', 'COMPANY-RATES')">
            <b-icon class="mr-2" icon="office-building"></b-icon>
            Company Rates
          </b-navbar-item>
          <b-navbar-item @click="setSubApp('ADMIN', 'CONTRACTS')">
            <b-icon class="mr-2" icon="handshake"></b-icon>
            Contracts
          </b-navbar-item>
          <b-navbar-item @click="setSubApp('ADMIN', 'SUBCONTRACT-INVOICES')">
            <b-icon class="mr-2" icon="receipt"></b-icon>
            Subcontract Invoices
          </b-navbar-item>
          <b-navbar-item
            @click="setSubApp('ADMIN', 'UPLOAD-TIMESHEET-INFORMATION')"
            ><b-icon class="mr-2" icon="upload"></b-icon>Upload Timesheet
            Information</b-navbar-item
          >
          <b-navbar-item @click="setSubApp('ADMIN', 'UPLOAD-ODC-EXPENDITURES')"
            ><b-icon class="mr-2" icon="upload"></b-icon>Upload ODC
            Expenditures</b-navbar-item
          >
          <b-navbar-item @click="setSubApp('ADMIN', 'UPLOAD-NEW-SALARIES')"
            ><b-icon class="mr-2" icon="upload"></b-icon>Upload New
            Salaries</b-navbar-item
          >
          <b-navbar-item
            @click="setSubApp('ADMIN', 'DOWNLOAD-HOUR-ASSIGNMENTS')"
          >
            <b-icon class="mr-2" icon="download"></b-icon>
            Download Hour Assignments
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>

      <template #end>
        <b-navbar-dropdown v-if="who.loggedin">
          <template #label>
            <b-icon class="mr-2" size="is-small" icon="account"></b-icon>
            <span>{{ who.name }}</span>
          </template>
          <b-navbar-item @click="setSubApp('ABOUT')">
            <b-icon class="mr-2" icon="information"></b-icon>
            About
          </b-navbar-item>
          <b-navbar-item href="/auth/logout">
            <b-icon class="mr-2" icon="logout"></b-icon>
            Log out
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item v-else tag="div">
          <div class="buttons">
            <b-button tag="a" href="/auth/login" icon-left="login">
              Log in
            </b-button>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <NotLoggedIn v-if="who.loggedin === false" />
    <div v-else class="hero is-fullheight-with-navbar has-background">
      <k-stage :config="configKonva" class="hero-background">
        <k-layer>
          <k-line :config="configTopPoly" />
          <k-line :config="configLeftPoly" />
        </k-layer>
      </k-stage>
      <div class="container is-fluid mt-5 mx-0">
        <section v-if="subApp === 'HOME'">
          <Home :id="who.PKEY" :role="who.role" v-if="who.loggedin === true" />
        </section>
        <section v-if="subApp === 'CONTRACT_TRACKING'">
          <ContractTracking
            :id="who.PKEY"
            :role="who.role"
            v-if="who.loggedin === true"
          />
        </section>
        <section class="is-info content" v-if="subApp === 'ABOUT'">
          <div class="container">
            <h1>Contract Tracking Software (CTS)</h1>
            <p></p>
            <h2>
              Group W-developed web-based software to track contracts and assign
              hours to employees.
            </h2>
            <hr />
            <VueShowdown
              flavor="original"
              :markdown="require('@/assets/CHANGELOG.md').default"
            />
          </div>
        </section>
        <section class="container is-fluid mx-0" v-if="subApp === 'ADMIN'">
          <Admin :selection="option" />
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import Admin from "./Admin.vue";
import ContractTracking from "@/components/ContractTracking";
import Home from "./Home";
import NotLoggedIn from "./NotLoggedIn";

import styles from "@/App.vue?vue&type=style&index=0&lang=scss&module=1";

export default {
  components: {
    Admin,
    ContractTracking,
    Home,
    NotLoggedIn,
  },
  data() {
    let stageW = 2400;
    let stageH = 1080;
    return {
      configKonva: {
        width: stageW,
        height: stageH,
      },
      configTopPoly: {
        closed: true,
        points: [0, 0, 25, 25, stageW, 25, stageW, 0],
        fill: styles.gwPrimaryColor,
      },
      configLeftPoly: {
        closed: true,
        points: [0, 0, 25, 25, 10, stageH, 0, stageH],
        fill: styles.gwGrayColor,
      },
      who: {},
      subApp: "HOME",
      option: "",
    };
  },
  beforeMount() {
    this.getWho();
  },
  methods: {
    async getWho() {
      let res = await fetch("/auth/who");
      this.who = await res.json();
    },
    setSubApp(subApp, option) {
      this.subApp = subApp;
      this.option = option ? option : "";
    },
  },
};
</script>
<style>
.navbar {
  background-color: unset !important;
}

.hero.has-background {
  position: relative;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: left top;
}

.hero-background.is-transparent {
  opacity: 0.3;
}
</style>
