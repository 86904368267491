<!--

  File: NotLoggedIn.vue

  Displays the page the user sees before signing-in.

  Props:
    None

  Events:
    None

-->
<template>
  <div ref="stagep" class="fill-window">
    <k-stage :config="configKonva">
      <k-layer>
        <k-line :config="configTopPoly" />
        <k-line :config="configLeftPoly" />
        <k-line :config="configBottomPoly" />
        <k-image :config="configImage" />
      </k-layer>
    </k-stage>
    <div class="level custom-text-position">
      <div class="level-left">
        <b-image
          class="is-128x128"
          :src="require('@/assets/cts-logo.png')"
          alt="CTS Logo"
        />
      </div>
      <div class="level-right">
        <div class="container">
          <h1 class="title has-text-white">
            <strong>Contract Tracking Software</strong>
          </h1>
          <h2 class="subtitle has-text-white">
            <p>You are not currently signed in</p>
            <p>Press the "Log in" button to sign in</p>
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import styles from "@/App.vue?vue&type=style&index=0&lang=scss&module=1";

const imageSrc = require("@/assets/Small-Group-W-Logo.png");

export default {
  name: "NotLoggedIn",
  data() {
    return {
      image: null,
      configKonva: {},
      configImage: {},
      configTopPoly: {},
      configLeftPoly: {},
      configBottomPoly: {},
    };
  },
  created() {
    const image = new window.Image();
    image.src = imageSrc;
    image.onload = () => {
      // set image only when it is loaded
      this.configImage.image = image;
      this.image = image;
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize(/*event*/) {
      let stageP = this.$refs.stagep;
      let stageW = stageP.offsetWidth;
      let stageH = stageP.offsetHeight;
      this.configKonva = {
        width: stageW,
        height: stageH,
      };
      this.configImage = {
        image: this.image,
        x: stageW / 8,
        y: stageH / 8,
      };
      this.configTopPoly = {
        closed: true,
        points: [
          0,
          0,
          stageW / 8,
          stageH / 2.25,
          stageW,
          stageH / 3.5,
          stageW,
          0,
        ],
        fill: "white",
      };
      this.configLeftPoly = {
        closed: true,
        points: [
          0,
          0,
          stageW / 8,
          stageH / 2.25,
          stageW / 32,
          stageH,
          0,
          stageH,
        ],
        fill: styles.gwGrayColor,
      };
      this.configBottomPoly = {
        closed: true,
        points: [
          stageW / 8,
          stageH / 2.25,
          stageW,
          stageH / 3.5,
          stageW,
          stageH,
          stageW / 32,
          stageH,
        ],
        fill: styles.gwPrimaryColor,
      };
    },
  },
};
</script>
<style scoped>
.fill-window {
  position: absolute;
  width: 100%;
  height: 93%;
}
.custom-text-position {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, 0);
}
</style>
