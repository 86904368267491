<!--

  File: OdcExpenditure.vue

  Defines a component that displays a summary of the ODCs charged to a task.

  Props:
    None

  Events:
    None

-->
<template>
  <div class="tile">
    <div class="panel my-0">
      <b-table
        v-if="task"
        :data="task ? task.odcExpenditure : []"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="node.contractor.Name"
        :loading="$apollo.loading"
        sticky-header
        :height="tableHeight(task.odcExpenditure)"
      >
        <template #empty>
          <div class="hero is-danger">
            <div class="hero-body">
              <h1 class="subtitle">There are no ODC charges to this task.</h1>
            </div>
          </div>
        </template>
        <b-table-column field="odc.Id" label="ODC" sortable>
          <template v-slot="props">
            {{ props.row.odc.Id }}
          </template>
        </b-table-column>
        <b-table-column
          field="LimitAmount"
          label="Planned"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.row.LimitAmount)
          }}
        </b-table-column>
        <b-table-column
          field="TotalBilled"
          label="Total Billed"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.row.TotalBilled)
          }}
        </b-table-column>
        <b-table-column
          field="Remaining"
          label="Remaining"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.row.Remaining)
          }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>
<script>
import gql from "graphql-tag";

export default {
  props: ["taskId"],
  components: {},
  apollo: {
    task: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          task(id: $id) {
            PKEY
            odcExpenditure {
              odc {
                Id
              }
              LimitAmount
              TotalBilled
              Remaining
            }
          }
        }
      `,
      variables() {
        // Use vue reactive properties here
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    tableHeight(list) {
      var h =
        list.length === 0 ? 180 : list.length > 5 ? 250 : 50 + 40 * list.length;
      return h;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .table-wrapper {
  overflow-x: hidden;
}
</style>
