<!--

  File: EmployeeForm.vue

  Defines a component that produces a form to edit employee data. Example employee
  data include: Name, Id, Email, etc.

  Props:
    id: The id of the employee.

  Events:
    role_change: Emitted if the role of the employee changes.
    modified: Emitted when the employee data changes.

-->
<template>
  <div class="columns">
    <div class="block">
      <b-field label="Name">
        <b-input
          v-model="employee.Name"
          placeholder="Employee's name"
          @change.native="onChange()"
        >
        </b-input>
      </b-field>
      <b-field label="Employee Id">
        <b-input
          v-model="employee.EmpNo"
          placeholder="Employee's accounting id"
          @change.native="onChange()"
          required
        >
        </b-input>
      </b-field>
      <b-field label="Role">
        <b-select
          v-model="employee.Role"
          placeholder="Select a role"
          @change.native="
            onChange();
            $emit('role_change', employee.Role);
          "
        >
          <option value="USER">USER</option>
          <option value="MANAGER">MANAGER</option>
          <option value="ADMIN">ADMIN</option>
        </b-select>
      </b-field>
      <b-field label="Email">
        <b-input
          type="email"
          v-model="employee.Email"
          placeholder="Employee's email"
          @change.native="onChange()"
        >
        </b-input>
      </b-field>
      <div class="field">
        <b-checkbox v-model="employee.Active" @change.native="onChange()">
          Active
        </b-checkbox>
      </div>
      <b-loading v-model="$apollo.loading" />
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: ["id"],
  apollo: {
    // Vue-Apollo options here
    employee: {
      query: gql`
        query ($id: ID!) {
          employee(id: $id) {
            PKEY
            Name
            EmpNo
            Email
            Role
            Active
          }
        }
      `,
      fetchPolicy: "no-cache",
      variables() {
        // Use vue reactive properties here
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
    },
  },
  data() {
    return {
      employee: {
        Name: undefined,
        Role: "USER",
        Active: true,
        EmpNo: undefined,
        Email: undefined,
      },
    };
  },
  watch: {
    id(newId) {
      if (!newId) {
        this.employee.Name = undefined;
        this.employee.Role = "USER";
        this.employee.Active = true;
        this.employee.EmpNo = undefined;
        this.employee.Email = undefined;
      }
    },
  },
  methods: {
    onChange() {
      if (this.employee.Name && this.employee.EmpNo && this.employee.Email) {
        this.$emit("modified");
      }
    },
    createEmployee() {
      let newEmployee = this.employee;
      delete newEmployee.__typename;
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: EmployeeInput!) {
              createEmployee(input: $input) {
                employee {
                  Name
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: newEmployee,
          },
        })
        .then((data) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Employee " +
              data.data.createEmployee.employee.Name +
              " created!",
            type: "is-success",
          });
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create employee " +
              newEmployee.Name +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    deleteEmployee(employee) {
      /* Called by parent when user requests to delete an employee. */
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($PKEY: Int!) {
              deleteEmployee(input: { PKEY: $PKEY }) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            PKEY: employee.PKEY,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Employee " + employee.Name + " deleted!",
            type: "is-success",
          });
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to delete employee " +
              employee.Name +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
    updateEmployee() {
      // Save the user input in case of an error
      let newEmployee = { ...this.employee };
      // remove GraphQL __typename artifact
      delete newEmployee.__typename;
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: EmployeeInput!) {
              updateEmployee(input: $input) {
                employee {
                  Name
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: newEmployee,
          },
        })
        .then((data) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Employee " +
              data.data.updateEmployee.employee.Name +
              " updated!",
            type: "is-success",
          });
        })
        .catch((error) => {
          // Error
          console.error(error);
          this.$buefy.snackbar.open({
            message:
              "Failed to update employee " +
              newEmployee.Name +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
          // We restore the initial user input
          this.employee = newEmployee;
        });
    },
  },
};
</script>
