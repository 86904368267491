<!--

  File: UploadOdcSteps.vue

  Defines a component that walks the user through a series of steps to
  upload a file downloaded from PROCAS containing ODC expenditures.

  Props:
    None

  Events:
    None

-->
<template>
  <div class="container mt-4">
    <div class="level">
      <div class="level-item">
        <h1 class="title has-text-primary has-text-centered">
          Upload ODC Expenditures
        </h1>
      </div>
      <div class="level-right">
        <HelpDoc id="upload-odc" />
      </div>
    </div>
    <b-steps type="is-primary" v-model="activeStep">
      <b-step-item type="is-primary" step="1" label="Upload">
        <div class="container">
          <b-field>
            <b-upload
              v-model="procasFile"
              expanded
              @input="readProcasFile()"
              drag-drop
              :loading="loading"
              :disabled="loading"
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"></b-icon>
                  </p>
                  <p>Drop your files here or click to upload</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div class="tags">
            <span class="tag is-primary" v-if="procasFile">
              {{ procasFile.name }}
              <button
                class="delete is-small"
                type="button"
                @click="removeProcasFile()"
              ></button>
            </span>
          </div>
        </div>
      </b-step-item>

      <b-step-item type="is-primary" step="2" label="Review">
        <h1 class="subtitle has-text-primary has-text-centered">
          Review the uploaded information
        </h1>
        <div class="columns is-vcentered" v-if="procasData.length > 0">
          <div class="column">
            <div
              class="hero is-small is-danger"
              v-if="
                uploadStatus && uploadStatus.missingChargeNumbers.length > 0
              "
            >
              <div class="hero-body">
                <b-dropdown
                  position="is-bottom-left"
                  append-to-body
                  aria-role="menu"
                  trap-focus
                  expanded
                >
                  <template #trigger>
                    <a class="navbar-item" role="button">
                      <span
                        >Missing charge numbers [{{
                          uploadStatus.missingChargeNumbers.length
                        }}]</span
                      >
                      <b-icon icon="menu-down" />
                    </a>
                  </template>
                  <b-dropdown-item
                    aria-role="list-item"
                    :focusable="false"
                    custom
                    paddingless
                  >
                    <b-table
                      :data="uploadStatus.missingChargeNumbers"
                      :show-header="false"
                      sticky-header
                      :columns="[{ field: 'ChargeNumber' }]"
                    />
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="hero is-small is-success" v-else>
              <div class="hero-body">
                Upload does not contain missing charge numbers
              </div>
            </div>
          </div>
          <div class="column">
            <div
              class="hero is-small is-danger"
              v-if="uploadStatus && uploadStatus.missingODCs.length > 0"
            >
              <div class="hero-body">
                <b-dropdown
                  position="is-bottom-left"
                  append-to-body
                  aria-role="menu"
                  trap-focus
                  expanded
                >
                  <template #trigger>
                    <a class="navbar-item" role="button">
                      <span
                        >Missing ODCs [{{
                          uploadStatus.missingODCs.length
                        }}]</span
                      >
                      <b-icon icon="menu-down" />
                    </a>
                  </template>
                  <b-dropdown-item
                    aria-role="list-item"
                    :focusable="false"
                    custom
                    paddingless
                  >
                    <b-table
                      :data="uploadStatus.missingODCs"
                      :show-header="false"
                      sticky-header
                      :columns="[{ field: 'ODCAccountingId' }]"
                    />
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="hero is-small is-success" v-else>
              <div class="hero-body">Upload does not contain missing ODCs</div>
            </div>
          </div>
          <div class="column">
            <div
              class="hero is-small is-warning"
              v-if="uploadStatus && uploadStatus.numExistingRecords > 0"
            >
              <div class="hero-body">
                {{ uploadStatus.numExistingRecords }} existing ODC expenditure
                records will be deleted
              </div>
            </div>
            <div class="hero is-small is-success" v-else>
              <div class="hero-body">No existing records will be deleted</div>
            </div>
          </div>
        </div>
        <b-table
          :data="procasData"
          :columns="procasDataColumns"
          sticky-header
          height="500"
        >
          <template #empty>
            <div class="hero is-danger">
              <div class="hero-body">
                <h1 class="subtitle">
                  First, upload a PROCAS ODC expenditure file on the previous
                  step
                </h1>
              </div>
            </div>
          </template>
        </b-table>
      </b-step-item>

      <b-step-item type="is-primary" step="3" label="Confirm">
        <h1 class="subtitle has-text-primary has-text-centered">
          Confirm upload
        </h1>
        <div v-if="procasData.length === 0" class="hero is-danger">
          <div class="hero-body">
            <h1 class="subtitle">
              ODC expenditures were not uploaded, go back to step 1
            </h1>
          </div>
        </div>
        <div v-else>
          <b-message
            v-if="uploadStatus && !uploadStatus.ok"
            type="is-danger"
            title="Errors in upload"
            :closable="false"
          >
            There are errors in the uploaded ODC expenditures. Go to the
            previous step to view problems. Only valid records in the uploaded
            file will be saved if the confirm button is pressed.
          </b-message>
          <b-message
            v-if="uploadStatus && uploadStatus.numExistingRecords > 0"
            type="is-warning"
            title="Overwrite warning"
            :closable="false"
          >
            Existing data matching the pay period(s) being uploaded will be
            deleted.
          </b-message>
          <h1 class="subtitle has-text-primary has-text-centered">
            Press the confirm button to store ODC expenditures
          </h1>
          <div class="buttons is-centered">
            <b-button
              :type="
                uploadStatus && uploadStatus.ok
                  ? uploadStatus && uploadStatus.numExistingRecords > 0
                    ? 'is-warning'
                    : 'is-success'
                  : 'is-danger'
              "
              @click="commitData()"
              icon-left="upload"
            >
              Confirm upload
            </b-button>
          </div>
        </div>
      </b-step-item>
      <template #navigation="{ previous, next }">
        <div class="buttons is-centered">
          <b-button
            outlined
            type="is-primary"
            icon-left="arrow-left"
            :disabled="previous.disabled"
            @click.prevent="previous.action"
          >
            Previous
          </b-button>
          <b-button
            outlined
            type="is-primary"
            icon-right="arrow-right"
            :disabled="next.disabled"
            @click.prevent="next.action"
          >
            Next
          </b-button>
        </div>
      </template>
    </b-steps>
  </div>
</template>
<script>
import gql from "graphql-tag";
import HelpDoc from "@/components/HelpDoc";

const papa = require("papaparse");
export default {
  components: { HelpDoc },
  data() {
    return {
      activeStep: 0,
      loading: false,
      procasFile: null,
      procasData: [],
      uploadStatus: null,
      commitStatus: null,
      procasDataColumns: [
        { label: "Charge Number", column: 1 },
        // columns 2-3 are not used
        { label: "ODC Accounting Id", column: 4 },
        // columns 5-8 are not used
        { label: "Date Incurred", column: 9 },
        // columns 10-19 are not used
        { label: "Amount", numeric: true, column: 20 },
      ],
    };
  },
  mounted() {
    for (let c of this.procasDataColumns) {
      c.field = c.label.replace(/\s+/g, "");
    }
  },
  methods: {
    readProcasFile() {
      this.loading = true;
      let vm = this;
      vm.procasData = [];
      papa.parse(this.procasFile, {
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
          let rows = results.data;
          for (const r of rows) {
            let data = {};
            for (const c of vm.procasDataColumns) {
              data[c.field] = r[c.column - 1];
            }
            vm.procasData.push(data);
          }
          vm.uploadData();
        },
      });
    },
    uploadData() {
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($rows: [ODCUploadInput]) {
              createODCUpload(input: $rows) {
                ok
                uploadSize
                missingChargeNumbers {
                  ChargeNumber
                }
                missingODCs {
                  ODCAccountingId
                }
                numExistingRecords
              }
            }
          `,
          // Parameters
          variables: {
            rows: this.procasData,
          },
        })
        .then((data) => {
          // Result
          this.uploadStatus = data.data.createODCUpload;
          let nrows = data.data.createODCUpload.uploadSize;
          this.$buefy.toast.open({
            message: "Uploaded " + nrows + " ODC expenditure records!",
            type: "is-success",
            duration: 5000,
          });
          this.activeStep = 1;
          this.loading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Problem uploading data. Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
          this.loading = false;
        });
    },
    commitData() {
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation {
              commitODCUpload {
                ok
                numRecordsSaved
              }
            }
          `,
        })
        .then((data) => {
          // Result
          this.commitStatus = data.data.commitODCUpload;
          let nrows = data.data.commitODCUpload.numRecordsSaved;
          this.$buefy.toast.open({
            message: "Committed " + nrows + " ODC expenditure records!",
            type: "is-success",
            duration: 5000,
          });
          this.activeStep = 0;
          this.removeProcasFile();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Problem committing data. Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
    removeProcasFile() {
      this.procasData = [];
      this.procasFile = null;
      this.uploadStatus = null;
      this.commitStatus = null;
    },
  },
};
</script>
