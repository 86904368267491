<!--

  File: SubcontractInvoices.vue

  Defines a component that allows for quick entry of subcontract invoices.

  Props:
    None

  Events:
    refetch: Emitted by the Invoices component. Requests a refetch of the GraphQL
             data.

-->
<template>
  <div class="mt-5">
    <div class="tile is-ancestor">
      <div class="tile is-vertical">
        <div class="level mb-0">
          <div class="level-left"></div>
          <div class="level-item">
            <h1 class="title has-text-primary has-text-centered">
              Subcontract Invoices
            </h1>
          </div>
          <div class="level-right">
            <div class="level-item">
              <HelpDoc id="subcontract-invoices" />
            </div>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child is-3">
            <div class="box mr-3 panel is-primary">
              <p class="panel-heading">Add Invoice</p>
              <b-field
                class="mt-3"
                label="Task and Contractor"
                v-if="autocompleteChoices"
              >
                <b-autocomplete
                  ref="autocomplete"
                  rounded
                  v-model="input"
                  placeholder="Enter task accounting id and contractor name"
                  :data="filteredChoices"
                  :keep-first="true"
                  :open-on-focus="true"
                  field="node.choice"
                  @select="(option) => (selected = option)"
                  :clearable="true"
                >
                </b-autocomplete>
              </b-field>
              <b-field label="Date">
                <b-datepicker
                  v-model="invoice.DateIncurred"
                  placeholder="Enter date of invoice"
                  icon="calendar"
                  trap-focus
                  required
                  editable
                  :date-formatter="
                    (d) => {
                      return d.toISOString().substring(0, 10);
                    }
                  "
                  :date-parser="
                    (s) => {
                      return new Date(s);
                    }
                  "
                />
              </b-field>
              <b-field label="Hours">
                <b-input
                  type="number"
                  step=".01"
                  icon="clock"
                  v-model="invoice.Hours"
                  placeholder="Enter hours"
                  required
                >
                </b-input>
              </b-field>
              <b-field label="Amount">
                <b-input
                  type="number"
                  step=".01"
                  icon="currency-usd"
                  v-model="invoice.Amount"
                  placeholder="Enter amount"
                  required
                >
                </b-input>
              </b-field>
              <b-button
                type="is-primary mt-1"
                rounded
                outlined
                icon-left="pencil"
                :disabled="!selected"
                @click="addInvoice()"
                @keydown.prevent.tab="$refs.autocomplete.focus()"
              >
                Add invoice
              </b-button>
            </div>
          </div>
          <div class="tile is-child is-3">
            <div class="box mr-3 panel is-primary">
              <p class="panel-heading">Invoices</p>
              <Invoices
                ref="invoices"
                class="mt-1"
                :subcontract="subcontract"
                @refetch="$apollo.queries.subcontract.refetch()"
              />
            </div>
          </div>
          <div class="tile is-child">
            <div class="box panel is-primary">
              <p class="panel-heading">Subcontract Summary</p>
              <b-table
                :data="subcontract ? [subcontract] : undefined"
                default-sort-direction="desc"
                sort-icon="arrow-up"
                sort-icon-size="is-small"
                default-sort="node.task.AccountingId"
                :loading="$apollo.loading"
              >
                <template #empty>
                  <div class="hero is-danger">
                    <div class="hero-body">
                      <h1 class="subtitle">
                        Enter a task and contractor to see a summary.
                      </h1>
                    </div>
                  </div>
                </template>
                <b-table-column field="EndDate" label="End" sortable>
                  <template v-slot="props">
                    {{ props.row.EndDate }}
                  </template>
                </b-table-column>
                <b-table-column
                  field="LimitAmount"
                  label="Funded"
                  numeric
                  v-slot="props"
                >
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(props.row.LimitAmount)
                  }}
                </b-table-column>
                <b-table-column
                  field="ProfitAmount"
                  label="Fee"
                  numeric
                  v-slot="props"
                >
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(props.row.ProfitAmount)
                  }}
                </b-table-column>
                <b-table-column
                  field="FundedHours"
                  label="Funded Hours"
                  numeric
                  v-slot="props"
                >
                  {{
                    Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                    }).format(props.row.FundedHours)
                  }}
                </b-table-column>
                <b-table-column
                  field="invoiceSummary.TotalBilled"
                  label="Billed"
                  numeric
                  v-slot="props"
                >
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(props.row.invoiceSummary.TotalBilled)
                  }}
                </b-table-column>
                <b-table-column
                  field="invoiceSummary.TotalHours"
                  label="Billed Hours"
                  numeric
                  v-slot="props"
                >
                  {{
                    Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                    }).format(props.row.invoiceSummary.TotalHours)
                  }}
                </b-table-column>
                <b-table-column
                  field="invoiceSummary.Remaining"
                  label="Remaining"
                  numeric
                  v-slot="props"
                >
                  {{
                    Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(props.row.invoiceSummary.Remaining)
                  }}
                </b-table-column>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import gql from "graphql-tag";
import HelpDoc from "@/components/HelpDoc";
import Invoices from "@/components/Invoices";
import gql from "graphql-tag";

export default {
  components: { HelpDoc, Invoices },
  apollo: {
    autocompleteChoices: {
      query: gql`
        {
          autocompleteChoices: allSubcontracts {
            edges {
              node {
                id
                task {
                  AccountingId
                }
                contractor {
                  Name
                }
              }
            }
          }
        }
      `,
      update(data) {
        for (let n of data.autocompleteChoices.edges) {
          n.node.choice =
            n.node.task.AccountingId + " " + n.node.contractor.Name;
        }
        return data.autocompleteChoices;
      },
    },
    subcontract: {
      query: gql`
        query ($id: ID!) {
          subcontract(id: $id) {
            id
            PKEY
            StartDate
            EndDate
            LimitAmount
            FundedHours
            ProfitAmount
            task {
              AccountingId
            }
            contractor {
              Name
            }
            invoiceSummary {
              TotalHours
              TotalBilled
              Remaining
            }
            invoices {
              edges {
                node {
                  PKEY
                  SubcontractPkey
                  DateIncurred
                  Hours
                  Amount
                }
              }
            }
          }
        }
      `,
      variables() {
        // Use vue reactive properties here
        return {
          id: this.selected.node.id,
        };
      },
      skip() {
        this.subcontract = undefined;
        return !this.selected;
      },
    },
  },
  data() {
    return {
      selected: undefined,
      input: "",
      invoice: {
        SubcontractPkey: undefined,
        DateIncurred: new Date(),
        Hours: 0,
        Amount: 0,
      },
    };
  },
  computed: {
    filteredChoices() {
      return this.autocompleteChoices.edges.filter((option) => {
        return (
          option.node.choice
            .toString()
            .toLowerCase()
            .indexOf(this.input.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    addInvoice() {
      this.invoice.SubcontractPkey = this.subcontract.PKEY;
      this.$refs.invoices.createInvoice(this.invoice);
    },
  },
};
</script>
<style lang="scss" scoped></style>
