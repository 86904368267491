<!--

  File: UploadSlariesSteps.vue

  Defines a component that walks the user through a series of steps to
  upload a CVS file contining employee ids and new salaries.

  Props:
    None

  Events:
    None

-->
<template>
  <div class="container mt-4">
    <b-loading
      :is-full-page="false"
      v-model="loading"
      :can-cancel="false"
    ></b-loading>
    <div class="level">
      <div class="level-item">
        <h1 class="title has-text-primary has-text-centered">
          Upload New Salaries
        </h1>
      </div>
      <div class="level-right">
        <HelpDoc id="upload-salaries" />
      </div>
    </div>
    <b-steps type="is-primary" v-model="activeStep">
      <b-step-item type="is-primary" step="1" label="Upload">
        <div class="container">
          <b-field grouped>
            <b-field label="Start date" expanded>
              <b-datepicker
                v-model="startDate"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus
                :date-formatter="
                  (d) => {
                    return d.toISOString().substring(0, 10);
                  }
                "
                :date-parser="
                  (s) => {
                    return new Date(s);
                  }
                "
              >
              </b-datepicker>
            </b-field>
            <b-field label="End date" expanded>
              <b-datepicker
                v-model="endDate"
                placeholder="Click to select..."
                icon="calendar-today"
                trap-focus
                :date-formatter="
                  (d) => {
                    return d.toISOString().substring(0, 10);
                  }
                "
                :date-parser="
                  (s) => {
                    return new Date(s);
                  }
                "
              >
              </b-datepicker>
            </b-field>
          </b-field>
          <b-field>
            <b-upload
              v-model="salariesFile"
              expanded
              @input="readSalariesFile()"
              drag-drop
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="upload" size="is-large"></b-icon>
                  </p>
                  <p>Drop your files here or click to upload</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div class="tags">
            <span class="tag is-primary" v-if="salariesFile">
              {{ salariesFile.name }}
              <button
                class="delete is-small"
                type="button"
                @click="removeSalariesFile()"
              ></button>
            </span>
          </div>
        </div>
      </b-step-item>

      <b-step-item type="is-primary" step="2" label="Review">
        <h1 class="subtitle has-text-primary has-text-centered">
          Review the uploaded information
        </h1>
        <div class="columns is-vcentered" v-if="salaryData.length > 0">
          <div class="column">
            <div
              class="hero is-small is-danger"
              v-if="uploadStatus && uploadStatus.missingEmployees.length > 0"
            >
              <div class="hero-body">
                <b-dropdown
                  position="is-bottom-left"
                  append-to-body
                  aria-role="menu"
                  trap-focus
                  expanded
                >
                  <template #trigger>
                    <a class="navbar-item" role="button">
                      <span
                        >Uploaded records not matched[{{
                          uploadStatus.missingEmployees.length
                        }}]</span
                      >
                      <b-icon icon="menu-down" />
                    </a>
                  </template>
                  <b-dropdown-item
                    aria-role="list-item"
                    :focusable="false"
                    custom
                    paddingless
                  >
                    <b-table
                      :data="uploadStatus.missingEmployees"
                      :show-header="false"
                      sticky-header
                      :columns="[{ field: 'EmpNo' }]"
                    />
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="hero is-small is-success" v-else>
              <div class="hero-body">
                All employess in upload were found in CTS
              </div>
            </div>
          </div>
          <div class="column">
            <div
              class="hero is-small is-danger"
              v-if="uploadStatus && uploadStatus.notUploaded.length > 0"
            >
              <div class="hero-body">
                <b-dropdown
                  position="is-bottom-left"
                  append-to-body
                  aria-role="menu"
                  trap-focus
                  expanded
                >
                  <template #trigger>
                    <a class="navbar-item" role="button">
                      <span
                        >Active employees not uploaded[{{
                          uploadStatus.notUploaded.length
                        }}]</span
                      >
                      <b-icon icon="menu-down" />
                    </a>
                  </template>
                  <b-dropdown-item
                    aria-role="list-item"
                    :focusable="false"
                    custom
                    paddingless
                  >
                    <b-table
                      :data="uploadStatus.notUploaded"
                      :show-header="false"
                      sticky-header
                      :columns="[{ field: 'EmpNo' }, { field: 'Name' }]"
                    />
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="hero is-small is-success" v-else>
              <div class="hero-body">All employess were uploaded</div>
            </div>
          </div>
          <div class="column">
            <div
              class="hero is-small is-warning"
              v-if="uploadStatus && uploadStatus.numExistingRecords > 0"
            >
              <div class="hero-body">
                {{ uploadStatus.numExistingRecords }} existing salary records
                will be deleted
              </div>
            </div>
            <div class="hero is-small is-success" v-else>
              <div class="hero-body">No existing records will be deleted</div>
            </div>
          </div>
        </div>
        <b-table
          :data="salaryData"
          :columns="salaryDataColumns"
          sticky-header
          height="500"
        >
          <template #empty>
            <div class="hero is-danger">
              <div class="hero-body">
                <h1 class="subtitle">
                  First, upload a CSV file with new salaries on the previous
                  step
                </h1>
              </div>
            </div>
          </template>
        </b-table>
      </b-step-item>

      <b-step-item type="is-primary" step="3" label="Confirm">
        <h1 class="subtitle has-text-primary has-text-centered">
          Confirm upload
        </h1>
        <div v-if="salaryData.length === 0" class="hero is-danger">
          <div class="hero-body">
            <h1 class="subtitle">
              New salaries were not uploaded, go back to step 1
            </h1>
          </div>
        </div>
        <div v-else>
          <b-message
            v-if="uploadStatus && !uploadStatus.ok"
            type="is-danger"
            title="Errors in upload"
            :closable="false"
          >
            There are errors in the uploaded salaries file. Go to the previous
            step to view problems. Only valid records in the uploaded file will
            be saved if the confirm button is pressed.
          </b-message>
          <b-message
            v-if="uploadStatus && uploadStatus.numExistingRecords > 0"
            type="is-warning"
            title="Overwrite warning"
            :closable="false"
          >
            Existing data matching the data being uploaded will be deleted.
          </b-message>
          <h1 class="subtitle has-text-primary has-text-centered">
            Press the confirm button to store new salaries
          </h1>
          <div class="buttons is-centered">
            <b-button
              :type="
                uploadStatus && uploadStatus.ok
                  ? uploadStatus && uploadStatus.numExistingRecords > 0
                    ? 'is-warning'
                    : 'is-success'
                  : 'is-danger'
              "
              @click="commitData()"
              icon-left="upload"
            >
              Confirm upload
            </b-button>
          </div>
        </div>
      </b-step-item>
      <template #navigation="{ previous, next }">
        <div class="buttons is-centered">
          <b-button
            outlined
            type="is-primary"
            icon-left="arrow-left"
            :disabled="previous.disabled"
            @click.prevent="previous.action"
          >
            Previous
          </b-button>
          <b-button
            outlined
            type="is-primary"
            icon-right="arrow-right"
            :disabled="next.disabled"
            @click.prevent="next.action"
          >
            Next
          </b-button>
        </div>
      </template>
    </b-steps>
  </div>
</template>
<script>
import gql from "graphql-tag";
import HelpDoc from "@/components/HelpDoc";

const papa = require("papaparse");
export default {
  components: { HelpDoc },
  data() {
    return {
      activeStep: 0,
      loading: false,
      salariesFile: null,
      salaryData: [],
      startDate: new Date(new Date().getFullYear() + "-07-01"),
      endDate: new Date(new Date().getFullYear() + 1 + "-06-30"),
      uploadStatus: null,
      commitStatus: null,
      salaryDataColumns: [
        { label: "Employee number", column: 1, field: "EmpNo" },
        { label: "New salary", column: 2, field: "YearlyRate" },
      ],
    };
  },
  mounted() {},
  methods: {
    readSalariesFile() {
      this.loading = true;
      let vm = this;
      vm.salaryData = [];
      papa.parse(this.salariesFile, {
        header: false,
        skipEmptyLines: true,
        complete: function (results) {
          let rows = results.data;
          for (const r of rows) {
            let data = {};
            if (r[0] === null || r[1] === null) continue;
            for (const c of vm.salaryDataColumns) {
              data[c.field] = Number(r[c.column - 1].replace(/[^0-9.-]+/g, ""));
            }
            if (data["EmpNo"] === 0) continue;
            vm.salaryData.push(data);
          }
          vm.uploadData();
        },
      });
    },
    uploadData() {
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation (
              $input: [SalaryUploadInput]
              $start: String
              $end: String
            ) {
              createSalaryUpload(input: $input, start: $start, end: $end) {
                ok
                uploadSize
                missingEmployees {
                  EmpNo
                }
                notUploaded {
                  EmpNo
                  Name
                }
                numExistingRecords
              }
            }
          `,
          // Parameters
          variables: {
            input: this.salaryData,
            start: this.startDate.toISOString().substring(0, 10),
            end: this.endDate.toISOString().substring(0, 10),
          },
        })
        .then((data) => {
          // Result
          this.uploadStatus = data.data.createSalaryUpload;
          let nrows = data.data.createSalaryUpload.uploadSize;
          this.$buefy.toast.open({
            message: "Uploaded " + nrows + " new salary records!",
            type: "is-success",
            duration: 5000,
          });
          this.activeStep = 1;
          this.loading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Problem uploading data. Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
          this.loading = false;
        });
    },
    commitData() {
      this.loading = true;
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation {
              commitSalaryUpload {
                ok
                numRecordsSaved
              }
            }
          `,
        })
        .then((data) => {
          // Result
          this.commitStatus = data.data.commitSalaryUpload;
          let nrows = data.data.commitSalaryUpload.numRecordsSaved;
          this.$buefy.toast.open({
            message: "Committed " + nrows + " salary records!",
            type: "is-success",
            duration: 5000,
          });
          this.loading = false;
          this.activeStep = 0;
          this.removeSalariesFile();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Problem committing data. Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
          this.loading = false;
        });
    },
    removeSalariesFile() {
      this.salaryData = [];
      this.salariesFile = null;
      this.uploadStatus = null;
      this.commitStatus = null;
    },
  },
};
</script>
