<!--

  File: TaskAssignments.vue

  Defines a component that displays the employees assigned to work on a task.

  Props:
    None

  Events:
    request-refresh: Tells the parent to refetch the data.

-->
<template>
  <div class="tile">
    <div class="panel my-0">
      <div class="buttons my-0">
        <b-button
          type="is-primary mt-1"
          rounded
          outlined
          icon-left="pencil"
          :disabled="checkedRows.length === 0"
          @click="openPlanModal()"
          >Add or Replace Plan</b-button
        >
        <b-button
          type="is-primary mt-1"
          rounded
          outlined
          icon-left="clock-out"
          :disabled="checkedRows.length === 0"
          @click="utilizationModalActive = true"
          >Employee Utilization</b-button
        >
      </div>
      <b-table
        v-if="task"
        :data="task.laborHourSummary"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="Name"
        :loading="$apollo.loading"
        sticky-header
        :checked-rows.sync="checkedRows"
        checkable
        :height="tableHeight(task.laborHourSummary)"
      >
        <template #empty>
          <div class="hero is-danger">
            <div class="hero-body">
              <h1 class="subtitle">
                There are no employees assigned to this task.
              </h1>
            </div>
          </div>
        </template>
        <b-table-column field="Name" label="Name" sortable v-slot="props">
          {{ props.row.Name }}
        </b-table-column>
        <b-table-column
          field="LaborCatId"
          label="Category"
          sortable
          v-slot="props"
        >
          {{ props.row.LaborCatId }}
        </b-table-column>
        <b-table-column
          field="hourAssignmentPlan.ModifiedOn"
          label="Modified On"
          sortable
          v-slot="props"
        >
          {{
            props.row.hourAssignmentPlan
              ? new Date(
                  props.row.hourAssignmentPlan.ModifiedOn + "Z",
                ).toLocaleString()
              : ""
          }}
        </b-table-column>
        <b-table-column
          field="hourAssignmentPlan.ModifiedBy"
          label="Modified By"
          sortable
          v-slot="props"
        >
          {{
            props.row.hourAssignmentPlan
              ? props.row.hourAssignmentPlan.ModifiedBy
              : ""
          }}
        </b-table-column>
        <b-table-column
          field="ExpendedHours"
          label="Expended Hours"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
            }).format(props.row.ExpendedHours)
          }}
        </b-table-column>
        <b-table-column
          field="RemainingHours"
          label="Remaining Hours"
          numeric
          v-slot="props"
        >
          {{
            Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
            }).format(props.row.RemainingHours)
          }}
        </b-table-column>
        <b-table-column
          field="RemainingPayPeriods"
          label="Remaining Pay Periods"
          numeric
          v-slot="props"
        >
          {{ props.row.RemainingPayPeriods }}
        </b-table-column>
      </b-table>
    </div>
    <HourAssignmentPlanModal
      :isActive="planModalActive"
      :assignments="checkedRows"
      :task="task ? task : {}"
      @close="onPlanFormClose($event)"
      @store-undo="$emit('store-undo', $event)"
    />
    <b-modal
      v-model="utilizationModalActive"
      :full-screen="true"
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <EmployeeUtilization :employees="checkedEmployees" :title="true" />
    </b-modal>
  </div>
</template>
<script>
import HourAssignmentPlanModal from "@/components/HourAssignmentPlanModal";
import EmployeeUtilization from "@/components/EmployeeUtilization";
import gql from "graphql-tag";
export default {
  props: ["taskId"],
  components: { HourAssignmentPlanModal, EmployeeUtilization },
  apollo: {
    task: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          task(id: $id) {
            id
            Id
            AccountingId
            fundingSourceType {
              Id
            }
            StartPOP
            EndPOP
            PKEY
            laborHourSummary {
              EmployeePkey
              LaborCategoryPkey
              Name
              LaborCatId
              StartDate
              EndDate
              ExpendedHours
              RemainingHours
              RemainingPayPeriods
              hourAssignmentPlan {
                EmployeePkey
                LaborCategoryPkey
                ModifiedOn
                ModifiedBy
                parts {
                  Units
                  Assignment
                  FTEBaseHours
                  OffPlanStrategy
                  startPayPeriod {
                    StartDate
                  }
                  endPayPeriod {
                    EndDate
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        // Use vue reactive properties here
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
  data() {
    return {
      task: undefined,
      planModalActive: false,
      utilizationModalActive: false,
      checkedRows: [],
      hourAssignmentPlan: {},
    };
  },
  mounted() {
    this.$emit("interface", {
      refresh: () => this.refresh(),
    });
  },
  computed: {
    checkedEmployees() {
      return this.checkedRows.map((cr) => cr.EmployeePkey);
    },
  },
  methods: {
    refresh() {
      Object.values(this.$apollo.queries).forEach((q) => q.refetch());
      this.checkedRows = [];
    },
    tableHeight(list) {
      var h =
        list.length === 0 ? 180 : list.length > 5 ? 250 : 65 + 40 * list.length;
      return h;
    },
    openPlanModal() {
      this.planModalActive = true;
    },
    onPlanFormClose(eventData) {
      if (eventData.modified) {
        this.$apollo.queries.task.refetch();
        this.$emit("request-refresh");
        this.checkedRows = [];
      }
      this.planModalActive = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
