<!--

  File: CompanyRatesModal.vue

  Defines a component that allows administrators to add and modify company
  rate information.

  Props:
    isActive: Activates the modal when true, false hides the modal.
    input: The company rates to modify.
    costCenterPkey: The cost center to associate with new records.

  Events:
    close: Instructs the parent to close the modal. An object with a
           property named modified is passed to the parent to indicate
           if data was modified.

-->
<template>
  <section>
    <b-modal
      :active="isActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 850px">
        <header class="modal-card-head">
          <p class="modal-card-title">Company Rates</p>
          <HelpDoc id="company-rates-modal" />
          <button
            type="button"
            class="delete"
            @click="$emit('close', { modified: false })"
          />
        </header>
        <section class="modal-card-body">
          <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
          <div class="columns">
            <div class="column">
              <b-field label="Date Range">
                <b-datepicker
                  v-model="dateRange"
                  placeholder="Click to select..."
                  icon="calendar-today"
                  @input="modified = true"
                  range
                  :date-formatter="
                    (d) => {
                      return (
                        d[0].toISOString().substring(0, 10) +
                        ' to ' +
                        d[1].toISOString().substring(0, 10)
                      );
                    }
                  "
                  editable
                  :date-parser="
                    (s) => {
                      let d = s.replace(' to ', '-').split('-');
                      return [
                        new Date(d[0], d[1] - 1, d[2]),
                        new Date(d[3], d[4] - 1, d[5]),
                      ];
                    }
                  "
                />
              </b-field>
              <b-field label="Materials & Handling">
                <b-input
                  type="number"
                  @input="modified = true"
                  v-model="companyRates.node.MH"
                  min="0"
                  max="1"
                  step="0.0001"
                />
              </b-field>
              <b-field label="Fringe">
                <b-input
                  type="number"
                  @input="modified = true"
                  v-model="companyRates.node.Fringe"
                  min="0"
                  max="1"
                  step="0.0001"
                />
              </b-field>
              <b-field label="General & Administrative">
                <b-input
                  type="number"
                  @input="modified = true"
                  v-model="companyRates.node.GA"
                  min="0"
                  max="1"
                  step="0.0001"
                />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Overhead">
                <b-table
                  :data="companyRates.node.ohRates.edges"
                  :show-header="false"
                >
                  <b-table-column field="node.Id" sortable numeric>
                    <template v-slot="props">
                      <b-input
                        v-model="props.row.node.Id"
                        placeholder="Name"
                        @change.native="modified = true"
                      >
                      </b-input>
                    </template>
                  </b-table-column>
                  <b-table-column field="node.Rate" numeric>
                    <template v-slot="props">
                      <b-input
                        type="number"
                        @input="modified = true"
                        v-model="props.row.node.Rate"
                        min="0"
                        max="1"
                        step="0.0001"
                      />
                    </template>
                  </b-table-column>
                  <b-table-column>
                    <template v-slot="props">
                      <b-icon
                        v-if="
                          props.index ===
                          companyRates.node.ohRates.edges.length - 1
                        "
                        type="is-dark mt-2"
                        size="is-normal"
                        icon="keyboard-return"
                        @click.native="addRow()"
                      />
                      <b-icon
                        v-else
                        type="is-dark mt-2"
                        size="is-normal"
                        icon="delete-forever"
                        @click.native="deleteRow(props.index)"
                      />
                    </template>
                  </b-table-column>
                </b-table>
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-primary"
            type="button"
            :disabled="!modified"
            @click="saveRates()"
          >
            {{ buttonLabel }}
          </button>
          <button
            class="button is-danger"
            type="button"
            @click="confirmDelete()"
            v-if="this.companyRates.node.PKEY"
          >
            Delete
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import HelpDoc from "@/components/HelpDoc";
import gql from "graphql-tag";

export default {
  props: {
    isActive: {
      type: Boolean,
    },
    input: {
      type: Object,
    },
    costCenterPkey: {
      type: String,
    },
  },
  components: { HelpDoc },
  data() {
    return {
      isLoading: false,
      modified: false,
      buttonLabel: "",
      companyRates: {
        node: {
          ohRates: {},
        },
      },
    };
  },
  watch: {
    isActive(newActive) {
      if (!newActive) {
        return;
      }
      // Use JSON to deep copy the company rates.
      this.companyRates = JSON.parse(JSON.stringify(this.input));
      if (this.companyRates) {
        this.buttonLabel = "Save";
        this.addRow();
        this.modified = false;
      } else {
        let currentTime = new Date();
        let currentYear = currentTime.getFullYear() + 2;
        this.companyRates = {
          node: {
            CostCenterPkey: this.costCenterPkey,
            StartDate: currentYear + "-01-01",
            EndDate: currentYear + "-12-31",
            ohRates: {
              edges: [
                {
                  node: {
                    Id: "Client",
                    Rate: undefined,
                  },
                },
                {
                  node: {
                    Id: "Company",
                    Rate: undefined,
                  },
                },
              ],
            },
          },
        };
        this.buttonLabel = "New";
        this.addRow();
        this.modified = true;
      }
    },
  },
  computed: {
    dateRange: {
      get() {
        return [
          new Date(this.companyRates.node.StartDate + " 00:00:00"),
          new Date(this.companyRates.node.EndDate + " 00:00:00"),
        ];
      },
      set(newValue) {
        this.companyRates.node.StartDate = newValue[0]
          .toISOString()
          .substring(0, 10);
        this.companyRates.node.EndDate = newValue[1]
          .toISOString()
          .substring(0, 10);
      },
    },
  },
  methods: {
    deleteRow(rowindex) {
      this.companyRates.node.ohRates.edges.splice(rowindex, 1);
      this.modified = true;
    },
    addRow() {
      this.companyRates.node.ohRates.edges.push({
        node: {
          IndirectRatesPkey: this.companyRates.node.PKEY,
          Id: undefined,
          Rate: undefined,
        },
      });
      this.modified = true;
    },
    saveRates() {
      if (this.companyRates.node.PKEY) {
        this.updateRates();
      } else {
        this.createRates();
      }
    },
    updateRates() {
      this.isLoading = true;
      let cr = { ...this.companyRates.node };
      cr.ohRates = this.companyRates.node.ohRates.edges.map((e) => e.node);

      // remove GraphQL __typename artifact
      delete cr.__typename;
      for (let o of cr.ohRates) {
        delete o.__typename;
      }

      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: IndirectRatesInput!) {
              updateIndirectRates(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: cr,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Company rates for period: " +
              this.companyRates.node.StartDate +
              " to " +
              this.companyRates.node.EndDate +
              " updated!",
            type: "is-success",
          });
          this.$emit("close", { modified: true });
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to update company rates for period: " +
              this.companyRates.node.StartDate +
              " to " +
              this.companyRates.node.EndDate +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
    createRates() {
      this.isLoading = true;
      let newCR = { ...this.companyRates.node };
      newCR.ohRates = this.companyRates.node.ohRates.edges.map((e) => e.node);
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: IndirectRatesInput!) {
              createIndirectRates(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: newCR,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Company rates for period: " +
              this.companyRates.node.StartDate +
              " to " +
              this.companyRates.node.EndDate +
              " created!",
            type: "is-success",
          });
          this.$emit("close", { modified: true });
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create company rates for period: " +
              this.companyRates.node.StartDate +
              " to " +
              this.companyRates.node.EndDate +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: "Deleting company rates",
        message:
          "Are you sure you want to <b>delete</b> the company rates for period: " +
          this.companyRates.node.StartDate +
          " to " +
          this.companyRates.node.EndDate +
          "? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteRates(),
      });
    },
    deleteRates() {
      this.isLoading = true;
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($PKEY: Int!) {
              deleteIndirectRates(input: { PKEY: $PKEY }) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            PKEY: this.companyRates.node.PKEY,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Company rates for period: " +
              this.companyRates.node.StartDate +
              " to " +
              this.companyRates.node.EndDate +
              " deleted!",
            type: "is-success",
          });
          this.$emit("close", { modified: true });
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to delete company rates for period: " +
              this.companyRates.node.StartDate +
              " to " +
              this.companyRates.node.EndDate +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .animation-content .modal-card {
  overflow: visible !important;
}

::v-deep .modal-card-body {
  overflow: visible !important;
}
</style>
