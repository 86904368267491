<!--

  File: PortfolioForm.vue

  Defines a component that produces controls to modify an employee's portfolio.
  Only managers can have a portfolio. Managers are responsible for the list of
  tasks that are in their portfolio. There are 2 tabs, one to display a table
  of all tasks and another to display a table with only the task in the portfolio.

  Props:
    id: The employee id of the manager.

  Events:
    modified: Emitted if the portfolio information changes.

-->
<template>
  <section>
    <b-tabs vertical type="is-toggle">
      <b-tab-item label="All Tasks">
        <b-table
          :data="allTasks"
          :columns="columns"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          :loading="$apollo.loading"
          default-sort="Name"
          focusable
          sticky-header
          :checked-rows.sync="checkedRows"
          checkable
          @check="$emit('modified')"
        >
        </b-table>
      </b-tab-item>
      <b-tab-item label="Administered Tasks">
        <b-table
          :data="checkedRows"
          :columns="columns"
          :default-sort-direction="defaultSortDirection"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          default-sort="Name"
          focusable
          sticky-header
          :checked-rows.sync="checkedRows"
          checkable
          @check="$emit('modified')"
        ></b-table>

        <b-message
          :closable="false"
          title="WARNING"
          type="is-warning mt-5"
          aria-close-label="Close message"
        >
          Un-checking an item on this page removes it. Items can only be
          re-checked in the "All Tasks" tab.
        </b-message>
      </b-tab-item>
    </b-tabs>
    <b>Total Administered</b>: {{ checkedRows.length }}
    <b-loading v-model="$apollo.loading" />
  </section>
</template>

<script>
import gql from "graphql-tag";

export default {
  props: ["id"],
  apollo: {
    // Vue-Apollo options here
    allTasks: {
      query: gql`
        query {
          allTasks(filters: { Closed: false }) {
            edges {
              node {
                PKEY
                Id
                AccountingId
              }
            }
          }
        }
      `,
      update(data) {
        // remove edges and nodes
        return data.allTasks.edges.map((e) => e.node);
      },
    },
    employee: {
      query: gql`
        query ($id: ID!) {
          employee(id: $id) {
            id
            PKEY
            Name
            portfolio {
              TaskPkey
            }
          }
        }
      `,
      update(data) {
        // remove edges and nodes
        data.employee.portfolio = data.employee.portfolio.map(
          (p) => p.TaskPkey
        );
        return data.employee;
      },
      fetchPolicy: "no-cache",
      variables() {
        // Use vue reactive properties here
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
    },
  },
  data() {
    return {
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",

      checkedRows: [],
      allTasks: [],

      columns: [
        {
          field: "AccountingId",
          label: "AccountingId",
          searchable: true,
          sortable: true,
        },
        {
          field: "Id",
          label: "Id",
          searchable: true,
          sortable: true,
        },
      ],
    };
  },
  watch: {
    employee() {
      this.checkedRows = this.allTasks.filter((t) =>
        this.employee.portfolio.includes(Number(t.PKEY))
      );
    },
  },
  methods: {
    updatePortfolio() {
      // We save the user input in case of an error
      let protoTasks = this.checkedRows.map((t) => t.PKEY);
      // Call to the graphql mutation
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($EmployeePkey: Int!, $TaskPKEYs: [Int!]!) {
              updatePortfolio(
                input: { EmployeePkey: $EmployeePkey, TaskPKEYs: $TaskPKEYs }
              ) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            EmployeePkey: this.employee.PKEY,
            TaskPKEYs: protoTasks,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Updated portfolio for employee " + this.employee.Name + "!",
            type: "is-success",
          });
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input<!-- this.portfolio = newEmployee; -->
        });
    },
  },
  activated() {
    if (!this.employee) {
      return;
    }
    this.checkedRows = this.allTasks.filter((t) =>
      this.employee.portfolio.includes(Number(t.PKEY))
    );
  },
};
</script>

<style scoped></style>
