<!--

  File: App.vue

  This file defines a single page application (SPA) in Vue. It uses the
  Buefy lightweight UI components for Vue.js. Buefy is based on the CSS
  framework Bulma. Buefy and Bulma are licensed under the MIT open source
  license.

  Props:
    None

  Events:
    None

-->
<template>
  <div id="app">
    <NavBar />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>

<style lang="scss">
/*
Export style variables to use in JavaScript
 */
:export {
  gwGrayColor: rgb(145, 147, 150);
  gwPrimaryColor: rgb(75, 99, 174);
}
</style>
