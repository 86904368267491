<!--

  File: OdcForm.vue

  Defines a component that produces a form to edit planned ODC expenditures.

  Props:
    task: The task being edited.

  Events:
    None

-->
<template>
  <section>
    <div class="buttons my-0">
      <b-button
        class="button py-0 mx-0 my-0 is-primary"
        type="is-text"
        size="is-small"
        icon-left="plus"
        @click="openNewForm()"
      />
      <b-button
        class="button py-0 mx-0 my-0 is-primary"
        type="is-text"
        size="is-small"
        icon-left="pencil"
        :disabled="!selected"
        @click="openEditForm()"
      />
    </div>
    <b-table
      :data="task && task.odcs ? task.odcs.edges : undefined"
      default-sort="node.odc.AccountingId"
      default-sort-direction="desc"
      :selected.sync="selected"
      @dblclick="openEditForm()"
    >
      <template #empty>
        <div class="hero is-danger">
          <div class="hero-body">
            <h1 class="subtitle">
              This task does not have any planned ODC expenditures.
            </h1>
          </div>
        </div>
      </template>
      <b-table-column label="ODC" v-slot="props">
        {{ props.row.node.odc.AccountingId }}
      </b-table-column>
      <b-table-column label="Id" v-slot="props">
        {{ props.row.node.odc.Id }}
      </b-table-column>
      <b-table-column label="Amount" numeric v-slot="props">
        {{ toCurrency(props.row.node.Amount) }}
      </b-table-column>
    </b-table>
    <b-modal
      :active="active"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto">
        <form action="">
          <header class="modal-card-head">
            <p class="modal-card-title mr-3">
              {{
                this.selected
                  ? "Update Planned ODC Expenditure"
                  : "New Planned ODC Expenditure"
              }}
            </p>
            <button type="button" class="delete" @click="closeSubForm()" />
          </header>
          <div class="modal-card-body">
            <b-field label="ODC" v-if="autocompleteChoices">
              <b-autocomplete
                ref="odcAutocomplete"
                v-model="input"
                placeholder="Enter an ODC"
                keep-first
                open-on-focus
                :data="filteredChoices"
                field="choice"
                @select="
                  (option) =>
                    (plannedODC.ODCPkey = option ? option.PKEY : undefined)
                "
                clearable
              >
                <template #header>
                  <div class="buttons my-0">
                    <b-button
                      class="button py-0 mx-0 my-0 is-primary"
                      type="is-text"
                      size="is-small"
                      icon-left="plus"
                      @click="openNewODC()"
                    />
                    <b-button
                      class="button py-0 mx-0 my-0 is-primary"
                      type="is-text"
                      size="is-small"
                      icon-left="pencil"
                      :disabled="!plannedODC.ODCPkey"
                      @click="openEditODC()"
                    />
                  </div>
                </template>
              </b-autocomplete>
            </b-field>
            <b-field label="Amount">
              <b-input
                type="number"
                step=".01"
                icon="currency-usd"
                v-model="plannedODC.Amount"
                placeholder="Enter amount"
                required
              >
              </b-input>
            </b-field>
          </div>
          <footer class="modal-card-foot">
            <button
              class="button is-primary"
              type="button"
              :disabled="saveDisabled"
              @click="savePlannedODC()"
            >
              {{ this.selected ? "Save" : "New" }}
            </button>
            <button
              class="button is-danger"
              type="button"
              @click="confirmDeletePlannedODC()"
              v-if="selected"
            >
              Delete
            </button>
          </footer>
        </form>
      </div>
    </b-modal>
    <ODCModal
      :isActive="activeODCModal"
      :odcPkey="plannedODC.ODCPkey"
      @close="closeODC($event)"
    />
  </section>
</template>

<script>
import gql from "graphql-tag";
import ODCModal from "./ODCModal";

export default {
  props: ["taskId"],
  components: { ODCModal },
  apollo: {
    autocompleteChoices: {
      query: gql`
        {
          autocompleteChoices: allODCs(sort: ACCOUNTING_ID_ASC) {
            edges {
              node {
                PKEY
                Id
                AccountingId
              }
            }
          }
        }
      `,
      update(data) {
        let choices = data.autocompleteChoices.edges.map((e) => {
          return {
            PKEY: e.node.PKEY,
            Id: e.node.Id,
            AccountingId: e.node.AccountingId,
            choice: e.node.AccountingId + " " + e.node.Id,
          };
        });

        return choices;
      },
      result() {
        if (!this.$refs.odcAutocomplete) {
          return;
        }
        if (this.plannedODC.ODCPkey) {
          let pkey = this.plannedODC.ODCPkey;
          let choice = this.autocompleteChoices.find((e) => e.PKEY == pkey);
          this.$refs.odcAutocomplete.setSelected(choice);
        } else {
          this.input = "";
          this.$refs.odcAutocomplete.setSelected(null);
        }
      },
    },
    task: {
      query: gql`
        query ($id: ID!) {
          task(id: $id) {
            id
            PKEY
            odcs {
              edges {
                node {
                  PKEY
                  TaskPkey
                  ODCPkey
                  Amount
                  odc {
                    Id
                    AccountingId
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        // Use vue reactive properties here
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
    },
  },
  data() {
    return {
      selected: undefined,
      active: false,
      input: "",
      plannedODC: {},
      activeODCModal: false,
    };
  },
  computed: {
    saveDisabled() {
      if (this.selected) {
        return (
          this.selected.node.ODCPkey === this.plannedODC.ODCPkey &&
          this.selected.node.Amount === this.plannedODC.Amount
        );
      } else {
        return !this.plannedODC.Amount || !this.plannedODC.ODCPkey;
      }
    },
    filteredChoices() {
      return this.autocompleteChoices.filter((option) => {
        return (
          option.choice
            .toString()
            .toLowerCase()
            .indexOf(this.input.toLowerCase()) >= 0
        );
      });
    },
  },
  watch: {},
  methods: {
    closeSubForm() {
      this.selected = undefined;
      this.active = false;
    },
    openEditForm() {
      this.plannedODC = { ...this.selected.node };
      this.input =
        this.plannedODC.odc.AccountingId + " " + this.plannedODC.odc.Id;
      // remove GraphQL __typename artifact
      delete this.plannedODC.__typename;
      delete this.plannedODC.odc;
      this.active = true;
    },
    openNewForm() {
      this.selected = undefined;
      this.plannedODC = {
        TaskPkey: this.task.PKEY,
        ODCPkey: undefined,
        Amount: undefined,
      };
      this.active = true;
    },
    toCurrency(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(value);
    },
    savePlannedODC() {
      if (this.selected) {
        this.updatePlannedODC();
      } else {
        this.createPlannedODC();
      }
    },
    createPlannedODC() {
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: ODCBudgetInput!) {
              createODCBudget(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.plannedODC,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Created planned ODC expenditure!",
            type: "is-success",
          });
          this.$apollo.queries.task.refetch();
          this.closeSubForm();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create planned ODC expenditure. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
    updatePlannedODC() {
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: ODCBudgetInput!) {
              updateODCBudget(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.plannedODC,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Updated planned ODC expenditure!",
            type: "is-success",
          });
          this.$apollo.queries.task.refetch();
          this.closeSubForm();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to update planned ODC expenditure. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
    confirmDeletePlannedODC() {
      this.$buefy.dialog.confirm({
        title: "Deleting planned ODC expenditure",
        message:
          "Are you sure you want to <b>delete</b> this data? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deletePlannedODC(),
      });
    },
    deletePlannedODC() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: ODCBudgetInput!) {
              deleteODCBudget(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.plannedODC,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Deleted planned ODC expenditure!",
            type: "is-success",
          });
          this.$apollo.queries.task.refetch();
          this.closeSubForm();
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to delete planned ODC expenditure. " +
              "Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
        });
    },
    openNewODC() {
      this.input = "";
      this.plannedODC.ODCPkey = null;
      this.activeODCModal = true;
    },
    openEditODC() {
      this.activeODCModal = true;
    },
    closeODC(eventData) {
      if (eventData.modified) {
        this.plannedODC.ODCPkey = eventData.PKEY;
        this.$apollo.queries.autocompleteChoices.refetch();
      }
      this.activeODCModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .animation-content .modal-card {
  overflow: visible !important;
}

::v-deep .modal-card-body {
  overflow: visible !important;
}
</style>
