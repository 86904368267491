<!--

  File: LaborCategoryAssignmentForm.vue

  Defines a component that produces a form to edit labor category assignment data.

  Props:
    laborCategoryAssignmentId: The id of the labor category assignment being edited.
    active: Displays the modal form.
    taskId: The task id owning the labor category asignment.


  Events:
    close: Notify parent to close the form.
    modified: Notify parent to close form and update data.

-->
<template>
  <b-modal
    :active="active"
    :can-cancel="false"
    has-modal-card
    trap-focus
    :destroy-on-hide="true"
    aria-role="dialog"
    aria-modal
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title mr-3">
          {{
            laborCategoryAssignmentId
              ? "Update Labor Category Assignment"
              : "New Labor Category Assignment"
          }}
        </p>
        <button type="button" class="delete" @click="closeForm()" />
      </header>
      <div class="modal-card-body" v-if="laborCategoryAssignment">
        <div class="columns">
          <div class="column">
            <b-field label="Employee">
              <b-autocomplete
                ref="autocomplete"
                rounded
                v-model="inputEmployee"
                placeholder="Enter employee name"
                :data="filteredEmployeeChoices"
                :keep-first="true"
                :open-on-focus="true"
                field="node.Name"
                @select="
                  (option) =>
                    (laborCategoryAssignment.EmployeePkey = option
                      ? option.node.PKEY
                      : undefined)
                "
                :clearable="true"
              >
              </b-autocomplete>
            </b-field>
            <b-field
              :label="
                task.fundingSourceType.Enum === 2 ? 'Labor Category' : 'OH Rate'
              "
            >
              <b-select
                placeholder="Select a labor category"
                v-model="laborCategoryAssignment.LaborCategoryPkey"
              >
                <option
                  v-for="lc in task.laborCategories"
                  :value="lc.PKEY"
                  :key="lc.PKEY"
                >
                  {{
                    task.fundingSourceType.Enum === 2 ? lc.AccountingId : lc.Id
                  }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Date Range">
              <b-datepicker
                placeholder="Click to select..."
                v-model="laborCategoryAssignment.dateRange"
                :min-date="task.StartDate"
                :max-date="task.EndDate"
                range
                :date-formatter="
                  (d) => {
                    return (
                      d[0].toISOString().substring(0, 10) +
                      ' to ' +
                      d[1].toISOString().substring(0, 10)
                    );
                  }
                "
                editable
                :date-parser="
                  (s) => {
                    let d = s.replace(' to ', '-').split('-');
                    return [
                      new Date(d[0], d[1] - 1, d[2]),
                      new Date(d[3], d[4] - 1, d[5]),
                    ];
                  }
                "
                position="is-bottom-left"
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>
      </div>
      <footer class="modal-card-foot">
        <button
          class="button is-primary"
          type="button"
          :disabled="saveDisabled"
          @click="saveLaborCategoryAssignment()"
        >
          {{ laborCategoryAssignmentId ? "Save" : "New" }}
        </button>
        <button
          class="button is-danger"
          type="button"
          @click="confirmDeleteLaborCategoryAssignment()"
          v-if="laborCategoryAssignmentId"
        >
          Delete
        </button>
      </footer>
    </div>
  </b-modal>
</template>
<script>
import gql from "graphql-tag";

export default {
  props: {
    active: { type: Boolean },
    laborCategoryAssignmentId: { type: String },
    taskId: { type: String },
  },
  apollo: {
    originalLaborCategoryAssignment: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          originalLaborCategoryAssignment: laborCategoryAssignment(id: $id) {
            id
            PKEY
            EmployeePkey
            LaborCategoryPkey
            StartingPayPeriod
            EndingPayPeriod
            laborCategory {
              id
              Id
              AccountingId
            }
            employee {
              id
              Name
            }
            startingPayPeriod {
              StartDate
            }
            endingPayPeriod {
              EndDate
            }
          }
        }
      `,
      variables() {
        return {
          id: this.laborCategoryAssignmentId,
        };
      },
      skip() {
        return !this.laborCategoryAssignmentId;
      },
      result() {
        this.copyOriginal();
      },
    },
    employeeChoices: {
      fetchPolicy: "no-cache",
      query: gql`
        {
          employeeChoices: allEmployees(
            sort: NAME_ASC
            filters: { Active: true }
          ) {
            edges {
              node {
                PKEY
                Name
              }
            }
          }
        }
      `,
    },
    task: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          task(id: $id) {
            id
            StartPOP
            EndPOP
            fundingSourceType {
              Enum
              id
            }
            laborCategories {
              id
              PKEY
              Id
              AccountingId
            }
            laborCategoryAssignments {
              PKEY
              LaborCategoryPkey
              EmployeePkey
            }
          }
        }
      `,
      variables() {
        // Use vue reactive properties here
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
      update(data) {
        let start = data.task.StartPOP;
        let end = data.task.EndPOP;
        data.task.StartDate = new Date(start + " 00:00:00");
        data.task.EndDate = new Date(end + " 00:00:00");
        return data.task;
      },
    },
    taskPOP: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($filter: PayPeriodFilter!) {
          taskPOP: allPayPeriods(sort: PKEY_ASC, filters: $filter) {
            edges {
              node {
                PKEY
                StartDate
                EndDate
              }
            }
          }
        }
      `,
      variables() {
        return {
          filter: {
            or: [
              {
                and: [
                  { StartDateLte: this.task.StartPOP },
                  { EndDateGte: this.task.StartPOP },
                ],
              },
              {
                and: [
                  { StartDateLte: this.task.EndPOP },
                  { EndDateGte: this.task.EndPOP },
                ],
              },
            ],
          },
        };
      },
      update(data) {
        return [data.taskPOP.edges[0].node, data.taskPOP.edges[1].node];
      },
    },
  },
  data() {
    return {
      laborCategoryAssignment: this.initializeLaborCategoryAssignment(),
      selectedEmployee: null,
      task: {
        fundingSourceType: { Enum: undefined },
        laborCategoryAssignments: [],
      },
      inputEmployee: "",
    };
  },
  watch: {
    laborCategoryAssignmentId(newId) {
      if (!newId) {
        this.laborCategoryAssignment = this.initializeLaborCategoryAssignment();
      }
    },
    active(newActive) {
      if (newActive) {
        this.$apollo.queries.task.refetch();
      }
    },
  },
  computed: {
    saveDisabled() {
      if (!this.laborCategoryAssignmentId) {
        return this.newDisabled();
      }
      let olca = this.originalLaborCategoryAssignment;
      // might get called before GraphQL is done, bail if the original
      // object is not set
      if (!olca) {
        return true;
      }
      if (this.notValid(olca.PKEY)) {
        return true;
      }
      let lca = this.laborCategoryAssignment;
      return (
        olca.LaborCategoryPkey == lca.LaborCategoryPkey &&
        olca.EmployeePkey == lca.EmployeePkey &&
        olca.startingPayPeriod.StartDate === lca.StartDate &&
        olca.endingPayPeriod.EndDate === lca.EndDate
      );
    },
    filteredEmployeeChoices() {
      if (!this.employeeChoices) {
        return [];
      }
      return this.employeeChoices.edges.filter((option) => {
        return (
          option.node.Name.toString()
            .toLowerCase()
            .indexOf(this.inputEmployee.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    notValid(beingEdited) {
      let lca = this.laborCategoryAssignment;
      let found = this.task.laborCategoryAssignments.find(
        (e) =>
          e.EmployeePkey == lca.EmployeePkey &&
          e.LaborCategoryPkey == lca.LaborCategoryPkey,
      );
      if (found && found.PKEY != beingEdited) {
        this.$buefy.toast.open({
          message: "Duplicates are not allowed",
          type: "is-danger",
          duration: 5000,
        });
        return true;
      }
      if (!this.taskPOP) {
        return true;
      }
      if (lca.StartDate < this.taskPOP[0].StartDate) {
        this.$buefy.toast.open({
          message: "Date must be after " + this.taskPOP[0].StartDate,
          type: "is-danger",
          duration: 5000,
        });
        return true;
      }
      if (lca.EndDate > this.taskPOP[1].EndDate) {
        this.$buefy.toast.open({
          message: "Date must be before " + this.taskPOP[1].EndDate,
          type: "is-danger",
          duration: 5000,
        });
        return true;
      }
      return false;
    },
    newDisabled() {
      if (this.notValid()) {
        return true;
      }
      let lca = this.laborCategoryAssignment;
      return (
        !lca.LaborCategoryPkey ||
        !lca.EmployeePkey ||
        !lca.StartDate ||
        !lca.EndDate
      );
    },
    initializeLaborCategoryAssignment() {
      let lca = {
        LaborCategoryPkey: null,
        EmployeePkey: null,
        StartDate: this.$apolloData ? this.task.StartPOP : undefined,
        EndDate: this.$apolloData ? this.task.EndPOP : undefined,
      };
      this.inputEmployee = "";
      this.defineDateRangeComputedProp(lca);
      return lca;
    },
    copyOriginal() {
      let olca = this.originalLaborCategoryAssignment;
      this.laborCategoryAssignment = {
        PKEY: olca.PKEY,
        LaborCategoryPkey: olca.LaborCategoryPkey,
        EmployeePkey: olca.EmployeePkey,
        StartDate: olca.startingPayPeriod.StartDate,
        EndDate: olca.endingPayPeriod.EndDate,
      };
      this.inputEmployee = olca.employee.Name;
      this.defineDateRangeComputedProp(this.laborCategoryAssignment);
    },
    defineDateRangeComputedProp(obj) {
      Object.defineProperty(obj, "dateRange", {
        get() {
          if (this.StartDate && this.EndDate) {
            return [
              new Date(this.StartDate + " 00:00:00"),
              new Date(this.EndDate + " 00:00:00"),
            ];
          }
          return undefined;
        },
        set(newValue) {
          this.StartDate = newValue[0].toISOString().substring(0, 10);
          this.EndDate = newValue[1].toISOString().substring(0, 10);
        },
      });
    },
    closeForm() {
      if (this.laborCategoryAssignmentId) {
        this.copyOriginal();
      } else {
        this.laborCategoryAssignment = this.initializeLaborCategoryAssignment();
      }
      this.$emit("close");
    },
    saveLaborCategoryAssignment() {
      if (this.laborCategoryAssignmentId) {
        this.updateLaborCategoryAssignment();
      } else {
        this.createLaborCategoryAssignment();
      }
    },
    createLaborCategoryAssignment() {
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: LaborCategoryAssignmentInput!) {
              createLaborCategoryAssignment(input: $input) {
                laborCategoryAssignment {
                  laborCategory {
                    id
                    Id
                  }
                  employee {
                    id
                    Name
                  }
                }
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.laborCategoryAssignment,
          },
        })
        .then((data) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Labor category assignment for " +
              data.data.createLaborCategoryAssignment.laborCategoryAssignment
                .employee.Name +
              ":" +
              data.data.createLaborCategoryAssignment.laborCategoryAssignment
                .laborCategory.Id +
              " created!",
            type: "is-success",
          });
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create labor category assignment." +
              " Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    updateLaborCategoryAssignment() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: LaborCategoryAssignmentInput!) {
              updateLaborCategoryAssignment(input: $input) {
                laborCategoryAssignment {
                  laborCategory {
                    id
                    Id
                  }
                  employee {
                    id
                    Name
                  }
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: this.laborCategoryAssignment,
          },
        })
        .then((data) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Labor category assignment for " +
              data.data.updateLaborCategoryAssignment.laborCategoryAssignment
                .employee.Name +
              ":" +
              data.data.updateLaborCategoryAssignment.laborCategoryAssignment
                .laborCategory.Id +
              " created!",
            type: "is-success",
          });
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          this.$buefy.snackbar.open({
            message: error.message,
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    confirmDeleteLaborCategoryAssignment() {
      this.$buefy.dialog.confirm({
        title: "Deleting labor category assignment",
        message:
          "Are you sure you want to <b>delete</b> the labor category assignment? " +
          "This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteLaborCategoryAssignment(),
      });
    },
    deleteLaborCategoryAssignment() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($PKEY: Int!) {
              deleteLaborCategoryAssignment(input: { PKEY: $PKEY }) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            PKEY: this.laborCategoryAssignment.PKEY,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Labor category assignment deleted!",
            type: "is-success",
          });
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          this.$buefy.snackbar.open({
            message: error.message,
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
  },
};
</script>
