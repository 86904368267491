<!--

  File: ContractTab.vue

  Defines a component that produces a form to edit contract data. There are
  2 tabs on the form, one to edit the contract and another to display the
  tasks.

  Props:
    None

  Events:
    close: Emitted when the close button is pressed.
    change: Emitted if contract data has been modified.

-->
<template>
  <div class="container">
    <div class="level">
      <div class="level-item">
        <h1 class="title has-text-primary has-text-centered">
          Contract : {{ contractName ? contractName : "New" }}
        </h1>
      </div>
      <div class="level-right">
        <button type="button" class="delete" @click="$emit('close')" />
      </div>
    </div>
    <b-tabs
      size="is-medium"
      v-model="activeTab"
      vertical
      position="is-centered"
      class="block"
    >
      <b-tab-item label="Contract Data" icon="handshake">
        <ContractForm :contractId="contractId" @modified="$emit('modified')" />
      </b-tab-item>
      <b-tab-item label="Tasks" icon="clipboard-text" :disabled="!contractId">
        <Tasks :contractId="contractId" v-if="contractId" />
        <div class="hero is-danger" v-else>
          <div class="hero-body">
            <h1 class="subtitle">
              No tasks to display. Select "Contract Data" to enter new contract
              details.
            </h1>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ContractForm from "@/components/ContractForm";
import Tasks from "@/components/Tasks";

export default {
  props: {
    contractId: { type: String },
    contractName: { type: String },
  },
  components: { ContractForm, Tasks },
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>
