<!--

  File: SubcontractorModal.vue

  Defines a component that allows administrators to add and modify 
  subcontractors.

  Props:
    isActive: Activates the modal when true, false hides the modal.
    subcontractorPkey: The PKEY of the subcontractor to modify or null PKEY to 
           create a new subcontractor.
  
  Events:
    close: Instructs the parent to close the modal. An object with the
           following properties is passed to the parent.
             modified - to indicate if data was modified
             PKEY - the subcontractor primary key or null if the 
                    subcontractor was deleted

-->
<template>
  <section>
    <b-modal
      :active="isActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Subcontractor</p>
          <button
            type="button"
            class="delete"
            @click="$emit('close', { modified: false })"
          />
        </header>
        <section class="modal-card-body">
          <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
          <b-field label="Name">
            <b-input v-model="subcontractor" placeholder="Name"> </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-primary"
            type="button"
            :disabled="saveDisabled"
            @click="saveSubcontractor()"
          >
            {{ buttonLabel }}
          </button>
          <button
            class="button is-danger"
            type="button"
            @click="confirmDelete()"
            v-if="this.subcontractorPkey"
          >
            Delete
          </button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>
<script>
import gql from "graphql-tag";

export default {
  name: "SubcontractorModal",
  props: ["isActive", "subcontractorPkey"],
  data() {
    return {
      isLoading: false,
      modified: false,
      buttonLabel: "",
      subcontractor: "",
    };
  },
  apollo: {
    origSubcontractor: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($contractorPkey: ID!) {
          origSubcontractor: allContractors(
            filters: { PKEY: $contractorPkey }
          ) {
            edges {
              node {
                PKEY
                Name
              }
            }
          }
        }
      `,
      variables() {
        return { contractorPkey: parseInt(this.subcontractorPkey) };
      },
      skip() {
        return !this.subcontractorPkey;
      },
      result() {
        this.subcontractor = this.origSubcontractor.edges[0].node.Name;
      },
    },
  },
  watch: {
    isActive(newActive) {
      if (!newActive) {
        return;
      }
      this.$apollo.queries.origSubcontractor.refetch();
      if (this.subcontractorPkey) {
        this.buttonLabel = "Save";
        this.modified = false;
      } else {
        this.buttonLabel = "New";
        this.subcontractor = "";
        this.modified = false;
      }
    },
  },
  computed: {
    saveDisabled() {
      if (!this.subcontractorPkey) {
        return this.subcontractor.length == 0;
      }
      if (this.origSubcontractor) {
        return (
          this.subcontractor.length == 0 ||
          this.subcontractor == this.origSubcontractor.edges[0].node.Name
        );
      }
      return true;
    },
  },
  methods: {
    saveSubcontractor() {
      if (this.subcontractorPkey) {
        this.updateSubcontractor();
      } else {
        this.createSubcontractor();
      }
    },
    updateSubcontractor() {
      this.isLoading = true;
      let ci = { PKEY: this.subcontractorPkey, Name: this.subcontractor };
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: ContractorUpdateInput!) {
              updateContractor(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: ci,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Subcontractor " + ci.Name + " updated!",
            type: "is-success",
          });
          this.$emit("close", { modified: true, PKEY: ci.PKEY });
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to update subcontractor " +
              this.origSubcontractor.edges[0].node.Name +
              " to " +
              ci.Name +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
    createSubcontractor() {
      this.isLoading = true;
      let ci = { Name: this.subcontractor };
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: ContractorCreateInput!) {
              createContractor(input: $input) {
                Contractor {
                  PKEY
                  Name
                }
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: ci,
          },
        })
        .then((result) => {
          // Result
          this.$buefy.toast.open({
            message: "Subcontractor " + ci.Name + " created!",
            type: "is-success",
          });
          this.$emit("close", {
            modified: true,
            PKEY: result.data.createContractor.Contractor.PKEY,
          });
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create subcontractor " +
              ci.Name +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: "Deleting subcontractor",
        message:
          "Are you sure you want to <b>delete</b> the subcontractor: " +
          this.subcontractor +
          "? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteSubcontractor(),
      });
    },
    deleteSubcontractor() {
      this.isLoading = true;
      let ci = { PKEY: this.subcontractorPkey };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: ContractorDeleteInput!) {
              deleteContractor(input: $input) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: ci,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Subcontractor: " + this.subcontractor + " deleted!",
            type: "is-success",
          });
          this.$emit("close", { modified: true, PKEY: null });
          this.isLoading = false;
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to delete subcontractor: " +
              this.subcontractor +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Got it",
            indefinite: true,
          });
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
