import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import VueApollo from "vue-apollo";
import VueShowdown from "vue-showdown";
import VueKonva from "vue-konva";

import dark from "!!raw-loader!bulmaswatch/cyborg/bulmaswatch.min.css";
import light from "!!raw-loader!bulmaswatch/yeti/bulmaswatch.min.css";

function fixBuefyCss() {
  /*
   * Some of the Buefy css selectors are in addition to Bulma css and are
   * not covered by the Bulmaswatch themes. Modify the Buefy css selectors
   * to adjust to switching between light and dark themes.
   */
  var element = document.getElementById("bulmaswatch");
  var ss = element.sheet;
  var tableBackgroundColor;
  var modalCloseBackgroundColor;
  var modalCardBodyBackgroundColor;
  for (const cr of ss.cssRules) {
    // Get the table background color used by the bulmaswatch theme.
    if (cr.selectorText == ".table") {
      tableBackgroundColor = cr.style.backgroundColor;
    }
    if (cr.selectorText == ".delete, .modal-close") {
      modalCloseBackgroundColor = cr.style.backgroundColor;
    }
    if (cr.selectorText == ".modal-card-body") {
      modalCardBodyBackgroundColor = cr.style.backgroundColor;
    }
  }
  // Replace the background color used for Buefy sticky headers
  // and columns. Unset the color used for navbar items.  Match
  // the color for the delete button on modals.
  var fixedBuefyCss = `
  .b-table .table-wrapper.has-sticky-header tr:first-child th {
    background: ${tableBackgroundColor};
  }
  .navbar-item, .navbar-link {
    color: unset;
  }
  .b-table .table td.is-sticky {
    background: ${tableBackgroundColor};
  }
  .modal-close {
    background: ${modalCloseBackgroundColor};
  }
  .modal.is-full-screen > .animation-content, .modal.is-full-screen > .animation-content > .modal-card {
    background-color: ${modalCardBodyBackgroundColor};
  }
  `;
  ss = document.getElementById("buefy-css-fix");
  if (!ss) {
    ss = document.createElement("style");
    ss.type = "text/css";
    ss.id = "buefy-css-fix";
    document.getElementsByTagName("head")[0].appendChild(ss);
  }
  ss.innerText = fixedBuefyCss;
}

function mediaListener(x) {
  /*
   * This function installs a dark or light themed css file
   * depending on if the given media query matches. The intended
   * media query is (prefers-color-scheme: dark).
   */
  var ss = document.getElementById("bulmaswatch");
  if (!ss) {
    ss = document.createElement("style");
    ss.type = "text/css";
    ss.id = "bulmaswatch";
    document.getElementsByTagName("head")[0].appendChild(ss);
  }
  if (x.matches) {
    ss.innerText = dark;
  } else {
    ss.innerText = light;
  }
  fixBuefyCss();
}

var x = window.matchMedia("(prefers-color-scheme: dark)");
mediaListener(x); // Call listener function at run time
x.addListener(mediaListener); // Attach listener function on state changes

const apolloClient = new ApolloClient({
  // You should use an absolute URL here
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      graphQLErrors.forEach((e) => {
        if (e.extensions) {
          if (e.extensions.auth_code === 401) {
            window.location.reload();
          }
        }
        console.log(e);
      });
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
    }),
  ]),
  cache: new InMemoryCache(),
});

Vue.use(Buefy);
Vue.use(VueApollo);
Vue.use(VueKonva, { prefix: "K" });
Vue.use(VueShowdown, {
  options: {
    emoji: true,
    tables: true,
  },
});
Vue.config.productionTip = false;

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
