<!--

  File: LaborCategoryForm.vue

  Defines a component that produces a form to edit labor category data.

  Props:
    laborCategoryId: The id of the labor category being edited.
    active: Displays the modal form.
    task: An object containing the PKEY, StartPOP and EndPOP of the task for
          which the labor category belongs.

  Events:
    close: Notify parent to close the form.
    modified: Notify parent to close form and update data.

-->
<template>
  <b-modal
    :active="active"
    :can-cancel="false"
    has-modal-card
    trap-focus
    :destroy-on-hide="true"
    aria-role="dialog"
    aria-modal
  >
    <div class="modal-card" style="width: auto">
      <form action="">
        <header class="modal-card-head">
          <p class="modal-card-title mr-3">
            {{
              laborCategoryId ? "Update Labor Category" : "New Labor Category"
            }}
          </p>
          <button type="button" class="delete" @click="closeForm()" />
        </header>
        <div class="modal-card-body">
          <div class="columns">
            <div class="column">
              <b-field label="Accounting Id">
                <b-input
                  v-model="laborCategory.AccountingId"
                  placeholder="Enter Id"
                  required
                >
                </b-input>
              </b-field>
              <b-field label="Id">
                <b-input
                  v-model="laborCategory.Id"
                  placeholder="Enter Id"
                  required
                >
                </b-input>
              </b-field>
              <b-field label="Description">
                <b-input
                  v-model="laborCategory.Description"
                  placeholder="Enter Description"
                  required
                >
                </b-input>
              </b-field>
              <b-field label="Ceiling">
                <b-input
                  type="number"
                  step="1.0"
                  icon="currency-usd"
                  v-model="laborCategory.LimitAmount"
                  placeholder="Enter Ceiling"
                  required
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-two-thirds">
              <b-field label="Rates">
                <b-table :data="laborCategory.rates" :show-header="false">
                  <b-table-column field="HourlyRate" sortable numeric>
                    <template v-slot="props">
                      <b-input
                        v-model="props.row.HourlyRate"
                        type="number"
                        step=".01"
                        placeholder="Hourly Rate"
                      >
                      </b-input>
                    </template>
                  </b-table-column>
                  <b-table-column field="">
                    <template v-slot="props">
                      <b-datepicker
                        placeholder="Click to select..."
                        v-model="props.row.dateRange"
                        range
                        :date-formatter="
                          (d) => {
                            return (
                              d[0].toISOString().substring(0, 10) +
                              ' to ' +
                              d[1].toISOString().substring(0, 10)
                            );
                          }
                        "
                        editable
                        :date-parser="
                          (s) => {
                            let d = s.replace(' to ', '-').split('-');
                            return [
                              new Date(d[0], d[1] - 1, d[2]),
                              new Date(d[3], d[4] - 1, d[5]),
                            ];
                          }
                        "
                        position="is-bottom-left"
                      >
                      </b-datepicker>
                    </template>
                  </b-table-column>
                  <b-table-column>
                    <template v-slot="props">
                      <b-icon
                        v-if="props.index === laborCategory.rates.length - 1"
                        type="is-dark mt-2"
                        size="is-normal"
                        icon="keyboard-return"
                        @click.native="addRow()"
                      />
                      <b-icon
                        v-else
                        type="is-dark mt-2"
                        size="is-normal"
                        icon="delete-forever"
                        @click.native="deleteRow(props.index)"
                      />
                    </template>
                  </b-table-column>
                </b-table>
              </b-field>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <button
            class="button is-primary"
            type="button"
            :disabled="saveDisabled"
            @click="saveLaborCategory()"
          >
            {{ laborCategoryId ? "Save" : "New" }}
          </button>
          <button
            class="button is-danger"
            type="button"
            @click="confirmDeleteLaborCategory()"
            v-if="laborCategoryId"
          >
            Delete
          </button>
        </footer>
      </form>
    </div>
  </b-modal>
</template>
<script>
import gql from "graphql-tag";

export default {
  props: {
    active: { type: Boolean },
    laborCategoryId: { type: String },
    task: { type: Object },
  },
  apollo: {
    originalLaborCategory: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          originalLaborCategory: laborCategory(id: $id) {
            id
            PKEY
            TaskPkey
            AccountingId
            Id
            Description
            LimitAmount
            rates {
              PKEY
              LaborCategoryPkey
              HourlyRate
              StartDate
              EndDate
            }
          }
        }
      `,
      variables() {
        return {
          id: this.laborCategoryId,
        };
      },
      skip() {
        return !this.laborCategoryId;
      },
      result() {
        this.copyOriginal();
      },
    },
  },
  data() {
    return {
      laborCategory: this.initializeLaborCategory(),
    };
  },
  watch: {
    active(newActive) {
      if (newActive && !this.laborCategoryId) {
        this.laborCategory = this.initializeLaborCategory();
      }
    },
  },
  computed: {
    saveDisabled() {
      let olc = this.originalLaborCategory;
      let lc = this.laborCategory;
      if (this.laborCategoryId) {
        // might get called before GraphQL is done, bail if the original
        // object is not set
        if (!olc) {
          return true;
        }
        // the edited object has an extra row for input, subtract 1 to ignore
        // the extra row
        if (olc.rates.length === lc.rates.length - 1) {
          for (let i = 0; i < lc.rates.length - 1; i++) {
            let olcr = olc.rates[i];
            let lcr = lc.rates[i];
            if (
              olcr.HourlyRate != lcr.HourlyRate ||
              olcr.StartDate !== lcr.StartDate ||
              olcr.EndDate !== lcr.EndDate
            ) {
              return false;
            }
          }
        }
        return (
          olc.rates.length === lc.rates.length - 1 &&
          olc.AccountingId === lc.AccountingId &&
          olc.Description === lc.Description &&
          olc.Id === lc.Id &&
          olc.LimitAmount == lc.LimitAmount
        );
      }
      for (let i = 0; i < lc.rates.length; i++) {
        let lcr = lc.rates[i];
        if (!lcr.HourlyRate || !lcr.StartDate || !lcr.EndDate) {
          return true;
        }
      }
      return !lc.AccountingId || !lc.Description || !lc.Id || !lc.LimitAmount;
    },
  },
  methods: {
    toCurrency(value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(value);
    },
    initializeLaborCategory() {
      let lc = {
        TaskPkey: this.task.PKEY,
        AccountingId: null,
        Description: null,
        Id: null,
        LimitAmount: null,
        rates: [],
      };
      let rate = {
        PKEY: null,
        HourlyRate: null,
        StartDate: this.task.StartPOP,
        EndDate: this.task.EndPOP,
        LaborCategoryPkey: null,
      };
      this.defineDateRangeComputedProp(rate);
      lc.rates.push(rate);
      return lc;
    },
    copyOriginal() {
      // use JSON to deep copy the object
      this.laborCategory = JSON.parse(
        JSON.stringify(this.originalLaborCategory)
      );
      delete this.laborCategory.__typename; // remove GraphQL __typename artifact
      delete this.laborCategory.id;
      for (let rate of this.laborCategory.rates) {
        delete rate.__typename; // remove GraphQL __typename artifact
        this.defineDateRangeComputedProp(rate);
      }
      this.addRow();
    },
    defineDateRangeComputedProp(rate) {
      Object.defineProperty(rate, "dateRange", {
        get() {
          if (this.StartDate && this.EndDate) {
            return [
              new Date(this.StartDate + " 00:00:00"),
              new Date(this.EndDate + " 00:00:00"),
            ];
          }
          return undefined;
        },
        set(newValue) {
          this.StartDate = newValue[0].toISOString().substring(0, 10);
          this.EndDate = newValue[1].toISOString().substring(0, 10);
        },
      });
    },
    closeForm() {
      if (this.laborCategoryId) {
        this.copyOriginal();
      }
      this.$emit("close");
    },
    saveLaborCategory() {
      if (this.laborCategoryId) {
        this.updateLaborCategory();
      } else {
        this.createLaborCategory();
      }
    },
    createLaborCategory() {
      this.$apollo
        .mutate({
          // Mutation
          mutation: gql`
            mutation ($input: LaborCategoryInput!) {
              createLaborCategory(input: $input) {
                laborCategory {
                  Id
                }
                ok
              }
            }
          `,
          // Parameters
          variables: {
            input: this.laborCategory,
          },
        })
        .then((data) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Labor category " +
              data.data.createLaborCategory.laborCategory.Id +
              " created!",
            type: "is-success",
          });
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to create labor category " +
              this.laborCategory.Id +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    updateLaborCategory() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($input: LaborCategoryInput!) {
              updateLaborCategory(input: $input) {
                laborCategory {
                  Id
                }
              }
            }
          `,
          // Parameters
          variables: {
            input: this.laborCategory,
          },
        })
        .then((data) => {
          // Result
          this.$buefy.toast.open({
            message:
              "Labor category " +
              data.data.updateLaborCategory.laborCategory.Id +
              " updated!",
            type: "is-success",
          });
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          console.error(error);
          this.$buefy.snackbar.open({
            message:
              "Failed to update labor category " +
              this.laborCategory.Id +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    confirmDeleteLaborCategory() {
      this.$buefy.dialog.confirm({
        title: "Deleting labor category",
        message:
          "Are you sure you want to <b>delete</b> the labor category " +
          this.laborCategory.Id +
          "? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteLaborCategory(),
      });
    },
    deleteLaborCategory() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($PKEY: Int!) {
              deleteLaborCategory(input: { PKEY: $PKEY }) {
                ok
              }
            }
          `,
          // Parameters
          variables: {
            PKEY: this.laborCategory.PKEY,
          },
        })
        .then((/* data */) => {
          // Result
          this.$buefy.toast.open({
            message: "Labor category " + this.laborCategory.Id + " deleted!",
            type: "is-success",
          });
          this.$emit("modified");
        })
        .catch((error) => {
          // Error
          console.error(JSON.stringify(error, null, 2));
          this.$buefy.snackbar.open({
            message:
              "Failed to delete labor category " +
              this.laborCategory.Id +
              ". Check console for error messages.",
            type: "is-danger",
            position: "is-top",
            actionText: "Ok",
            indefinite: true,
          });
        });
    },
    addRow() {
      let rate = {
        PKEY: null,
        HourlyRate: null,
        StartDate: this.task.StartPOP,
        EndDate: this.task.EndPOP,
        LaborCategoryPkey: this.laborCategory.PKEY,
      };
      this.defineDateRangeComputedProp(rate);
      this.laborCategory.rates.push(rate);
    },
    deleteRow(rowindex) {
      this.laborCategory.rates.splice(rowindex, 1);
    },
  },
};
</script>
